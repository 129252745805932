"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionStatus = void 0;
/**
 * An abstraction for handling and interpreting the "status" field of a transaction.
 */
class TransactionStatus {
    /**
     * Creates a new TransactionStatus object.
     */
    constructor(status) {
        this.status = (status || "").toLowerCase();
    }
    /**
     * Creates an unknown status.
     */
    static createUnknown() {
        return new TransactionStatus("unknown");
    }
    /**
     * Returns whether the transaction is pending (e.g. in mempool).
     */
    isPending() {
        return (this.status == "received" ||
            this.status == "pending");
    }
    /**
     * Returns whether the transaction has been executed (not necessarily with success).
     */
    isExecuted() {
        return this.isSuccessful() || this.isFailed() || this.isInvalid();
    }
    /**
     * Returns whether the transaction has been executed successfully.
     */
    isSuccessful() {
        return (this.status == "executed" ||
            this.status == "success" ||
            this.status == "successful");
    }
    /**
     * Returns whether the transaction has been executed, but with a failure.
     */
    isFailed() {
        return (this.status == "fail" ||
            this.status == "failed" ||
            this.status == "unsuccessful" ||
            this.isInvalid());
    }
    /**
     * Returns whether the transaction has been executed, but marked as invalid (e.g. due to "insufficient funds").
     */
    isInvalid() {
        return this.status == "invalid";
    }
    toString() {
        return this.status;
    }
    valueOf() {
        return this.status;
    }
    equals(other) {
        if (!other) {
            return false;
        }
        return this.status == other.status;
    }
}
exports.TransactionStatus = TransactionStatus;
