"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArgSerializer = exports.ArgumentsSeparator = void 0;
const codec_1 = require("./codec");
const algebraic_1 = require("./typesystem/algebraic");
const composite_1 = require("./typesystem/composite");
const variadic_1 = require("./typesystem/variadic");
exports.ArgumentsSeparator = "@";
// TODO: perhaps move default construction options to a factory (ArgSerializerFactory), instead of referencing them in the constructor
// (postpone as much as possible, breaking change)
const defaultArgSerializerrOptions = {
    codec: new codec_1.BinaryCodec()
};
class ArgSerializer {
    constructor(options) {
        options = Object.assign(Object.assign({}, defaultArgSerializerrOptions), options);
        this.codec = options.codec;
    }
    /**
     * Reads typed values from an arguments string (e.g. aa@bb@@cc), given parameter definitions.
     */
    stringToValues(joinedString, parameters) {
        let buffers = this.stringToBuffers(joinedString);
        let values = this.buffersToValues(buffers, parameters);
        return values;
    }
    /**
     * Reads raw buffers from an arguments string (e.g. aa@bb@@cc).
     */
    stringToBuffers(joinedString) {
        // We also keep the zero-length buffers (they could encode missing options, Option<T>).
        return joinedString.split(exports.ArgumentsSeparator).map(item => Buffer.from(item, "hex"));
    }
    /**
     * Decodes a set of buffers into a set of typed values, given parameter definitions.
     */
    buffersToValues(buffers, parameters) {
        // TODO: Refactor, split (function is quite complex).
        const self = this;
        buffers = buffers || [];
        let values = [];
        let bufferIndex = 0;
        let numBuffers = buffers.length;
        for (let i = 0; i < parameters.length; i++) {
            let parameter = parameters[i];
            let type = parameter.type;
            let value = readValue(type);
            values.push(value);
        }
        // This is a recursive function.
        function readValue(type) {
            // TODO: Use matchers.
            if (type.hasExactClass(algebraic_1.OptionalType.ClassName)) {
                let typedValue = readValue(type.getFirstTypeParameter());
                return new algebraic_1.OptionalValue(type, typedValue);
            }
            else if (type.hasExactClass(variadic_1.VariadicType.ClassName)) {
                let typedValues = [];
                while (!hasReachedTheEnd()) {
                    typedValues.push(readValue(type.getFirstTypeParameter()));
                }
                return new variadic_1.VariadicValue(type, typedValues);
            }
            else if (type.hasExactClass(composite_1.CompositeType.ClassName)) {
                let typedValues = [];
                for (const typeParameter of type.getTypeParameters()) {
                    typedValues.push(readValue(typeParameter));
                }
                return new composite_1.CompositeValue(type, typedValues);
            }
            else {
                // Non-composite (singular), non-variadic (fixed) type.
                // The only branching without a recursive call.
                let typedValue = decodeNextBuffer(type);
                return typedValue;
            }
        }
        function decodeNextBuffer(type) {
            if (hasReachedTheEnd()) {
                return null;
            }
            let buffer = buffers[bufferIndex++];
            let decodedValue = self.codec.decodeTopLevel(buffer, type);
            return decodedValue;
        }
        function hasReachedTheEnd() {
            return bufferIndex >= numBuffers;
        }
        return values;
    }
    /**
     * Serializes a set of typed values into an arguments string (e.g. aa@bb@@cc).
     */
    valuesToString(values) {
        let strings = this.valuesToStrings(values);
        let argumentsString = strings.join(exports.ArgumentsSeparator);
        let count = strings.length;
        return { argumentsString, count };
    }
    /**
     * Serializes a set of typed values into a set of strings.
     */
    valuesToStrings(values) {
        let buffers = this.valuesToBuffers(values);
        let strings = buffers.map(buffer => buffer.toString("hex"));
        return strings;
    }
    /**
     * Serializes a set of typed values into a set of strings buffers.
     * Variadic types and composite types might result into none, one or more buffers.
     */
    valuesToBuffers(values) {
        // TODO: Refactor, split (function is quite complex).
        const self = this;
        let buffers = [];
        for (const value of values) {
            handleValue(value);
        }
        // This is a recursive function. It appends to the "buffers" variable.
        function handleValue(value) {
            // TODO: Use matchers.
            if (value.hasExactClass(algebraic_1.OptionalValue.ClassName)) {
                let valueAsOptional = value;
                if (valueAsOptional.isSet()) {
                    handleValue(valueAsOptional.getTypedValue());
                }
            }
            else if (value.hasExactClass(variadic_1.VariadicValue.ClassName)) {
                let valueAsVariadic = value;
                for (const item of valueAsVariadic.getItems()) {
                    handleValue(item);
                }
            }
            else if (value.hasExactClass(composite_1.CompositeValue.ClassName)) {
                let valueAsComposite = value;
                for (const item of valueAsComposite.getItems()) {
                    handleValue(item);
                }
            }
            else {
                // Non-composite (singular), non-variadic (fixed) type.
                // The only branching without a recursive call.
                let buffer = self.codec.encodeTopLevel(value);
                buffers.push(buffer);
            }
        }
        return buffers;
    }
}
exports.ArgSerializer = ArgSerializer;
