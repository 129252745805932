import React from "react";
import {
	CartIcon,
	DocumentIcon,
	HomeIcon,
	PersonIcon,
	StatsIcon,
	RocketIcon,
	WalletIcon,
} from "components/Icons/Icons";

import Homepage from "pages/Homepage.js";
import ComingSoon from "pages/ComingSoon.js";
import Profile from "pages/Overview.js";
import Project from "pages/Project.js";
import Agency from "pages/Agency.js";
import Default from "views/Dashboard/Default.js";
import SignInCover from "pages/SignIn.js";
import Mint from "pages/Mint.js";
import Admin from "pages/Admin.js";
import Statistics from "pages/Statistics.js";
import Dao from "pages/Dao.js";
import DaoCouncil from "pages/DaoCouncil.js";
import DaoFunctioning from "pages/DaoFunctioning.js";
import BotHandle from "pages/BotHandle.js";
import Sticket from "pages/Sticket.js";

const dashRoutes = [
	{
		name: "Homepage",
		path: "/homepage",
		icon: <HomeIcon color="inherit" />,
		authIcon: <HomeIcon color="inherit" />,
		collapse: false,
		component: Homepage,
		layout: "/clubber",
	},
	{
		name: "User",
		path: "/overview",
		icon: <WalletIcon color="inherit" />,
		authIcon: <WalletIcon color="inherit" />,
		collapse: false,
		component: Profile,
		layout: "/clubber",
	},
	{
		name: "NFTs",
		path: "/nfts",
		icon: <RocketIcon color="inherit" />,
		authIcon: <RocketIcon color="inherit" />,
		collapse: true,
		items: [
			{
				name: "Tired Ape Country Club",
				path: "/nfts/tacc",
				component: Project,
				layout: "/clubber",
			},
			{
				name: "Tired Ape Sporting Club",
				path: "/nfts/tasc",
				component: Project,
				layout: "/clubber",
			},
		],
	},
	{
		name: "DAO",
		path: "/dao",
		icon: <PersonIcon color="inherit" />,
		authIcon: <PersonIcon color="inherit" />,
		collapse: true,
		items: [
			{
				name: "Proposals",
				path: "/dao/proposals",
				component: Dao,
				layout: "/clubber",
			},
			{
				name: "Council",
				path: "/dao/council",
				component: DaoCouncil,
				layout: "/clubber",
			},
			{
				name: "Functioning",
				path: "/dao/functioning",
				component: DaoFunctioning,
				layout: "/clubber",
			},
		],
	},
	{
		name: "Statistics",
		path: "/statistics",
		icon: <StatsIcon color="inherit" />,
		authIcon: <StatsIcon color="inherit" />,
		collapse: false,
		component: Statistics,
		layout: "/clubber",
	},
	{
		name: "Mint",
		path: "/mint",
		icon: <CartIcon color="inherit" />,
		authIcon: <CartIcon color="inherit" />,
		collapse: false,
		component: Mint,
		layout: "/clubber",
	},
	{
		name: "sTicket",
		path: "/sticket",
		icon: <CartIcon color="inherit" />,
		authIcon: <CartIcon color="inherit" />,
		collapse: false,
		component: Sticket,
		layout: "/clubber",
	},
	{
		name: "Admin Panel",
		path: "/adminpanel",
		icon: <HomeIcon color="inherit" />,
		authIcon: <HomeIcon color="inherit" />,
		collapse: false,
		component: Admin,
		layout: "/clubber",
	},
	{
		name: "Bot Handle",
		path: "/bothandle",
		icon: <CartIcon color="inherit" />,
		authIcon: <CartIcon color="inherit" />,
		collapse: false,
		component: BotHandle,
		layout: "/clubber",
	},
	{
		name: "Sign In",
		path: "/",
		icon: <PersonIcon color="inherit" />,
		collapse: false,
		component: SignInCover,
		layout: "/auth",
	},
];

export default dashRoutes;
