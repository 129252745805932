import React, { useReducer, useState, useEffect } from "react";
import {
	Avatar,
	AvatarBadge,
	AvatarGroup,
	Box,
	Button,
	CircularProgress,
	CircularProgressLabel,
	DarkMode,
	Flex,
	Grid,
	Icon,
	Image,
	Link,
	SimpleGrid,
	Switch,
	Text,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import IconBox from "components/Icons/IconBox";
import { HSeparator } from "components/Separator/Separator";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useAuth, useTransaction } from "@elrond-giants/erd-react-hooks";
import {
	Address,
	Query,
	AddressValue,
	ContractFunction,
	AbiRegistry,
	SmartContractAbi,
	SmartContract,
	ResultsParser,
	BytesValue,
} from "@elrondnetwork/erdjs";
import { ProxyNetworkProvider } from "@elrondnetwork/erdjs-network-providers";
import axios, { AxiosResponse } from "axios";
import { contractAddress } from "./../config";

function ProposalCard({ proposal, contract, council }) {
	const proxy = new ProxyNetworkProvider("https://gateway.multiversx.com");
	const { makeTransaction, whenCompleted } = useTransaction();

	const {
		isOpen: isOpenTransactionSign,
		onOpen: onOpenTransactionSign,
		onClose: onCloseTransactionSign,
	} = useDisclosure();
	const [txSigned, setTxSigned] = useState(false);
	const [txCompleted, setTxCompleted] = useState(false);

	const { address, authenticated, login, logout } = useAuth();

	const [userVote, setUserVote] = useState(0);
	const [userVotingPower, setUserVotingPower] = useState(0);

	const [proposalId, setProposalId] = useState("");
	const [proposalTitle, setProposalTitle] = useState("");
	const [proposalContent, setProposalContent] = useState("");

	const [creationTimestamp, setCreationTimestamp] = useState(0);
	const [deadlineTimestamp, setDeadlineTimestamp] = useState(0);
	const [deadlineDate, setDeadlineDate] = useState(new Date());

	const [totalVotingPower, setTotalVotingPower] = useState(0);
	const [totalYes, setTotalYes] = useState(0);
	const [totalNo, setTotalNo] = useState(0);
	const [votedPower, setVotedPower] = useState(0);
	const [percentageVoted, setPercentageVoted] = useState(0);
	const [percentageYes, setPercentageYes] = useState(0);
	const [percentageNo, setPercentageNo] = useState(0);

	useEffect(() => {
		setFields(proposal);
	}, []);

	function setFields(proposal) {
		let fields = proposal.fieldsByName;

		setProposalId(
			Buffer.from(fields.get("proposal_id").value, "base64").toString("hex")
		);

		setProposalTitle(
			Buffer.from(fields.get("proposal_title").value, "base64").toString()
		);

		setProposalContent(
			Buffer.from(fields.get("proposal_content").value, "base64")
				.toString()
				.replace(/\\n/g, "\n")
		);

		setCreationTimestamp(
			fields.get("creation_timestamp").value.value.toNumber()
		);

		let deadlineTimestamp = fields
			.get("deadline_timestamp")
			.value.value.toNumber();
		setDeadlineTimestamp(deadlineTimestamp);

		setDeadlineDate(new Date(deadlineTimestamp * 1000));

		let totalVotingPower = fields
			.get("total_voting_power")
			.value.value.toNumber();
		let totalYes = fields.get("total_yes").value.value.toNumber();
		let totalNo = fields.get("total_no").value.value.toNumber();
		let votedPower = totalYes + totalNo;

		setTotalVotingPower(totalVotingPower);
		setTotalYes(totalYes);
		setTotalNo(totalNo);
		setVotedPower(votedPower);

		setPercentageVoted((votedPower / totalVotingPower) * 100);
		setPercentageYes(votedPower > 0 ? (totalYes / votedPower) * 100 : 0);
		setPercentageNo(votedPower > 0 ? (totalNo / votedPower) * 100 : 0);
	}

	async function getUserVote() {
		let proposalId = Buffer.from(
			proposal.fieldsByName.get("proposal_id").value,
			"base64"
		).toString("hex");

		let response: AxiosResponse = await axios.get("tiredclub.abi.json");
		let abiRegistry = AbiRegistry.create(response.data);
		let abi = new SmartContractAbi(abiRegistry, ["TiredClub"]);
		let contract = new SmartContract({
			address: new Address(contractAddress),
			abi: abi,
		});
		let query = contract.createQuery({
			func: new ContractFunction(
				!council ? "getUserVote" : "getUserVoteCouncil"
			),
			args: [
				new AddressValue(new Address(address)),
				new BytesValue(proposalId),
			],
		});

		let queryResponse = await proxy.queryContract(query);
		let endpointDefinition = contract.getEndpoint(
			!council ? "getUserVote" : "getUserVoteCouncil"
		);
		let { firstValue } = new ResultsParser().parseQueryResponse(
			queryResponse,
			endpointDefinition
		);
		setUserVote(firstValue.value.toNumber());
	}

	async function getUserVotingPower() {
		let proposalId = Buffer.from(
			proposal.fieldsByName.get("proposal_id").value,
			"base64"
		).toString("hex");

		let response: AxiosResponse = await axios.get("tiredclub.abi.json");
		let abiRegistry = AbiRegistry.create(response.data);
		let abi = new SmartContractAbi(abiRegistry, ["TiredClub"]);
		let contract = new SmartContract({
			address: new Address(contractAddress),
			abi: abi,
		});
		let query = contract.createQuery({
			func: new ContractFunction(
				!council ? "getVotingPowers" : "getVotingPowersCouncil"
			),
			args: [
				new BytesValue(proposalId),
				new AddressValue(new Address(address)),
			],
		});

		let queryResponse = await proxy.queryContract(query);
		let endpointDefinition = contract.getEndpoint(
			!council ? "getVotingPowers" : "getVotingPowersCouncil"
		);
		let { firstValue } = new ResultsParser().parseQueryResponse(
			queryResponse,
			endpointDefinition
		);
		setUserVotingPower(firstValue.value.toNumber());
	}

	async function getProposal() {
		let query = contract.createQuery({
			func: new ContractFunction(
				!council ? "getProposalById" : "getProposalByIdCouncil"
			),
			args: [new BytesValue(proposalId)],
		});

		let queryResponse = await proxy.queryContract(query);
		let endpointDefinition = contract.getEndpoint(
			!council ? "getProposalById" : "getProposalByIdCouncil"
		);
		let { firstValue } = new ResultsParser().parseQueryResponse(
			queryResponse,
			endpointDefinition
		);
		setFields(firstValue);
	}

	useEffect(() => {
		let deadlineTimestamp = proposal.fieldsByName
			.get("deadline_timestamp")
			.value.value.toNumber();
		if (authenticated && deadlineTimestamp > Date.now() / 1000) {
			getUserVote();
			getUserVotingPower();
		}
	}, []);

	const voteYes = async () => {
		setTxSigned(false);
		setTxCompleted(false);

		onOpenTransactionSign();
		const txHash = await makeTransaction({
			transaction: {
				receiver: contractAddress,
				data: (!council ? "vote@" : "voteCouncil@") + proposalId + "@01",
				gasLimit: 7_500_000,
				value: 0,
			},
		});

		setTxSigned(true);

		const txResult = await whenCompleted(txHash, { interval: 2000 });

		setTxCompleted(true);
		getUserVote();
		getProposal();
	};

	const voteNo = async () => {
		setTxSigned(false);
		setTxCompleted(false);

		onOpenTransactionSign();
		const txHash = await makeTransaction({
			transaction: {
				receiver: contractAddress,
				data: (!council ? "vote@" : "voteCouncil@") + proposalId + "@02",
				gasLimit: 7_500_000,
				value: 0,
			},
		});

		setTxSigned(true);

		const txResult = await whenCompleted(txHash, { interval: 2000 });

		setTxCompleted(true);
		getUserVote();
		getProposal();
	};

	return (
		<>
			<Card
				p="16px"
				w={{ sm: "90%", xl: "100%" }}
				gridArea={{ xl: "1", "2xl": "auto" }}
				mx="auto"
				mt="16px"
			>
				<CardHeader p="12px 5px" mb="12px" align="start">
					<Flex direction="column" w="100%">
						<Text
							fontSize="xl"
							color="#fff"
							fontWeight="bold"
							mb="6px"
							mx="10px"
						>
							{proposalTitle}
						</Text>
						<Text fontSize="sm" color="#fff" mb="6px" mx="10px">
							#{proposalId}
						</Text>
					</Flex>
				</CardHeader>
				<CardBody>
					<Flex flexDirection="column" padding="2px 12px" width="100%">
						<Flex
							flexDirection="row"
							justifyContent="space-evenly"
							alignItems="center"
							alignContent="flex-start"
							flexWrap="wrap"
							gap="30px"
							flexBasis="auto"
							boxSizing="border-box"
							color="#fff"
						>
							<Text w="70%" fontSize="18px">
								<b>Proposal:</b>
								<p style={{ whiteSpace: "pre-wrap" }}>{proposalContent}</p>
							</Text>
							<Flex
								align="center"
								direction="column"
								px="25px"
								py="15px"
								justify="space-between"
								bg="linear-gradient(126.97deg, rgba(6, 11, 38, 0.74) 28.26%, rgba(26, 31, 55, 0.5) 91.2%)"
								borderRadius="20px"
							>
								<div
									style={{
										width: "120px",
									}}
								>
									<CircularProgressbar
										value={percentageYes}
										text={`${(percentageYes > 50
											? percentageYes
											: percentageNo
										).toFixed()}%`}
										styles={buildStyles({
											pathColor: "green",
											trailColor: "#dc2626",
											textColor: percentageYes > 50 ? "green" : "#dc2626",
										})}
									/>
								</div>
								<Text color="#fff" fontSize="22px" fontWeight="bold">
									Approve:
								</Text>
								<Text fontSize="s" color="gray.400" mb="3px">
									{percentageYes.toFixed(2)}% ({totalYes} votes)
								</Text>
								<Text color="#fff" fontSize="22px" fontWeight="bold">
									Deny:
								</Text>
								<Text fontSize="s" color="gray.400" mb="3px">
									{percentageNo.toFixed(2)}% ({totalNo} votes)
								</Text>
							</Flex>
						</Flex>
						{authenticated && deadlineTimestamp * 1000 > Date.now() ? (
							<>
								<HSeparator mt="10px" mb="10px" />
								<Text color="#fff" fontSize="18px">
									Your voting power: {userVotingPower}
								</Text>
								{userVote === 0 && userVotingPower > 0 ? (
									<>
										<Text color="#fff" fontSize="22px" fontWeight="bold">
											Cast your vote:
										</Text>

										<Flex
											direction="row"
											px="25px"
											py="15px"
											justify="space-between"
										>
											<Button
												bg="green"
												color="white"
												alignSelf="flex-end"
												w="48%"
												h="35px"
												onClick={voteYes}
											>
												Approve
											</Button>
											<Button
												bg="#dc2626"
												color="white"
												alignSelf="flex-end"
												w="48%"
												h="35px"
												onClick={voteNo}
											>
												Deny
											</Button>
										</Flex>
									</>
								) : (
									<>
										<Text color="#fff" fontSize="22px" fontWeight="bold">
											You have voted for: {userVote === 1 ? "Approve" : "Deny"}
										</Text>
									</>
								)}
							</>
						) : null}
						<HSeparator mt="10px" mb="10px" />
						<Text color="#fff" fontSize="18px">
							{percentageVoted.toFixed(2)}% has voted ({votedPower} votes out of{" "}
							{totalVotingPower} total voting power)
						</Text>
						<Text color="#fff" fontSize="18px">
							Vote {deadlineTimestamp * 1000 > Date.now() ? "ends" : "ended"} on{" "}
							{deadlineDate.toLocaleDateString()} at{" "}
							{deadlineDate.toLocaleTimeString()}
						</Text>
					</Flex>
				</CardBody>
			</Card>
			<Modal isOpen={isOpenTransactionSign} onClose={onCloseTransactionSign}>
				<ModalOverlay />
				<ModalContent mx={{ sm: 5, lg: 0 }}>
					<ModalBody>
						<Text>
							{!txSigned
								? "Check your MultiversX Wallet to sign the transaction"
								: null}
							{txSigned && !txCompleted
								? "Transaction is being processed, please wait..."
								: null}
							{txCompleted ? "Transaction completed!" : null}
						</Text>
					</ModalBody>
					<ModalFooter>
						<Button variant="outline" mr={3} onClick={onCloseTransactionSign}>
							{txCompleted ? "Close" : "Cancel"}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);

	function base64ToHexFunc(str: string): string {
		const encodedData = atob(str);
		let result = "";
		for (let i = 0; i < encodedData.length; i++) {
			const hex = encodedData.charCodeAt(i).toString(16);
			result += hex.length === 2 ? hex : "0" + hex;
		}
		return result.toUpperCase();
	}
}

export default ProposalCard;
