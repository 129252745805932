import React from "react";
import styled from "styled-components";
import {
	Box,
	Flex,
	Grid,
	Progress,
	SimpleGrid,
	Spacer,
	Stack,
	Stat,
	StatHelpText,
	StatLabel,
	StatNumber,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	useColorModeValue,
	Image,
} from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cards";
import { EffectCards, Navigation, Pagination, Autoplay } from "swiper";

import img1 from "assets/img/nfts/1.jpg";
import img2 from "assets/img/nfts/2.jpg";
import img3 from "assets/img/nfts/3.jpg";
import img4 from "assets/img/nfts/4.jpg";
import img5 from "assets/img/nfts/5.jpg";
import img6 from "assets/img/nfts/6.jpg";
import img7 from "assets/img/nfts/7.jpg";
import img8 from "assets/img/nfts/8.jpg";
import img9 from "assets/img/nfts/9.jpg";
import img10 from "assets/img/nfts/10.jpg";

const Container = styled.div`
	.swiper {
		width: 100%;
		height: 100%;
	}
	.swiper-slide {
		border-radius: 20px;
		justify-content: center;
		align-items: center;
	}
`;
const Carousel = () => {
	return (
		<Box w={{ sm: "100%", lg: "25vw" }} align="center" p="30px">
			<Swiper
				autoplay={{ delay: 2000, disableOnInteraction: false }}
				scrollbar={{ draggable: true }}
				effect={"cards"}
				grabCursor={true}
				modules={[EffectCards, Navigation, Pagination, Autoplay]}
			>
				<SwiperSlide style={{ borderRadius: "20px" }}>
					<img src={img1} alt="nft1" />
				</SwiperSlide>
				<SwiperSlide style={{ borderRadius: "20px" }}>
					<img src={img2} alt="nft2" />
				</SwiperSlide>
				<SwiperSlide style={{ borderRadius: "20px" }}>
					<img src={img3} alt="nft3" />
				</SwiperSlide>
				<SwiperSlide style={{ borderRadius: "20px" }}>
					<img src={img4} alt="nft4" />
				</SwiperSlide>
				<SwiperSlide style={{ borderRadius: "20px" }}>
					<img src={img5} alt="nft5" />
				</SwiperSlide>
				<SwiperSlide style={{ borderRadius: "20px" }}>
					<img src={img6} alt="nft6" />
				</SwiperSlide>
				<SwiperSlide style={{ borderRadius: "20px" }}>
					<img src={img7} alt="nft7" />
				</SwiperSlide>
				<SwiperSlide style={{ borderRadius: "20px" }}>
					<img src={img8} alt="nft8" />
				</SwiperSlide>
				<SwiperSlide style={{ borderRadius: "20px" }}>
					<img src={img9} alt="nft9" />
				</SwiperSlide>
				<SwiperSlide style={{ borderRadius: "20px" }}>
					<img src={img10} alt="nft10" />
				</SwiperSlide>
			</Swiper>
		</Box>
	);
};

export default Carousel;
