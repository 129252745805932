// Chakra Icons
import { BellIcon, SearchIcon } from "@chakra-ui/icons";
// Chakra Imports
import {
	Button,
	Flex,
	IconButton,
	Input,
	InputGroup,
	InputLeftElement,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	useColorModeValue,
} from "@chakra-ui/react";
// Assets
import avatar1 from "assets/img/avatars/avatar1.png";
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar3 from "assets/img/avatars/avatar3.png";
// Custom Icons
import { ProfileIcon, SettingsIcon } from "components/Icons/Icons";
// Custom Components
import { ItemContent } from "components/Menu/ItemContent";
import { SidebarResponsive } from "components/Sidebar/Sidebar";
import PropTypes from "prop-types";
import React from "react";
import { NavLink } from "react-router-dom";
import routes from "routes.js";

import { useAuth, useTransaction } from "@elrond-giants/erd-react-hooks";

export default function HeaderLinks(props) {
	const { variant, children, fixed, secondary, onOpen, ...rest } = props;

	const { authenticated, logout } = useAuth();

	// Chakra Color Mode
	let mainBrand = "white";
	let inputBg = "#0F1535";
	let mainText = "white";
	let navbarIcon = "white";
	let searchIcon = "white";

	if (secondary) {
		navbarIcon = "white";
		mainText = "white";
	}
	const settingsRef = React.useRef();
	return (
		<Flex
			pe={{ sm: "0px", md: "16px" }}
			w={{ sm: "100%", md: "auto" }}
			alignItems="center"
		>
			{!authenticated ? (
				<NavLink to="/auth">
					<Button
						ms="0px"
						px="0px"
						me={{ sm: "2px", md: "16px" }}
						color={navbarIcon}
						variant="transparent-with-icon"
						leftIcon={
							<ProfileIcon
								color={navbarIcon}
								w="22px"
								//color="white"
								h="22px"
								me="0px"
							/>
						}
					>
						<Text display={{ sm: "none", md: "flex" }} color="white">
							Sign In
						</Text>
					</Button>
				</NavLink>
			) : (
				<Button
					ms="0px"
					px="0px"
					me={{ sm: "2px", md: "16px" }}
					color={navbarIcon}
					variant="transparent"
					onClick={logout}
					leftIcon={
						<ProfileIcon
							color={navbarIcon}
							w="22px"
							//color="white"
							h="22px"
							me="0px"
						/>
					}
				>
					<Text display={{ sm: "none", md: "flex" }} color="white">
						Logout
					</Text>
				</Button>
			)}
			<SidebarResponsive
				logoText={props.logoText}
				secondary={props.secondary}
				routes={routes}
				{...rest}
			/>
		</Flex>
	);
}

HeaderLinks.propTypes = {
	variant: PropTypes.string,
	fixed: PropTypes.bool,
	secondary: PropTypes.bool,
	onOpen: PropTypes.func,
};
