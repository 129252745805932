import React from "react";
import routes from "routes.js";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

// Chakra imports
import {
	Box,
	Button,
	Flex,
	Grid,
	HStack,
	Icon,
	Link,
	Menu,
	MenuItem,
	MenuList,
	Stack,
	Text,
	useColorModeValue,
	useDisclosure,
} from "@chakra-ui/react";

// Custom components
import IconBox from "components/Icons/IconBox";
import { SidebarResponsive } from "components/Sidebar/Sidebar";

// React icons
import { RocketIcon } from "components/Icons/Icons";
import { AiFillStar } from "react-icons/ai";
import { GoChevronDown } from "react-icons/go";

// Images assets
import bgCard from "assets/img/background-card-reports.png";

export default function AuthNavbar(props) {
	const { logo, logoText, secondary, ...rest } = props;

	// Chakra color mode
	let mainText = "#fff";
	let navbarBg =
		"linear-gradient(123.64deg, rgba(255, 255, 255, 0) -22.38%, rgba(255, 255, 255, 0.039) 70.38%)";
	let navbarBorder = "rgba(226, 232, 240, 0.3)";
	let navbarShadow = useColorModeValue(
		"0px 7px 23px rgba(0, 0, 0, 0.05)",
		"none"
	);
	let navbarFilter = useColorModeValue(
		"none",
		"drop-shadow(0px 7px 23px rgba(0, 0, 0, 0.05))"
	);
	let navbarBackdrop = "blur(42px)";
	let bgButton = "#582CFF";
	let navbarPosition = "fixed";
	let colorButton = "#fff";
	if (props.secondary === true) {
		navbarBg = "none";
		navbarBorder = "none";
		navbarShadow = "initial";
		navbarFilter = "initial";
		navbarBackdrop = "none";
		bgButton = "#fff";
		colorButton = "#2D3748";
		mainText = "#fff";
		navbarPosition = "absolute";
	}

	const createPagesLinks = (routes) => {
		return routes.map((link, index) => {
			if (
				link.name === "Applications" ||
				link.name === "Ecommerce" ||
				link.name === "Authentication" ||
				link.name === "RTL" ||
				link.name === "Widgets" ||
				link.name === "Charts" ||
				link.name === "Alerts"
			) {
				return;
			}
			if (link.name === "Pricing Page") {
				return (
					<Stack direction="column" key={index}>
						<Stack
							direction="row"
							spacing="6px"
							align="center"
							mb="6px"
							cursor="default"
						>
							<IconBox bg="brand.200" color="white" h="30px" w="30px">
								<RocketIcon color="inherit" />
							</IconBox>
							<Text fontWeight="bold" fontSize="sm" color="#fff">
								Extra
							</Text>
						</Stack>
						{createExtraLinks(extraArr)}
					</Stack>
				);
			}
			if (link.authIcon) {
				return (
					<Stack direction="column" key={index}>
						<Stack
							direction="row"
							spacing="6px"
							align="center"
							mb="6px"
							cursor="default"
						>
							<IconBox bg="brand.200" color="white" h="30px" w="30px">
								{link.authIcon}
							</IconBox>
							<Text fontWeight="bold" fontSize="sm" color="#fff">
								{link.name}
							</Text>
						</Stack>
						{createPagesLinks(link.items)}
					</Stack>
				);
			} else {
				if (link.component) {
					return (
						<NavLink to={link.layout + link.path} key={index}>
							<MenuItem
								ps="36px"
								py="0px"
								_hover={{ boxShadow: "none", bg: "none" }}
								borderRadius="12px"
							>
								<Text color="gray.400" fontSize="sm" fontWeight="500">
									{link.name}
								</Text>
							</MenuItem>
						</NavLink>
					);
				} else {
					return <>{createPagesLinks(link.items)}</>;
				}
			}
		});
	};

	const createExtraLinks = (routes) => {
		return routes.map((link, index) => {
			return (
				<NavLink to={link.layout + link.path} key={index}>
					<MenuItem
						ps="36px"
						py="0px"
						_hover={{ boxShadow: "none", bg: "none" }}
						borderRadius="12px"
					>
						<Text color="gray.400" fontSize="sm" fontWeight="500">
							{link.name}
						</Text>
					</MenuItem>
				</NavLink>
			);
		});
	};

	const createAuthLinks = (routes) => {
		return routes.map((link, index) => {
			if (link.authIcon) {
				return (
					<Stack direction="column" key={index}>
						<Stack
							direction="row"
							spacing="6px"
							align="center"
							mb="6px"
							cursor="default"
						>
							<IconBox bg="brand.200" color="white" h="30px" w="30px">
								{link.authIcon}
							</IconBox>
							<Text fontWeight="700" fontSize="sm" color="#fff">
								{link.name}
							</Text>
						</Stack>
						{createAuthLinks(link.items)}
					</Stack>
				);
			} else {
				return (
					<NavLink to={link.layout + link.path} key={index}>
						<MenuItem
							ps="36px"
							py="0px"
							_hover={{ boxShadow: "none", bg: "none" }}
							borderRadius="12px"
						>
							<Text color="gray.400" fontSize="sm" fontWeight="500">
								{link.name}
							</Text>
						</MenuItem>
					</NavLink>
				);
			}
		});
	};

	const createApplicationLinks = (routes) => {
		return routes.map((link, index) => {
			return (
				<NavLink to={link.layout + link.path} key={index}>
					<Stack direction="row" spacing="12px" align="center" cursor="pointer">
						<IconBox bg="brand.200" color="white" h="30px" w="30px">
							{link.authIcon}
						</IconBox>
						<Text fontWeight="bold" fontSize="sm" color="#fff">
							{link.name}
						</Text>
					</Stack>
				</NavLink>
			);
		});
	};

	const createEcommerceLinks = (routes) => {
		return routes.map((link, index) => {
			if (link.authIcon) {
				return (
					<Stack direction="column" key={index}>
						<Stack
							direction="row"
							spacing="6px"
							align="center"
							mb="6px"
							cursor="default"
						>
							<IconBox bg="brand.200" color="white" h="30px" w="30px">
								{link.authIcon}
							</IconBox>
							<Text fontWeight="bold" fontSize="sm" color="#fff">
								{link.name}
							</Text>
						</Stack>
						{createPagesLinks(link.items)}
					</Stack>
				);
			} else {
				if (link.component) {
					return (
						<NavLink to={link.layout + link.path} key={index}>
							<MenuItem
								ps="36px"
								py="0px"
								_hover={{ boxShadow: "none", bg: "none" }}
								borderRadius="12px"
							>
								<Text color="gray.400" fontSize="sm" fontWeight="500">
									{link.name}
								</Text>
							</MenuItem>
						</NavLink>
					);
				} else {
					return <>{createPagesLinks(link.items)}</>;
				}
			}
		});
	};

	var brand = (
		<Link
			href={`${process.env.PUBLIC_URL}/`}
			display="flex"
			lineHeight="100%"
			fontWeight="bold"
			justifyContent="center"
			alignItems="center"
			color={mainText}
		>
			<Box
				bg="linear-gradient(97.89deg, #FFFFFF 70.67%, rgba(117, 122, 140, 0) 108.55%)"
				bgClip="text"
				ms="12px"
			>
				<Text
					fontSize="sm"
					fontWeight="500"
					color="transparent"
					letterSpacing="0.18em"
				>
					{logoText}
				</Text>
			</Box>
		</Link>
	);

	return (
		<Flex
			position={navbarPosition}
			top="16px"
			left="50%"
			transform="translate(-50%, 0px)"
			background={navbarBg}
			border={props.secondary ? "none" : "2px solid"}
			borderColor={navbarBorder}
			boxShadow={navbarShadow}
			filter={navbarFilter}
			backdropFilter={navbarBackdrop}
			borderRadius="15px"
			px="16px"
			py="22px"
			mx="auto"
			width="1044px"
			maxW="90%"
			alignItems="center"
		>
			<Flex w="100%" justifyContent={{ sm: "start", lg: "center" }}>
				{brand}
				<Box ms={{ base: "auto", lg: "0px" }} display={{ lg: "none" }}>
					<SidebarResponsive
						logoText={props.logoText}
						secondary={props.secondary}
						routes={routes}
						{...rest}
					/>
				</Box>
			</Flex>
		</Flex>
	);
}

AuthNavbar.propTypes = {
	color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
	brandText: PropTypes.string,
};
