"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NothingValue = exports.NothingType = void 0;
const types_1 = require("./types");
class NothingType extends types_1.PrimitiveType {
    constructor() {
        super("nothing");
    }
    getClassName() {
        return NothingType.ClassName;
    }
}
exports.NothingType = NothingType;
NothingType.ClassName = "NothingType";
class NothingValue extends types_1.PrimitiveValue {
    constructor() {
        super(new NothingType());
    }
    getClassName() {
        return NothingValue.ClassName;
    }
    equals(_other) {
        return false;
    }
    valueOf() {
        return {};
    }
}
exports.NothingValue = NothingValue;
NothingValue.ClassName = "NothingValue";
