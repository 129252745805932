"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionFactory = void 0;
const tokenTransferBuilders_1 = require("./tokenTransferBuilders");
const transaction_1 = require("./transaction");
const address_1 = require("./address");
class TransactionFactory {
    constructor(gasEstimator) {
        this.gasEstimator = gasEstimator;
    }
    createEGLDTransfer(args) {
        var _a;
        const dataLength = ((_a = args.data) === null || _a === void 0 ? void 0 : _a.length()) || 0;
        const estimatedGasLimit = this.gasEstimator.forEGLDTransfer(dataLength);
        return new transaction_1.Transaction({
            nonce: args.nonce,
            value: args.value,
            receiver: args.receiver,
            sender: args.sender || address_1.Address.Zero(),
            gasPrice: args.gasPrice,
            gasLimit: args.gasLimit || estimatedGasLimit,
            data: args.data,
            chainID: args.chainID
        });
    }
    createESDTTransfer(args) {
        const transactionPayload = new tokenTransferBuilders_1.ESDTTransferPayloadBuilder()
            .setPayment(args.payment)
            .build();
        const dataLength = transactionPayload.length() || 0;
        const estimatedGasLimit = this.gasEstimator.forESDTTransfer(dataLength);
        return new transaction_1.Transaction({
            nonce: args.nonce,
            receiver: args.receiver,
            sender: args.sender || address_1.Address.Zero(),
            gasPrice: args.gasPrice,
            gasLimit: args.gasLimit || estimatedGasLimit,
            data: transactionPayload,
            chainID: args.chainID
        });
    }
    createESDTNFTTransfer(args) {
        const transactionPayload = new tokenTransferBuilders_1.ESDTNFTTransferPayloadBuilder()
            .setPayment(args.payment)
            .setDestination(args.destination)
            .build();
        const dataLength = transactionPayload.length() || 0;
        const estimatedGasLimit = this.gasEstimator.forESDTNFTTransfer(dataLength);
        return new transaction_1.Transaction({
            nonce: args.nonce,
            receiver: args.sender,
            sender: args.sender,
            gasPrice: args.gasPrice,
            gasLimit: args.gasLimit || estimatedGasLimit,
            data: transactionPayload,
            chainID: args.chainID
        });
    }
    createMultiESDTNFTTransfer(args) {
        const transactionPayload = new tokenTransferBuilders_1.MultiESDTNFTTransferPayloadBuilder()
            .setPayments(args.payments)
            .setDestination(args.destination)
            .build();
        const dataLength = transactionPayload.length() || 0;
        const estimatedGasLimit = this.gasEstimator.forMultiESDTNFTTransfer(dataLength, args.payments.length);
        return new transaction_1.Transaction({
            nonce: args.nonce,
            receiver: args.sender,
            sender: args.sender,
            gasPrice: args.gasPrice,
            gasLimit: args.gasLimit || estimatedGasLimit,
            data: transactionPayload,
            chainID: args.chainID
        });
    }
}
exports.TransactionFactory = TransactionFactory;
