import React from "react";

//Giants imports
import { useAuth } from "@elrond-giants/erd-react-hooks";
import { AuthProviderType } from "@elrond-giants/erdjs-auth/dist/types";
import { useRouter } from "next/router";
import QRCode from "qrcode";
import { useEffect, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";

import * as config from "./../config.js";

import MaiarLoginPopup from "./../components/MaiarLoginPopup.js";

// Chakra imports
import {
	Box,
	Flex,
	Button,
	FormControl,
	FormLabel,
	Heading,
	Input,
	Link,
	Switch,
	Text,
	DarkMode,
	Image,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
} from "@chakra-ui/react";
platform = require("platform");

// Assets
import signInImage from "assets/img/signInImage.png";
import legendary from "assets/img/legendary.jpg";
import maiarIcon from "assets/login/maiar.svg";
import defiIcon from "assets/login/defi.svg";
import ledgerIcon from "assets/login/ledger.svg";
import webIcon from "assets/login/web.svg";

// Custom Components
import AuthFooter from "components/Footer/AuthFooter";

function SignInCover() {
	// Chakra color mode
	const titleColor = "white";
	const textColor = "gray.400";

	const isMobile =
		platform?.os?.family === "iOS" || platform?.os?.family === "Android";

	const { isOpen, onOpen, onClose } = useDisclosure();
	const {
		isOpen: isOpenLedger,
		onOpen: onOpenLedger,
		onClose: onCloseLedger,
	} = useDisclosure();

	const history = useHistory();

	const { authenticated, login, getLedgerAccounts, address } = useAuth();
	const router = useRouter();
	const [maiarAuthUri, setMaiarAuthUri] = useState("");
	const [authQrCode, setAuthQrCode] = useState("");
	const [ledgerAccounts, setLedgerAccounts] = useState(null);

	const location = useLocation();
	const params = new URLSearchParams(location.search);

	const uuid = params.get("uuid");
	console.log(uuid);
	useEffect(() => {
		if (!authenticated) {
			return;
		}

		(async () => {
			if (uuid) {
				history.push("/clubber/bothandle?uuid=" + uuid);
			} else {
				history.push("/clubber/overview");
			}
		})();
	}, [router, authenticated]);

	const maiarClickHandler = async () => {
		const uri = await login(AuthProviderType.WALLET_CONNECT);
		const qrCode = await QRCode.toString(uri, { type: "svg" });
		const authUri = `${
			config.walletConnectDeepLink
		}?wallet-connect=${encodeURIComponent(uri)}`;
		setAuthQrCode(qrCode);
		setMaiarAuthUri(authUri);
		onOpen();
	};

	const webClickHandler = async () => {
		await login(AuthProviderType.WEBWALLET);
	};

	const extensionClickHandler = async () => {
		await login(AuthProviderType.EXTENSION);
	};

	const ledgerClickHandler = async () => {
		const accounts = await getLedgerAccounts();
		setLedgerAccounts(accounts);
		onOpenLedger();
	};

	const loginWithLedger = async (accountIndex: number) => {
		await login(AuthProviderType.LEDGER, { ledgerAccountIndex: accountIndex });
	};

	return (
		<>
			<Flex position="relative">
				<Flex
					minH="100vh"
					h={{ base: "120vh", lg: "fit-content" }}
					w="100%"
					maxW="1044px"
					mx="auto"
					pt={{ sm: "100px", md: "0px" }}
					flexDirection="column"
					me={{ base: "auto", lg: "50px", xl: "auto" }}
				>
					<Flex
						alignItems="center"
						justifyContent="start"
						style={{ userSelect: "none" }}
						mx={{ base: "auto", lg: "unset" }}
						ms={{ base: "auto", lg: "auto" }}
						w={{ base: "100%", md: "50%", lg: "450px" }}
						px="50px"
					>
						<Flex
							direction="column"
							w="100%"
							background="transparent"
							mt={{ base: "50px", md: "150px", lg: "160px", xl: "245px" }}
							mb={{ base: "60px", lg: "95px" }}
						>
							<Heading color={titleColor} fontSize="32px" mb="10px">
								Welcome
							</Heading>
							<Text
								mb="36px"
								ms="4px"
								color={textColor}
								fontWeight="bold"
								fontSize="14px"
							>
								Choose your provider to connect
							</Text>
							<Button
								variant="brand"
								fontSize="10px"
								type="submit"
								w="100%"
								h="45"
								mb="20px"
								onClick={maiarClickHandler}
							>
								<Flex
									w="100%"
									flexDirection="row"
									py="5px"
									alignItems="center"
									justifyContent="center"
								>
									<Image
										src={maiarIcon}
										alt="xPortal"
										w="20px"
										h="16px"
										mr="20px"
									/>
									xPortal
								</Flex>
							</Button>
							<Button
								variant="brand"
								fontSize="10px"
								type="submit"
								w="100%"
								h="45"
								mb="20px"
								onClick={webClickHandler}
							>
								<Image src={webIcon} alt="web" w="20px" h="16px" mr="20px" />
								Web Wallet
							</Button>
							<Button
								variant="brand"
								fontSize="10px"
								type="submit"
								w="100%"
								h="45"
								mb="20px"
								onClick={extensionClickHandler}
							>
								<Image src={defiIcon} alt="defi" w="20px" h="16px" mr="20px" />
								Extension
							</Button>
							<Button
								variant="brand"
								fontSize="10px"
								type="submit"
								w="100%"
								h="45"
								mb="20px"
								onClick={ledgerClickHandler}
								disabled="true"
							>
								<Image
									src={ledgerIcon}
									alt="ledger"
									w="20px"
									h="16px"
									mr="20px"
								/>
								Ledger coming soon...
							</Button>
						</Flex>
					</Flex>
					<Box
						w={{ base: "335px", md: "450px" }}
						mx={{ base: "auto", lg: "unset" }}
						ms={{ base: "auto", lg: "auto" }}
						mb="80px"
					>
						<AuthFooter />
					</Box>
					<Box
						display={{ base: "none", lg: "block" }}
						overflowX="hidden"
						h="100%"
						maxW={{ md: "50vw", lg: "50vw" }}
						minH="100vh"
						w="960px"
						position="absolute"
						left="0px"
					>
						<Box
							w="100%"
							h="100%"
							bgSize="cover"
							bgPosition="50%"
							display="flex"
							flexDirection="column"
							justifyContent="center"
							alignItems="center"
							position="absolute"
						>
							<Box
								bgImage={legendary}
								w="100%"
								h="100%"
								bgSize="cover"
								position="absolute"
								filter="blur(4px)"
							/>

							<Text
								textAlign="center"
								color="transparent"
								letterSpacing="8px"
								fontSize="36px"
								fontWeight="bold"
								bgClip="text !important"
								bg="linear-gradient(180deg, rgba(255,156,35,1) 14%, rgba(251,255,161,1) 49%, rgba(255,255,255,1) 100%);"
								zIndex="1"
							>
								THE TIRED CLUB DAPP
							</Text>
						</Box>
					</Box>
				</Flex>
			</Flex>

			<Modal isOpen={isOpen} onClose={onClose} size="md">
				<ModalOverlay />
				<ModalContent mx={{ sm: 5, lg: 0 }}>
					<ModalHeader>
						<Text fontSize="xl">Login using xPortal</Text>
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Text fontSize="md">
							Unlock your Wallet using <Text as="b">xPortal App</Text> on your
							phone
						</Text>
						<Flex direction="column" align="center" justify="center">
							<Box w="250px" dangerouslySetInnerHTML={{ __html: authQrCode }} />
							{isMobile ? (
								<Link
									href={maiarAuthUri}
									target="_blank"
									rel="noopener noreferrer nofollow"
								>
									<Button size="md" colorScheme="blue" variant="outline">
										Login with xPortal
									</Button>
								</Link>
							) : null}
						</Flex>
					</ModalBody>
					<ModalFooter></ModalFooter>
				</ModalContent>
			</Modal>
			{/* Modal Ledger */}
			<Modal isOpen={isOpenLedger} onClose={onCloseLedger} size="md">
				<ModalOverlay />
				<ModalContent mx={{ sm: 5, lg: 0 }}>
					<ModalHeader>
						<Text fontSize="xl">Login using Ledger</Text>
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						{ledgerAccounts?.map((ledgerAccount, index) => (
							<Text key={index}>{ledgerAccount}</Text>
						))}
					</ModalBody>
					<ModalFooter></ModalFooter>
				</ModalContent>
			</Modal>

			{/*onChange={(e) => loginWithLedger(parseInt(e.target.value))}*/}
		</>
	);
}

export default SignInCover;
