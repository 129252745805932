"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Struct = exports.StructType = void 0;
const errors_1 = require("../../errors");
const fields_1 = require("./fields");
const types_1 = require("./types");
class StructType extends types_1.CustomType {
    constructor(name, fieldsDefinitions) {
        super(name);
        this.fieldsDefinitions = [];
        this.fieldsDefinitions = fieldsDefinitions;
    }
    getClassName() {
        return StructType.ClassName;
    }
    static fromJSON(json) {
        let definitions = (json.fields || []).map(definition => fields_1.FieldDefinition.fromJSON(definition));
        return new StructType(json.name, definitions);
    }
    getFieldsDefinitions() {
        return this.fieldsDefinitions;
    }
    getFieldDefinition(name) {
        return this.fieldsDefinitions.find(item => item.name == name);
    }
    getNamesOfDependencies() {
        return fields_1.Fields.getNamesOfTypeDependencies(this.fieldsDefinitions);
    }
}
exports.StructType = StructType;
StructType.ClassName = "StructType";
class Struct extends types_1.TypedValue {
    /**
     * One can only set fields at initialization time.
     */
    constructor(type, fields) {
        super(type);
        this.fields = fields;
        this.fieldsByName = new Map(fields.map(field => [field.name, field]));
        this.checkTyping();
    }
    getClassName() {
        return Struct.ClassName;
    }
    checkTyping() {
        let type = this.getType();
        let definitions = type.getFieldsDefinitions();
        fields_1.Fields.checkTyping(this.fields, definitions);
    }
    getFields() {
        return this.fields;
    }
    getFieldValue(name) {
        let field = this.fieldsByName.get(name);
        if (field) {
            return field.value.valueOf();
        }
        throw new errors_1.ErrMissingFieldOnStruct(name, this.getType().getName());
    }
    valueOf() {
        let result = {};
        for (const field of this.fields) {
            result[field.name] = field.value.valueOf();
        }
        return result;
    }
    equals(other) {
        if (!this.getType().equals(other.getType())) {
            return false;
        }
        let selfFields = this.getFields();
        let otherFields = other.getFields();
        return fields_1.Fields.equals(selfFields, otherFields);
    }
}
exports.Struct = Struct;
Struct.ClassName = "Struct";
