import React, { useReducer } from "react";

// Tired imports
import { useAuth, useTransaction } from "@elrond-giants/erd-react-hooks";
import { ApiNetworkProvider } from "@elrondnetwork/erdjs-network-providers";
import { ProxyNetworkProvider } from "@elrondnetwork/erdjs-network-providers";
import {
	Address,
	Query,
	AddressValue,
	ContractFunction,
} from "@elrondnetwork/erdjs";
import { useState } from "react";
import { contractAddress, taccScAddress, ownerAddress } from "./../config";
import { BigNumber } from "bignumber.js";
import { useHistory } from "react-router-dom";

// Chakra imports
import {
	Avatar,
	AvatarBadge,
	AvatarGroup,
	Box,
	Button,
	CircularProgress,
	CircularProgressLabel,
	DarkMode,
	Flex,
	Grid,
	Icon,
	Image,
	Link,
	Switch,
	Text,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Spinner,
	AlertTitle,
	AlertDescription,
	CloseButton,
	Alert,
	Input,
	InputGroup,
	InputRightAddon,
} from "@chakra-ui/react";
import avatar11 from "assets/img/avatars/avatar11.png";
// Assets
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar3 from "assets/img/avatars/avatar3.png";
import avatar4 from "assets/img/avatars/avatar4.png";
import avatar6 from "assets/img/avatars/avatar6.png";
import bgProfile from "assets/img/bgProfile.png";
import ProjectImage1 from "assets/img/ProjectImage1.png";
import ProjectImage2 from "assets/img/ProjectImage2.png";
import ProjectImage3 from "assets/img/ProjectImage3.png";
import xLogo from "../assets/img/x-black.png";
// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import LineChart from "components/Charts/LineChart";
import IconBox from "components/Icons/IconBox";
import { HSeparator, VSeparator } from "components/Separator/Separator";

// Icons
import {
	CarIcon,
	LightningIcon,
	LightningWhiteIcon,
	ElrondLogo,
} from "components/Icons/Icons";
import { BsArrowRight } from "react-icons/bs";
import {
	FaCube,
	FaFacebook,
	FaInstagram,
	FaPencilAlt,
	FaPenFancy,
	FaTwitter,
} from "react-icons/fa";
import { IoDocumentsSharp } from "react-icons/io5";

// This reducer changes the active button based on the current state

const reducer = (state, action) => {
	if (action.type === "SWITCH_ACTIVE") {
		if (action.payload === "overview") {
			const newState = {
				overview: true,
				teams: false,
				projects: false,
			};
			return newState;
		} else if (action.payload === "teams") {
			const newState = {
				overview: false,
				teams: true,
				projects: false,
			};
			return newState;
		} else if (action.payload === "projects") {
			const newState = {
				overview: false,
				teams: false,
				projects: true,
			};
			return newState;
		}
	}
	return state;
};

function Profile() {
	// Chakra color mode
	const [state, dispatch] = useReducer(reducer, {
		overview: true,
		teams: false,
		projects: false,
	});

	const {
		isOpen: isOpenTransactionSign,
		onOpen: onOpenTransactionSign,
		onClose: onCloseTransactionSign,
	} = useDisclosure();
	const [txSigned, setTxSigned] = useState(false);
	const [txCompleted, setTxCompleted] = useState(false);

	const proxy = new ProxyNetworkProvider("https://gateway.multiversx.com");

	const { makeTransaction, whenCompleted } = useTransaction();
	const history = useHistory();

	const { address, authenticated, login, logout } = useAuth();

	const [mintData, setMintData] = useState(null);
	const [taccSc, setTaccSc] = useState(null);
	const [tascStats, setTascStats] = useState(null);
	const [inputTaccRoyalties, setInputTaccRoyalties] = useState("");
	const [inputTascRoyalties, setInputTascRoyalties] = useState("");
	const [inputToTacc, setInputToTacc] = useState("");
	const [inputToTasc, setInputToTasc] = useState("");

	const [inputDaoCouncilTitle, setInputDaoCouncilTitle] = useState("");
	const [
		inputDaoCouncilDescription,
		setInputDaoCouncilDescription,
	] = useState("");
	const [inputDaoCouncilHours, setInputDaoCouncilHours] = useState("");
	const [inputDaoHash, setInputDaoHash] = useState("");
	const [inputDaoHours, setInputDaoHours] = useState("");

	const getUser = async () => {
		const provider = new ApiNetworkProvider("https://api.multiversx.com");
		const account = await provider.getAccount(new Address(address));
		setAccount(account);
	};

	const claimTaccRoyalties = async () => {
		setTxSigned(false);
		setTxCompleted(false);

		onOpenTransactionSign();
		const txHash = await makeTransaction({
			transaction: {
				receiver: taccScAddress,
				data: "claimScFunds",
				gasLimit: 3_500_000,
				value: 0,
			},
		});

		setTxSigned(true);

		const txResult = await whenCompleted(txHash, { interval: 2000 });

		setTxCompleted(true);

		getTaccRoyalties();
	};

	function base64ToHexFunc(str: string): string {
		const encodedData = atob(str);
		let result = "";
		for (let i = 0; i < encodedData.length; i++) {
			const hex = encodedData.charCodeAt(i).toString(16);
			result += hex.length === 2 ? hex : "0" + hex;
		}
		return result.toUpperCase();
	}

	function stringToHex(str) {
		var result = "";
		for (var i = 0; i < str.length; i++) {
			result += str.charCodeAt(i).toString(16);
		}
		return result.length % 2 === 0 ? result : "0" + hex;
	}

	function decToHex(dec) {
		let hex = dec.toString(16);
		return hex.length % 2 === 0 ? hex : "0" + hex;
	}

	async function getTaccRoyalties() {
		const provider = new ApiNetworkProvider("https://api.multiversx.com");
		const account = await provider.getAccount(new Address(taccScAddress));
		setTaccSc(account);
	}

	async function getTascStats() {
		fetch(
			`https://api.elrondnftswap.com/mint/getMintCollections?address=${ownerAddress}`
		)
			.then((response) => response.json())
			.then((data) => {
				setTascStats(data[0]);
			});
	}

	const distributeRewards = async () => {
		setTxSigned(false);
		setTxCompleted(false);

		onOpenTransactionSign();
		const txHash = await makeTransaction({
			transaction: {
				receiver: contractAddress,
				data: "distributeRewards",
				gasLimit: 150_000_000,
				value: new BigNumber(inputTaccRoyalties),
			},
		});

		setTxSigned(true);

		const txResult = await whenCompleted(txHash, { interval: 2000 });

		setTxCompleted(true);
	};

	const distributeToTacc = async () => {
		setTxSigned(false);
		setTxCompleted(false);

		onOpenTransactionSign();
		const txHash = await makeTransaction({
			transaction: {
				receiver: contractAddress,
				data: "distributeToTacc",
				gasLimit: 100_000_000,
				value: new BigNumber(inputToTacc),
			},
		});

		setTxSigned(true);

		const txResult = await whenCompleted(txHash, { interval: 2000 });

		setTxCompleted(true);
	};

	const distributeToTasc = async () => {
		setTxSigned(false);
		setTxCompleted(false);

		onOpenTransactionSign();
		const txHash = await makeTransaction({
			transaction: {
				receiver: contractAddress,
				data: "distributeToTasc",
				gasLimit: 100_000_000,
				value: new BigNumber(inputToTasc),
			},
		});

		setTxSigned(true);

		const txResult = await whenCompleted(txHash, { interval: 2000 });

		setTxCompleted(true);
	};

	const distributeTascRoyalties = async () => {
		setTxSigned(false);
		setTxCompleted(false);

		onOpenTransactionSign();
		const txHash = await makeTransaction({
			transaction: {
				receiver: contractAddress,
				data: "distributeRoyaltiesSecondCollection",
				gasLimit: 150_000_000,
				value: new BigNumber(inputTascRoyalties),
			},
		});

		setTxSigned(true);

		const txResult = await whenCompleted(txHash, { interval: 2000 });

		setTxCompleted(true);
	};

	const createDaoCouncilProposal = async () => {
		setTxSigned(false);
		setTxCompleted(false);

		let title = stringToHex(inputDaoCouncilTitle);
		let description = stringToHex(inputDaoCouncilDescription);
		let hours = decToHex(parseInt(inputDaoCouncilHours));

		onOpenTransactionSign();
		const txHash = await makeTransaction({
			transaction: {
				receiver: contractAddress,
				data:
					"createProposalCouncil" +
					"@" +
					title +
					"@" +
					description +
					"@" +
					hours,
				gasLimit: 100_000_000,
				value: new BigNumber(0),
			},
		});

		setTxSigned(true);

		const txResult = await whenCompleted(txHash, { interval: 2000 });

		setTxCompleted(true);
	};

	const submitDao = async () => {
		setTxSigned(false);
		setTxCompleted(false);

		let hash = inputDaoHash;
		let hours = decToHex(parseInt(inputDaoHours));

		onOpenTransactionSign();
		const txHash = await makeTransaction({
			transaction: {
				receiver: contractAddress,
				data: "submitCouncilProposal" + "@" + hash + "@" + hours,
				gasLimit: 110_000_000,
				value: new BigNumber(0),
			},
		});

		setTxSigned(true);

		const txResult = await whenCompleted(txHash, { interval: 2000 });

		setTxCompleted(true);
	};

	React.useEffect(() => {
		if (!authenticated) {
			history.push("/auth");
			return;
		}

		getTaccRoyalties();
		getTascStats();
		getUser();
	}, [authenticated, address]);

	return (
		<>
			<Flex direction="column" mt={{ sm: "150px", md: "100px" }}>
				<Box
					mb={{ sm: "24px", md: "50px", xl: "20px" }}
					borderRadius="15px"
					px="0px"
					display="flex"
					flexDirection="column"
					justifyContent="center"
				>
					{/* Header */}
					<Card
						direction={{ sm: "column", md: "row" }}
						mx="auto"
						maxH="330px"
						w={{ sm: "90%", xl: "100%" }}
						justifyContent={{ sm: "center", md: "space-between" }}
						align="center"
						p="24px"
						borderRadius="20px"
					>
						<Flex
							align="center"
							direction={{ sm: "column", md: "row" }}
						>
							<Flex
								align="center"
								mb={{ sm: "10px", md: "0px" }}
								direction={{ sm: "column", md: "row" }}
								w={{ sm: "100%" }}
								textAlign={{ sm: "center", md: "start" }}
							>
								<Flex
									direction="column"
									maxWidth="100%"
									my={{ sm: "14px" }}
								>
									<Text
										fontSize={{ sm: "lg", lg: "xl" }}
										color="#fff"
										fontWeight="bold"
										ms={{ sm: "8px", md: "0px" }}
									>
										<Text>Admin Panel</Text>
									</Text>
								</Flex>
							</Flex>
						</Flex>
					</Card>
				</Box>
				<Flex
					direction={{ sm: "column", md: "column", lg: "row" }}
					w="100%"
					justifyContent={{
						sm: "space-between",
						md: "space-between",
						lg: "center",
					}}
					mb={{ sm: "20px", md: "20px" }}
				>
					{/* TACC Royalties */}
					<Box flex="1">
						<Card p="16px" h="300px">
							<CardHeader p="12px 5px" mb="12px">
								<Flex direction="column">
									<Text
										fontSize="xl"
										color="#fff"
										fontWeight="bold"
										mb="6px"
									>
										Royalties TACC
									</Text>
								</Flex>
							</CardHeader>
							<CardBody w="100%">
								<Flex
									w="100%"
									direction={{ sm: "column", md: "row" }}
								>
									<Grid
										templateColumns={{
											sm: "2fr",
											md: "repeat(1, 2fr)",
										}}
										gap="24px"
										w="100%"
										alignSelf="flex-start"
									>
										<Flex
											align="center"
											p="18px"
											justify="space-between"
											bg="linear-gradient(126.97deg, rgba(6, 11, 38, 0.74) 28.26%, rgba(26, 31, 55, 0.5) 91.2%)"
											borderRadius="20px"
										>
											<Flex direction="column" me="auto">
												<Text
													fontSize="xs"
													color="gray.400"
													mb="3px"
												>
													Royalties accumulate
												</Text>
												<Text
													color="#fff"
													fontSize="22px"
													fontWeight="bold"
												>
													{(
														taccSc?.balance ||
														new BigNumber(0)
													)
														.div(10 ** 18)
														.toPrecision(3)}{" "}
													EGLD
												</Text>
											</Flex>
											<IconBox
												bg="brand.200"
												w="56px"
												h="56px"
												direction="column"
												p="10px"
											>
												<Image src={xLogo} />
											</IconBox>
										</Flex>
										<Button
											variant="brand"
											alignSelf="flex-end"
											mt="24px"
											w="100px"
											h="35px"
											onClick={claimTaccRoyalties}
										>
											<Text
												fontSize="xs"
												color="#fff"
												fontWeight="bold"
											>
												Claim
											</Text>
										</Button>
									</Grid>
								</Flex>
							</CardBody>
						</Card>
					</Box>
					{/* TASC Royalties */}
					<Box
						flex="1"
						ml={{ md: "0px", lg: "20px" }}
						mt={{ sm: "20px", md: "20px", lg: "0px" }}
					>
						<Card p="16px" h="300px">
							<CardHeader p="12px 5px" mb="12px">
								<Flex direction="column">
									<Text
										fontSize="xl"
										color="#fff"
										fontWeight="bold"
										mb="6px"
									>
										Royalties TASC
									</Text>
								</Flex>
							</CardHeader>
							<CardBody w="100%">
								<Flex
									w="100%"
									direction={{ sm: "column", md: "row" }}
								>
									<Grid
										templateColumns={{
											sm: "2fr",
											md: "repeat(1, 2fr)",
										}}
										gap="24px"
										w="100%"
										alignSelf="flex-start"
									>
										<Flex
											align="center"
											p="18px"
											justify="space-between"
											bg="linear-gradient(126.97deg, rgba(6, 11, 38, 0.74) 28.26%, rgba(26, 31, 55, 0.5) 91.2%)"
											borderRadius="20px"
										>
											<Flex direction="column" me="auto">
												<Text
													fontSize="xs"
													color="gray.400"
													mb="3px"
												>
													Royalties accumulate
												</Text>
												<Text
													color="#fff"
													fontSize="22px"
													fontWeight="bold"
												>
													{new BigNumber(
														tascStats?.royalties ||
															0
													)
														.div(10 ** 18)
														.toPrecision(3)}{" "}
													EGLD
												</Text>
											</Flex>
											<IconBox
												bg="brand.200"
												w="56px"
												h="56px"
												direction="column"
												p="10px"
											>
												<Image src={xLogo} />
											</IconBox>
										</Flex>
									</Grid>
								</Flex>
							</CardBody>
						</Card>
					</Box>
				</Flex>
				<Flex
					direction={{ sm: "column", md: "column", lg: "row" }}
					w="100%"
					justifyContent={{
						sm: "space-between",
						md: "space-between",
						lg: "center",
					}}
					mb={{ sm: "20px", md: "20px" }}
				>
					{/* Distribute TACC Royalties */}
					<Box flex="1">
						<Card p="16px" h="300px">
							<CardHeader p="12px 5px" mb="12px">
								<Flex direction="column">
									<Text
										fontSize="xl"
										color="#fff"
										fontWeight="bold"
										mb="6px"
									>
										Distribuisci Rewards
									</Text>
								</Flex>
							</CardHeader>
							<CardBody w="100%">
								<Flex
									w="100%"
									direction={{ sm: "column", md: "row" }}
								>
									<Grid
										templateColumns={{
											sm: "2fr",
											md: "repeat(1, 2fr)",
										}}
										gap="24px"
										w="100%"
										alignSelf="flex-start"
									>
										<InputGroup size="lg">
											<Input
												placeholder="Importo"
												type="number"
												color="white"
												value={inputTaccRoyalties}
												onChange={(e) =>
													setInputTaccRoyalties(
														e.target.value
													)
												}
											/>
											<InputRightAddon backgroundColor="gray.300">
												EGLD
											</InputRightAddon>
										</InputGroup>
										<Button
											variant="brand"
											alignSelf="flex-end"
											mt="24px"
											h="35px"
											onClick={distributeRewards}
										>
											<Text
												fontSize="xs"
												color="#fff"
												fontWeight="bold"
											>
												Distribuisci
											</Text>
										</Button>
									</Grid>
								</Flex>
							</CardBody>
						</Card>
					</Box>
					{/* Distribute TASC Royalties */}
					<Box flex="1" ml={{ md: "0px", lg: "20px" }}>
						<Card p="16px" h="300px">
							<CardHeader p="12px 5px" mb="12px">
								<Flex direction="column">
									<Text
										fontSize="xl"
										color="#fff"
										fontWeight="bold"
										mb="6px"
									>
										Distribuisci Royalties da TASC
									</Text>
								</Flex>
							</CardHeader>
							<CardBody w="100%">
								<Flex
									w="100%"
									direction={{ sm: "column", md: "row" }}
								>
									<Grid
										templateColumns={{
											sm: "2fr",
											md: "repeat(1, 2fr)",
										}}
										gap="24px"
										w="100%"
										alignSelf="flex-start"
									>
										<InputGroup size="lg">
											<Input
												placeholder="Importo"
												type="number"
												color="white"
												value={inputTascRoyalties}
												onChange={(e) =>
													setInputTascRoyalties(
														e.target.value
													)
												}
											/>
											<InputRightAddon backgroundColor="gray.300">
												EGLD
											</InputRightAddon>
										</InputGroup>
										<Button
											variant="brand"
											alignSelf="flex-end"
											mt="24px"
											h="35px"
											onClick={distributeTascRoyalties}
										>
											<Text
												fontSize="xs"
												color="#fff"
												fontWeight="bold"
											>
												Distribuisci
											</Text>
										</Button>
									</Grid>
								</Flex>
							</CardBody>
						</Card>
					</Box>
				</Flex>
				<Flex
					direction={{ sm: "column", md: "column", lg: "row" }}
					w="100%"
					justifyContent={{
						sm: "space-between",
						md: "space-between",
						lg: "center",
					}}
					mb={{ sm: "20px", md: "20px" }}
				>
					{/* Distribute To TACC */}
					<Box flex="1">
						<Card p="16px" h="300px">
							<CardHeader p="12px 5px" mb="12px">
								<Flex direction="column">
									<Text
										fontSize="xl"
										color="#fff"
										fontWeight="bold"
										mb="6px"
									>
										Distribuisci a TACC
									</Text>
								</Flex>
							</CardHeader>
							<CardBody w="100%">
								<Flex
									w="100%"
									direction={{ sm: "column", md: "row" }}
								>
									<Grid
										templateColumns={{
											sm: "2fr",
											md: "repeat(1, 2fr)",
										}}
										gap="24px"
										w="100%"
										alignSelf="flex-start"
									>
										<InputGroup size="lg">
											<Input
												placeholder="Importo"
												type="number"
												color="white"
												value={inputToTacc}
												onChange={(e) =>
													setInputToTacc(
														e.target.value
													)
												}
											/>
											<InputRightAddon backgroundColor="gray.300">
												EGLD
											</InputRightAddon>
										</InputGroup>
										<Button
											variant="brand"
											alignSelf="flex-end"
											mt="24px"
											h="35px"
											onClick={distributeToTacc}
										>
											<Text
												fontSize="xs"
												color="#fff"
												fontWeight="bold"
											>
												Distribuisci
											</Text>
										</Button>
									</Grid>
								</Flex>
							</CardBody>
						</Card>
					</Box>
					{/* Distribute to TASC */}
					<Box flex="1" ml={{ md: "0px", lg: "20px" }}>
						<Card p="16px" h="300px">
							<CardHeader p="12px 5px" mb="12px">
								<Flex direction="column">
									<Text
										fontSize="xl"
										color="#fff"
										fontWeight="bold"
										mb="6px"
									>
										Distribuisci a TASC
									</Text>
								</Flex>
							</CardHeader>
							<CardBody w="100%">
								<Flex
									w="100%"
									direction={{ sm: "column", md: "row" }}
								>
									<Grid
										templateColumns={{
											sm: "2fr",
											md: "repeat(1, 2fr)",
										}}
										gap="24px"
										w="100%"
										alignSelf="flex-start"
									>
										<InputGroup size="lg">
											<Input
												placeholder="Importo"
												type="number"
												color="white"
												value={inputToTasc}
												onChange={(e) =>
													setInputToTasc(
														e.target.value
													)
												}
											/>
											<InputRightAddon backgroundColor="gray.300">
												EGLD
											</InputRightAddon>
										</InputGroup>
										<Button
											variant="brand"
											alignSelf="flex-end"
											mt="24px"
											h="35px"
											onClick={distributeToTasc}
										>
											<Text
												fontSize="xs"
												color="#fff"
												fontWeight="bold"
											>
												Distribuisci
											</Text>
										</Button>
									</Grid>
								</Flex>
							</CardBody>
						</Card>
					</Box>
				</Flex>
				<Flex
					direction={{ sm: "column", md: "column", lg: "row" }}
					w="100%"
					justifyContent={{
						sm: "space-between",
						md: "space-between",
						lg: "center",
					}}
				>
					{/* Crea Proposta DAO per Council */}
					<Box flex="1">
						<Card p="16px" h="370px">
							<CardHeader p="12px 5px" mb="12px">
								<Flex direction="column">
									<Text
										fontSize="xl"
										color="#fff"
										fontWeight="bold"
										mb="6px"
									>
										Crea Proposta DAO per Council
									</Text>
								</Flex>
							</CardHeader>
							<CardBody w="100%">
								<Flex
									w="100%"
									direction={{ sm: "column", md: "row" }}
								>
									<Grid
										templateColumns={{
											sm: "2fr",
											md: "repeat(1, 2fr)",
										}}
										gap="24px"
										w="100%"
										alignSelf="flex-start"
									>
										<Input
											placeholder="Titolo"
											type="text"
											color="white"
											value={inputDaoCouncilTitle}
											onChange={(e) =>
												setInputDaoCouncilTitle(
													e.target.value
												)
											}
										/>
										<Input
											placeholder="Descrizione"
											type="text"
											color="white"
											value={inputDaoCouncilDescription}
											onChange={(e) =>
												setInputDaoCouncilDescription(
													e.target.value
												)
											}
										/>
										<Input
											placeholder="Ore"
											type="number"
											color="white"
											value={inputDaoCouncilHours}
											onChange={(e) =>
												setInputDaoCouncilHours(
													e.target.value
												)
											}
										/>

										<Button
											variant="brand"
											alignSelf="flex-end"
											mt="24px"
											h="35px"
											onClick={createDaoCouncilProposal}
										>
											<Text
												fontSize="xs"
												color="#fff"
												fontWeight="bold"
											>
												Crea
											</Text>
										</Button>
									</Grid>
								</Flex>
							</CardBody>
						</Card>
					</Box>
				</Flex>
				<Flex
					direction={{ sm: "column", md: "column", lg: "row" }}
					w="100%"
					justifyContent={{
						sm: "space-between",
						md: "space-between",
						lg: "center",
					}}
				>
					{/* Proposta DAO da Council a DAO */}
					<Box flex="1">
						<Card p="16px" h="370px">
							<CardHeader p="12px 5px" mb="12px">
								<Flex direction="column">
									<Text
										fontSize="xl"
										color="#fff"
										fontWeight="bold"
										mb="6px"
									>
										Sottoponi Proposta Council a tutta la
										DAO
									</Text>
								</Flex>
							</CardHeader>
							<CardBody w="100%">
								<Flex
									w="100%"
									direction={{ sm: "column", md: "row" }}
								>
									<Grid
										templateColumns={{
											sm: "2fr",
											md: "repeat(1, 2fr)",
										}}
										gap="24px"
										w="100%"
										alignSelf="flex-start"
									>
										<Input
											placeholder="Hash"
											type="text"
											color="white"
											value={inputDaoHash}
											onChange={(e) =>
												setInputDaoHash(e.target.value)
											}
										/>
										<Input
											placeholder="Ore"
											type="number"
											color="white"
											value={inputDaoHours}
											onChange={(e) =>
												setInputDaoHours(e.target.value)
											}
										/>

										<Button
											variant="brand"
											alignSelf="flex-end"
											mt="24px"
											h="35px"
											onClick={submitDao}
										>
											<Text
												fontSize="xs"
												color="#fff"
												fontWeight="bold"
											>
												Crea
											</Text>
										</Button>
									</Grid>
								</Flex>
							</CardBody>
						</Card>
					</Box>
				</Flex>
			</Flex>
			<Modal
				isOpen={isOpenTransactionSign}
				onClose={onCloseTransactionSign}
			>
				<ModalOverlay />
				<ModalContent mx={{ sm: 5, lg: 0 }}>
					<ModalBody>
						<Text>
							{!txSigned
								? "Check your MultiversX Wallet to sign the transaction"
								: null}
							{txSigned && !txCompleted
								? "Transaction is being processed, please wait..."
								: null}
							{txCompleted ? "Transaction completed!" : null}
						</Text>
					</ModalBody>
					<ModalFooter>
						<Button
							variant="outline"
							mr={3}
							onClick={onCloseTransactionSign}
						>
							{txCompleted ? "Close" : "Cancel"}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}

export default Profile;
