import { ApiNetworkProvider } from "@elrondnetwork/erdjs-network-providers";
import { Address } from "@elrondnetwork/erdjs";

export class MyApiNetworkProvider extends ApiNetworkProvider {
	async getAccountTransfersCount(address: string): Promise<number> {
		const response = await this.doGetGeneric(
			`accounts/${address}/transfers/count`
		);
		return response;
	}

	async getAccountNftsFromCollection(
		address: string,
		collection: string
	): Promise<any[]> {
		const response = await this.doGetGeneric(
			`accounts/${address}/nfts?collections=${collection}&size=1000`
		);
		return response;
	}

	async getNftsWithIdentifiers(identifiers: string[]): Promise<any[]> {
		if (identifiers.length === 0) {
			return [];
		}

		const response = await this.doGetGeneric(
			`nfts?identifiers=${identifiers.join(",")}&size=1000`
		);
		return response;
	}

	async getNftSupplyFromCollection(collection: string): Promise<number> {
		const response = await this.doGetGeneric(
			`collections/${collection}/nfts/count`
		);
		return response;
	}

	async getNftCollection(collection: string): Promise<any> {
		const response = await this.doGetGeneric(`collections/${collection}`);
		return response;
	}
}
