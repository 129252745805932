"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NetworkGeneralStatistics = void 0;
/**
 * An object holding general Network statistics and parameters.
 */
class NetworkGeneralStatistics {
    constructor() {
        this.Shards = 0;
        this.Blocks = 0;
        this.Accounts = 0;
        this.Transactions = 0;
        this.RefreshRate = 0;
        this.Epoch = 0;
        this.RoundsPassed = 0;
        this.RoundsPerEpoch = 0;
    }
    /**
     * Constructs a stats object from a HTTP response (as returned by the provider).
     */
    static fromHttpResponse(payload) {
        let stats = new NetworkGeneralStatistics();
        stats.Shards = Number(payload["shards"]);
        stats.Blocks = Number(payload["blocks"]);
        stats.Accounts = Number(payload["accounts"]);
        stats.Transactions = Number(payload["transactions"]);
        stats.RefreshRate = Number(payload["refreshRate"]);
        stats.Epoch = Number(payload["epoch"]);
        stats.RoundsPassed = Number(payload["roundsPassed"]);
        stats.RoundsPerEpoch = Number(payload["roundsPerEpoch"]);
        return stats;
    }
}
exports.NetworkGeneralStatistics = NetworkGeneralStatistics;
