export const network = {
	id: "mainnet",
	name: "Mainnet",
	walletAddress: "https://wallet.multiversx.com",
	apiAddress: "https://api.multiversx.com",
	gatewayAddress: "https://gateway.multiversx.com",
	explorerAddress: "https://explorer.multiversx.com",
};
export const walletConnectBridge = "https://bridge.walletconnect.org";
export const walletConnectDeepLink =
	"https://maiar.page.link/?apn=com.elrond.maiar.wallet&isi=1519405832&ibi=com.elrond.maiar.wallet&link=https://maiar.com/";
export const chainId = "1";
export const denomination = 18;
export const egldLabel = "EGLD";
export const isDemo = false;

export const contractAddress =
	"erd1qqqqqqqqqqqqqpgqwjv6ru86mmlgvad54alm62xay0st5n5f4yuqdky79h";

export const mintAddress =
	"erd1qqqqqqqqqqqqqpgqa0z2985naa0w9w9kay635rkup60e9x5swe0sn0rr2a";

export const sticketMintAddress =
	"erd1qqqqqqqqqqqqqpgqt30rxjxmxratqgmnh903fk4weveuz2nnxq4qhmhvft";

export const taccScAddress =
	"erd1qqqqqqqqqqqqqpgq7dprvwd4pdlszp4p0emzlcpzl9cnhpg34yuqen7vd2";

export const ownerAddress =
	"erd1dn73taqpa6a9hwt6ghcje8js39x36ljpzhr3x2vsexrpg99vxq4qsfcus3";

export const stakingAgencyContract =
	"erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqx8llllsxavffq";

export const dAppName = "Tired Club dApp";

export const firstCollectionIdentifier = "TACC-73857e";
export const secondCollectionIdentifier = "TIREDCLUB-b8cfb8";
