"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionReceipt = void 0;
const primitives_1 = require("./primitives");
class TransactionReceipt {
    constructor() {
        this.value = "";
        this.sender = new primitives_1.Address("");
        this.data = "";
        this.hash = "";
    }
    static fromHttpResponse(response) {
        let receipt = new TransactionReceipt();
        receipt.value = (response.value || 0).toString();
        receipt.sender = new primitives_1.Address(response.sender);
        receipt.data = response.data;
        receipt.hash = response.txHash;
        return receipt;
    }
}
exports.TransactionReceipt = TransactionReceipt;
