import React, { useState } from "react";
import {
	Box,
	Button,
	Flex,
	Text,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalFooter,
	ModalBody,
	useDisclosure,
} from "@chakra-ui/react";
import { useAuth, useTransaction } from "@elrond-giants/erd-react-hooks";
import {
	Address,
	Query,
	AddressValue,
	ContractFunction,
	AbiRegistry,
	SmartContractAbi,
	SmartContract,
	U32Value,
	ResultsParser,
	BytesValue,
} from "@elrondnetwork/erdjs";
import { ProxyNetworkProvider } from "@elrondnetwork/erdjs-network-providers";
import axios, { AxiosResponse } from "axios";
import { BigNumber } from "bignumber.js";
import { FaCube, FaPenFancy, FaArrowUp } from "react-icons/fa";
import { IoDocumentsSharp } from "react-icons/io5";
import ProgressiveImage from "react-progressive-image-loading";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import LineChart from "components/Charts/LineChart";
import IconBox from "components/Icons/IconBox";
import ProposalCard from "components/ProposalCard";
import { HSeparator, VSeparator } from "components/Separator/Separator";
import {
	contractAddress,
	firstCollectionIdentifier,
	secondCollectionIdentifier,
} from "./../config.js";
import { MyApiNetworkProvider } from "./../MyApiNetworkProvider/index.js";

function DaoCouncil() {
	const history = useHistory();

	const apiNetworkProvider = new MyApiNetworkProvider(
		"https://api.multiversx.com"
	);
	const proxy = new ProxyNetworkProvider("https://gateway.multiversx.com");

	const location = useLocation();

	const {
		isOpen: isOpenTransactionSign,
		onOpen: onOpenTransactionSign,
		onClose: onCloseTransactionSign,
	} = useDisclosure();
	const [txSigned, setTxSigned] = useState(false);
	const [txCompleted, setTxCompleted] = useState(false);

	const { makeTransaction, whenCompleted } = useTransaction();

	const { address, authenticated, login, logout } = useAuth();

	const [account, setAccount] = useState(null);

	const [contract, setContract] = useState(null);

	const [proposals, setProposals] = useState([]);

	const timestampToDateTime = (timestamp: number) => {
		const date = new Date(timestamp * 1000);
		return date.toLocaleTimeString() + " " + date.toLocaleDateString();
	};

	function base64ToHexFunc(str: string): string {
		const encodedData = atob(str);
		let result = "";
		for (let i = 0; i < encodedData.length; i++) {
			const hex = encodedData.charCodeAt(i).toString(16);
			result += hex.length === 2 ? hex : "0" + hex;
		}
		return result.toUpperCase();
	}

	const getUser = async () => {
		const account = await apiNetworkProvider.getAccount(new Address(address));
		setAccount(account);
	};

	async function getContract() {
		let response: AxiosResponse = await axios.get("tiredclub.abi.json");
		let abiRegistry = AbiRegistry.create(response.data);
		let abi = new SmartContractAbi(abiRegistry, ["TiredClub"]);
		let contract = new SmartContract({
			address: new Address(contractAddress),
			abi: abi,
		});

		setContract(contract);
		getProposals(contract);
	}

	async function getProposals(contract: SmartContract) {
		let query = contract.createQuery({
			func: new ContractFunction("getProposalsCouncil"),
			args: [],
		});

		let queryResponse = await proxy.queryContract(query);
		let endpointDefinition = contract.getEndpoint("getProposalsCouncil");
		let { firstValue } = new ResultsParser().parseQueryResponse(
			queryResponse,
			endpointDefinition
		);
		let proposalsId = firstValue.items;

		let proposals = [];

		if (proposalsId.length === 0) {
			return;
		}

		await Promise.all(
			proposalsId.map(async (proposalId) => {
				let proposalHash = Buffer.from(proposalId.value, "base64").toString(
					"hex"
				);
				let proposal = await getProposal(proposalHash, contract);
				proposals.push(proposal);
			})
		);

		proposals.sort((a, b) => {
			return (
				b.fieldsByName.get("proposal_number").value.value.toNumber() -
				a.fieldsByName.get("proposal_number").value.value.toNumber()
			);
		});

		setProposals(proposals);
	}

	async function getProposal(hash, contract: SmartContract) {
		let query = contract.createQuery({
			func: new ContractFunction("getProposalByIdCouncil"),
			args: [new BytesValue(hash)],
		});

		let queryResponse = await proxy.queryContract(query);
		let endpointDefinition = contract.getEndpoint("getProposalByIdCouncil");
		let { firstValue } = new ResultsParser().parseQueryResponse(
			queryResponse,
			endpointDefinition
		);
		return firstValue;
	}

	React.useEffect(() => {
		getUser();
		getContract();
	}, [authenticated, address]);

	return (
		<>
			<Flex direction="column" mt={{ sm: "150px", md: "100px" }}>
				<Box
					mb={{ sm: "24px", md: "50px", xl: "20px" }}
					borderRadius="15px"
					px="0px"
					display="flex"
					flexDirection="column"
					justifyContent="center"
				>
					{/* Header */}
					<Card
						direction={{ sm: "column", md: "row" }}
						mx="auto"
						maxH="330px"
						w={{ sm: "90%", xl: "100%" }}
						justifyContent={{ sm: "center", md: "space-between" }}
						align="center"
						p="24px"
						borderRadius="20px"
					>
						<Flex
							direction="column"
							maxWidth="100%"
							my={{ sm: "14px" }}
							align="center"
						>
							<Text
								fontSize={{ sm: "lg", lg: "xl" }}
								color="#fff"
								fontWeight="bold"
								ms={{ sm: "8px", md: "0px" }}
							>
								Tired Club DAO
							</Text>
							<Text fontSize={{ sm: "sm", md: "md" }} color="gray.400">
								Transparency, democracy, and active participation in the
								management of the Tired Club.
							</Text>
							<HSeparator my="16px" />
							<Text fontSize={{ sm: "sm", md: "md" }} color="gray.400">
								Remember: The Tired Council determines whether the proposal
								should be presented to the entire community. It is important to
								note that this vote is not a decision on the proposal itself,
								but rather a decision on whether to submit the proposal to the
								entire community for their vote
							</Text>
						</Flex>
					</Card>
				</Box>

				<Box
					mb={{ sm: "24px", md: "50px", xl: "20px" }}
					borderRadius="15px"
					px="0px"
					display="flex"
					flexDirection="column"
					justifyContent="center"
				>
					{proposals.map((proposal) => (
						<ProposalCard
							proposal={proposal}
							key={Buffer.from(
								proposal.fieldsByName.get("proposal_id").value,
								"base64"
							).toString("hex")}
							contract={contract}
							council
						/>
					))}
				</Box>
			</Flex>
			<Modal isOpen={isOpenTransactionSign} onClose={onCloseTransactionSign}>
				<ModalOverlay />
				<ModalContent mx={{ sm: 5, lg: 0 }}>
					<ModalBody>
						<Text>
							{!txSigned
								? "Check your MultiversX Wallet to sign the transaction"
								: null}
							{txSigned && !txCompleted
								? "Transaction is being processed, please wait..."
								: null}
							{txCompleted ? "Transaction completed!" : null}
						</Text>
					</ModalBody>
					<ModalFooter>
						<Button variant="outline" mr={3} onClick={onCloseTransactionSign}>
							{txSigned ? "Close" : "Cancel"}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}

export default DaoCouncil;
