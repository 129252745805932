import React, { useReducer } from "react";

// Tired imports
import { useAuth, useTransaction } from "@elrond-giants/erd-react-hooks";
import { MyApiNetworkProvider } from "./../MyApiNetworkProvider/index.js";
import { ProxyNetworkProvider } from "@elrondnetwork/erdjs-network-providers";
import {
	Address,
	Query,
	AddressValue,
	ContractFunction,
	AbiRegistry,
	SmartContractAbi,
	SmartContract,
	U32Value,
	ResultsParser,
	BytesValue,
} from "@elrondnetwork/erdjs";
import { useState } from "react";
import {
	contractAddress,
	firstCollectionIdentifier,
	secondCollectionIdentifier,
} from "./../config.js";
import { BigNumber } from "bignumber.js";
import { useLocation } from "react-router-dom";

// Chakra imports
import {
	Avatar,
	AvatarBadge,
	AvatarGroup,
	Box,
	Button,
	CircularProgress,
	CircularProgressLabel,
	DarkMode,
	Flex,
	Grid,
	Icon,
	Image,
	Link,
	SimpleGrid,
	Switch,
	Text,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
} from "@chakra-ui/react";

import { useHistory } from "react-router-dom";

// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import LineChart from "components/Charts/LineChart";
import IconBox from "components/Icons/IconBox";
import { HSeparator, VSeparator } from "components/Separator/Separator";

// Icons
import { FaCube, FaPenFancy, FaArrowUp } from "react-icons/fa";
import { IoDocumentsSharp } from "react-icons/io5";

import ProgressiveImage from "react-progressive-image-loading";
import axios, { AxiosResponse } from "axios";

function DaoFunctioning() {
	const history = useHistory();

	const apiNetworkProvider = new MyApiNetworkProvider("https://api.elrond.com");
	const proxy = new ProxyNetworkProvider("https://gateway.elrond.com");

	const location = useLocation();

	const {
		isOpen: isOpenTransactionSign,
		onOpen: onOpenTransactionSign,
		onClose: onCloseTransactionSign,
	} = useDisclosure();
	const [txSigned, setTxSigned] = useState(false);
	const [txCompleted, setTxCompleted] = useState(false);

	const { makeTransaction, whenCompleted } = useTransaction();

	const { address, authenticated, login, logout } = useAuth();

	const [account, setAccount] = useState(null);

	const timestampToDateTime = (timestamp: number) => {
		const date = new Date(timestamp * 1000);
		return date.toLocaleTimeString() + " " + date.toLocaleDateString();
	};

	function base64ToHexFunc(str: string): string {
		const encodedData = atob(str);
		let result = "";
		for (let i = 0; i < encodedData.length; i++) {
			const hex = encodedData.charCodeAt(i).toString(16);
			result += hex.length === 2 ? hex : "0" + hex;
		}
		return result.toUpperCase();
	}

	const getUser = async () => {
		const account = await apiNetworkProvider.getAccount(new Address(address));
		setAccount(account);
	};

	React.useEffect(() => {
		getUser();
	}, [authenticated, address]);

	return (
		<>
			<Flex direction="column" mt={{ sm: "150px", md: "100px" }}>
				<Box
					mb={{ sm: "24px", md: "50px", xl: "20px" }}
					borderRadius="15px"
					px="0px"
					display="flex"
					flexDirection="column"
					justifyContent="center"
				>
					{/* Header */}
					<Card
						direction={{ sm: "column", md: "row" }}
						mx="auto"
						maxH="330px"
						w={{ sm: "90%", xl: "100%" }}
						justifyContent={{ sm: "center", md: "space-between" }}
						align="center"
						p="24px"
						borderRadius="20px"
					>
						<Flex
							direction="column"
							maxWidth="100%"
							my={{ sm: "14px" }}
							align="center"
						>
							<Text
								fontSize={{ sm: "lg", lg: "xl" }}
								color="#fff"
								fontWeight="bold"
								ms={{ sm: "8px", md: "0px" }}
							>
								Tired Club DAO
							</Text>
							<Text fontSize={{ sm: "sm", md: "md" }} color="gray.400">
								Transparency, democracy, and active participation in the
								management of the Tired Club.
							</Text>
							<Text
								fontSize={{ sm: "lg", lg: "xl" }}
								color="#fff"
								fontWeight="bold"
								ms={{ sm: "8px", md: "0px" }}
							>
								Read about the proposed Tired Club DAO functioning below.
							</Text>
						</Flex>
					</Card>
				</Box>

				<Box
					mb={{ sm: "24px", md: "50px", xl: "20px" }}
					borderRadius="15px"
					px="0px"
					display="flex"
					flexDirection="column"
					justifyContent="center"
				>
					<Card
						direction={{ sm: "column", md: "row" }}
						mx="auto"
						maxH="330px"
						w={{ sm: "90%", xl: "100%" }}
						justifyContent={{ sm: "center", md: "space-between" }}
						align="left"
						p="24px"
						borderRadius="20px"
					>
						<Flex direction="column" maxWidth="100%" my={{ sm: "14px" }}>
							<Link
								href="/TiredClubDao.pdf"
								fontSize={{ sm: "lg", lg: "xl" }}
								color="#fff"
								fontWeight="bold"
								ms={{ sm: "8px", md: "0px" }}
							>
								English Document
							</Link>
						</Flex>
					</Card>
					<Card
						direction={{ sm: "column", md: "row" }}
						mx="auto"
						mt="20px"
						maxH="330px"
						w={{ sm: "90%", xl: "100%" }}
						justifyContent={{ sm: "center", md: "space-between" }}
						align="left"
						p="24px"
						borderRadius="20px"
					>
						<Flex direction="column" maxWidth="100%" my={{ sm: "14px" }}>
							<Link
								href="/TiredClubDao_it.pdf"
								fontSize={{ sm: "lg", lg: "xl" }}
								color="#fff"
								fontWeight="bold"
								ms={{ sm: "8px", md: "0px" }}
							>
								Italian Translation
							</Link>
						</Flex>
					</Card>
					<Card
						direction={{ sm: "column", md: "row" }}
						mx="auto"
						mt="20px"
						maxH="330px"
						w={{ sm: "90%", xl: "100%" }}
						justifyContent={{ sm: "center", md: "space-between" }}
						align="left"
						p="24px"
						borderRadius="20px"
					>
						<Flex direction="column" maxWidth="100%" my={{ sm: "14px" }}>
							<Link
								href="/TiredClubDao_ro.pdf"
								fontSize={{ sm: "lg", lg: "xl" }}
								color="#fff"
								fontWeight="bold"
								ms={{ sm: "8px", md: "0px" }}
							>
								Romanian Translation
							</Link>
						</Flex>
					</Card>
				</Box>
			</Flex>
			<Modal isOpen={isOpenTransactionSign} onClose={onCloseTransactionSign}>
				<ModalOverlay />
				<ModalContent mx={{ sm: 5, lg: 0 }}>
					<ModalBody>
						<Text>
							{!txSigned
								? "Check your MultiversX Wallet to sign the transaction"
								: null}
							{txSigned && !txCompleted
								? "Transaction is being processed, please wait..."
								: null}
							{txCompleted ? "Transaction completed!" : null}
						</Text>
					</ModalBody>
					<ModalFooter>
						<Button variant="outline" mr={3} onClick={onCloseTransactionSign}>
							{txSigned ? "Close" : "Cancel"}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}

export default DaoFunctioning;
