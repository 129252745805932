"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionOnNetwork = void 0;
const transactionStatus_1 = require("./transactionStatus");
const contractResults_1 = require("./contractResults");
const primitives_1 = require("./primitives");
const transactionCompletionStrategy_1 = require("./transactionCompletionStrategy");
const transactionLogs_1 = require("./transactionLogs");
const transactionReceipt_1 = require("./transactionReceipt");
class TransactionOnNetwork {
    constructor(init) {
        this.isCompleted = false;
        this.hash = "";
        this.type = "";
        this.nonce = 0;
        this.round = 0;
        this.epoch = 0;
        this.value = "";
        this.receiver = new primitives_1.Address("");
        this.sender = new primitives_1.Address("");
        this.gasLimit = 0;
        this.gasPrice = 0;
        this.data = Buffer.from([]);
        this.signature = "";
        this.status = transactionStatus_1.TransactionStatus.createUnknown();
        this.timestamp = 0;
        this.blockNonce = 0;
        this.hyperblockNonce = 0;
        this.hyperblockHash = "";
        this.receipt = new transactionReceipt_1.TransactionReceipt();
        this.contractResults = new contractResults_1.ContractResults([]);
        this.logs = new transactionLogs_1.TransactionLogs();
        Object.assign(this, init);
    }
    static fromProxyHttpResponse(txHash, response) {
        let result = TransactionOnNetwork.fromHttpResponse(txHash, response);
        result.contractResults = contractResults_1.ContractResults.fromProxyHttpResponse(response.smartContractResults || []);
        result.isCompleted = new transactionCompletionStrategy_1.TransactionCompletionStrategyOnProxy().isCompleted(result);
        // TODO: uniformize transaction status.
        return result;
    }
    static fromApiHttpResponse(txHash, response) {
        let result = TransactionOnNetwork.fromHttpResponse(txHash, response);
        result.contractResults = contractResults_1.ContractResults.fromApiHttpResponse(response.results || []);
        result.isCompleted = new transactionCompletionStrategy_1.TransactionCompletionStrategyOnAPI().isCompleted(result);
        // TODO: uniformize transaction status.
        return result;
    }
    static fromHttpResponse(txHash, response) {
        let result = new TransactionOnNetwork();
        result.hash = txHash;
        result.type = response.type || "";
        result.nonce = response.nonce || 0;
        result.round = response.round;
        result.epoch = response.epoch || 0;
        result.value = (response.value || 0).toString();
        result.sender = new primitives_1.Address(response.sender);
        result.receiver = new primitives_1.Address(response.receiver);
        result.gasPrice = response.gasPrice || 0;
        result.gasLimit = response.gasLimit || 0;
        result.data = Buffer.from(response.data || "", "base64");
        result.status = new transactionStatus_1.TransactionStatus(response.status);
        result.timestamp = response.timestamp || 0;
        result.blockNonce = response.blockNonce || 0;
        result.hyperblockNonce = response.hyperblockNonce || 0;
        result.hyperblockHash = response.hyperblockHash || "";
        result.receipt = transactionReceipt_1.TransactionReceipt.fromHttpResponse(response.receipt || {});
        result.logs = transactionLogs_1.TransactionLogs.fromHttpResponse(response.logs || {});
        return result;
    }
    getDateTime() {
        return new Date(this.timestamp * 1000);
    }
}
exports.TransactionOnNetwork = TransactionOnNetwork;
