import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";

import { AuthContextProvider } from "@elrond-giants/erd-react-hooks";
import { Provider as ReduxProvider } from "react-redux";
import store from "./redux/store.js";

ReactDOM.render(
	<HashRouter>
		<ReduxProvider store={store}>
			<AuthContextProvider
				env={"mainnet"}
				projectId="8c8e31c7277f4897b2ee7f49ff1a54ed"
				use2FABrowserInput={true}
			>
				<Switch>
					<Route path={`/auth`} component={AuthLayout} />
					<Route path={`/clubber`} component={AdminLayout} />
					<Redirect from={`/`} to="/clubber/" />
				</Switch>
			</AuthContextProvider>
		</ReduxProvider>
	</HashRouter>,
	document.getElementById("root")
);
