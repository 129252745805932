import React, { useReducer } from "react";

// Tired imports
import { useAuth, useTransaction } from "@elrond-giants/erd-react-hooks";
import { MyApiNetworkProvider } from "./../MyApiNetworkProvider/index.js";
import { ProxyNetworkProvider } from "@elrondnetwork/erdjs-network-providers";
import {
	Address,
	Query,
	AddressValue,
	ContractFunction,
} from "@elrondnetwork/erdjs";
import { useState } from "react";
import {
	contractAddress,
	firstCollectionIdentifier,
	secondCollectionIdentifier,
} from "./../config.js";
import { BigNumber } from "bignumber.js";
import { useLocation } from "react-router-dom";

// Chakra imports
import {
	Avatar,
	AvatarBadge,
	AvatarGroup,
	Box,
	Button,
	CircularProgress,
	CircularProgressLabel,
	DarkMode,
	Flex,
	Grid,
	Icon,
	Image,
	Link,
	SimpleGrid,
	Switch,
	Text,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
} from "@chakra-ui/react";

import { useHistory } from "react-router-dom";

// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import LineChart from "components/Charts/LineChart";
import IconBox from "components/Icons/IconBox";
import { HSeparator, VSeparator } from "components/Separator/Separator";

// Icons
import { FaCube, FaPenFancy, FaArrowUp } from "react-icons/fa";
import { IoDocumentsSharp } from "react-icons/io5";

import ProgressiveImage from "react-progressive-image-loading";

const reducer = (state, action) => {
	if (action.type === "SWITCH_ACTIVE") {
		if (action.payload === "staked") {
			const newState = {
				staked: true,
				unstaked: false,
				wallet: false,
			};
			return newState;
		} else if (action.payload === "unstaked") {
			const newState = {
				staked: false,
				unstaked: true,
				wallet: false,
			};
			return newState;
		} else if (action.payload === "wallet") {
			const newState = {
				staked: false,
				unstaked: false,
				wallet: true,
			};
			return newState;
		}
	}
	return state;
};

function Profile() {
	// Chakra color mode
	const [state, dispatch] = useReducer(reducer, {
		staked: true,
		unstaked: false,
		wallet: false,
	});

	const history = useHistory();

	const [modalNft, setModalNft] = useState(null);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const {
		isOpen: isOpenStakeConfirm,
		onOpen: onOpenStakeConfirm,
		onClose: onCloseStakeConfirm,
	} = useDisclosure();
	const {
		isOpen: isOpenStakeAllConfirm,
		onOpen: onOpenStakeAllConfirm,
		onClose: onCloseStakeAllConfirm,
	} = useDisclosure();
	const {
		isOpen: isOpenUnstakeConfirm,
		onOpen: onOpenUnstakeConfirm,
		onClose: onCloseUnstakeConfirm,
	} = useDisclosure();
	const {
		isOpen: isOpenCancelUnstake,
		onOpen: onOpenCancelUnstake,
		onClose: onCloseCancelUnstake,
	} = useDisclosure();

	const apiNetworkProvider = new MyApiNetworkProvider(
		"https://api.multiversx.com"
	);
	const proxy = new ProxyNetworkProvider("https://gateway.multiversx.com");

	const location = useLocation();

	const {
		isOpen: isOpenTransactionSign,
		onOpen: onOpenTransactionSign,
		onClose: onCloseTransactionSign,
	} = useDisclosure();
	const [txSigned, setTxSigned] = useState(false);
	const [txCompleted, setTxCompleted] = useState(false);

	const { makeTransaction, whenCompleted } = useTransaction();

	const { address, authenticated, login, logout } = useAuth();

	const [account, setAccount] = useState(null);
	const [rewards, setRewards] = useState(new BigNumber(0));

	const [stakedNonces, setStakedNonces] = useState([]);
	const [stakedNfts, setStakedNfts] = useState([]);

	const [unstakedNonces, setUnstakedNonces] = useState([]);
	const [unstakedNfts, setUnstakedNfts] = useState([]);

	const [walletNfts, setWalletNfts] = useState([]);

	const collectionIdentifier =
		location.pathname === "/clubber/nfts/tacc"
			? firstCollectionIdentifier
			: secondCollectionIdentifier;

	const timestampToDateTime = (timestamp: number) => {
		const date = new Date(timestamp * 1000);
		return date.toLocaleTimeString() + " " + date.toLocaleDateString();
	};

	function base64ToHexFunc(str: string): string {
		const encodedData = atob(str);
		let result = "";
		for (let i = 0; i < encodedData.length; i++) {
			const hex = encodedData.charCodeAt(i).toString(16);
			result += hex.length === 2 ? hex : "0" + hex;
		}
		return result.toUpperCase();
	}

	const getUser = async () => {
		const account = await apiNetworkProvider.getAccount(new Address(address));
		setAccount(account);
	};

	const getStakedNfts = async (nonces: string[]) => {
		const response = await apiNetworkProvider.getNftsWithIdentifiers(
			nonces.map((n) => collectionIdentifier + "-" + n)
		);
		console.log(response);
		setStakedNfts(response);
		setModalNft(response[0]);
	};

	const getUnstakedNfts = async (nfts: any[]) => {
		const response = await apiNetworkProvider.getNftsWithIdentifiers(
			nfts.map((nft) => collectionIdentifier + "-" + nft.nonce)
		);
		response.forEach((r) => {
			r.deadline = nfts.find(
				(n) => n.nonce === r.identifier.split("-")[2]
			)?.deadline;
		});
		setUnstakedNfts(response);
	};

	const getWalletNfts = async () => {
		const response = await apiNetworkProvider.getAccountNftsFromCollection(
			address,
			collectionIdentifier
		);
		setWalletNfts(response);
	};

	//Get staked NFTs
	async function getUserStakedNfts() {
		const query = new Query({
			address: new Address(contractAddress),
			func: new ContractFunction(
				collectionIdentifier === firstCollectionIdentifier
					? "getUserStakedFirstCollection"
					: "getUserStakedSecondCollection"
			),
			args: [new AddressValue(new Address(address))],
		});
		proxy
			.queryContract(query)
			.then(({ returnData }) => {
				const decodedData = returnData.map((x: any) =>
					Buffer.from(x, "base64").toString("hex")
				);
				setStakedNonces(decodedData);
				getStakedNfts(decodedData);
			})
			.catch((err) => {
				console.error("Unable to call VM query", err);
			});
	}

	//Get unstaked NFTs
	async function getUserUnstakedNfts() {
		const query = new Query({
			address: new Address(contractAddress),
			func: new ContractFunction(
				collectionIdentifier === firstCollectionIdentifier
					? "getUserUnstakedFirstCollection"
					: "getUserUnstakedSecondCollection"
			),
			args: [new AddressValue(new Address(address))],
		});
		proxy
			.queryContract(query)
			.then(({ returnData }) => {
				const decodedData = returnData.map((x: any) => {
					const hex = Buffer.from(x, "base64").toString("hex");
					let nonce = parseInt(hex.substring(0, 16), 16).toString(16);
					if (nonce.length % 2 !== 0) {
						nonce = "0" + nonce;
					}
					const deadline = parseInt(hex.substring(16), 16);
					return { nonce, deadline };
				});
				setUnstakedNonces(decodedData);
				getUnstakedNfts(decodedData);
			})
			.catch((err) => {
				console.error("Unable to call VM query", err);
			});
	}

	React.useEffect(() => {
		if (!authenticated) {
			history.push("/auth");
			return;
		}

		getUserStakedNfts();
		getUserUnstakedNfts();

		getUser();
		getWalletNfts();
	}, [authenticated, address]);

	return (
		<>
			<Flex direction="column" mt={{ sm: "150px", md: "100px" }}>
				<Box
					mb={{ sm: "24px", md: "50px", xl: "20px" }}
					borderRadius="15px"
					px="0px"
					display="flex"
					flexDirection="column"
					justifyContent="center"
				>
					{/* Header */}
					<Card
						direction={{ sm: "column", md: "row" }}
						mx="auto"
						maxH="330px"
						w={{ sm: "90%", xl: "100%" }}
						justifyContent={{ sm: "center", md: "space-between" }}
						align="center"
						p="24px"
						borderRadius="20px"
					>
						<Flex
							direction="column"
							maxWidth="100%"
							my={{ sm: "14px" }}
							align="center"
						>
							{collectionIdentifier === firstCollectionIdentifier ? (
								<>
									<Text
										fontSize={{ sm: "lg", lg: "xl" }}
										color="#fff"
										fontWeight="bold"
										ms={{ sm: "8px", md: "0px" }}
									>
										Tired Ape Country Club
									</Text>
									<Text fontSize={{ sm: "sm", md: "md" }} color="gray.400">
										The 777 OG Apes from Tired Ape Country Club
									</Text>
								</>
							) : (
								<>
									<Text
										fontSize={{ sm: "lg", lg: "xl" }}
										color="#fff"
										fontWeight="bold"
										ms={{ sm: "8px", md: "0px" }}
									>
										Tired Ape Sporting Club
									</Text>
									<Text fontSize={{ sm: "sm", md: "md" }} color="gray.400">
										The most atlethic Nfts over the whole MultiversX Network
									</Text>
								</>
							)}
						</Flex>
					</Card>
				</Box>

				<Box
					mb={{ sm: "24px", md: "50px", xl: "20px" }}
					borderRadius="15px"
					px="0px"
					display="flex"
					flexDirection="column"
					justifyContent="center"
				>
					<Card
						p="16px"
						w={{ sm: "90%", xl: "100%" }}
						gridArea={{ xl: "1", "2xl": "auto" }}
						mx="auto"
					>
						<CardHeader p="12px 5px" mb="12px" align="start">
							<Flex
								direction={{ sm: "column", md: "row" }}
								alignItems="center"
								alignContent={{ sm: "center", md: "center", lg: "flex-start" }}
								w="100%"
							>
								<Text
									fontSize="xl"
									color="#fff"
									fontWeight="bold"
									mb="6px"
									mx="10px"
								>
									Your Apes
								</Text>
								<Flex
									direction="row"
									my={{ sm: "10px", md: "0px", lg: "0px" }}
									flexWrap="wrap"
									alignItems="center"
									justifyContent={{
										sm: "space-evenly",
										md: "flex-end",
										lg: "",
									}}
									w={{ md: "75%", lg: "85%" }}
								>
									<Button
										borderRadius="12px"
										transition="background .3s ease"
										bg={state.staked ? "brand.200" : "transparent"}
										_hover={{
											bg: "brand.200",
										}}
										_active={{
											bg: "brand.200",
										}}
										me={{ base: "none", lg: "20px" }}
										leftIcon={<Icon color="white" as={FaCube} me="6px" />}
										onClick={() =>
											dispatch({ type: "SWITCH_ACTIVE", payload: "staked" })
										}
										minW="90px"
									>
										<Text fontSize="xs" color="#fff" fontWeight="bold">
											STAKED ({stakedNfts.length})
										</Text>
									</Button>
									<Button
										borderRadius="12px"
										transition="background .3s ease"
										bg={state.unstaked ? "brand.200" : "transparent"}
										_hover={{
											bg: "brand.200",
										}}
										_active={{
											bg: "brand.200",
										}}
										me={{ base: "none", lg: "20px" }}
										leftIcon={
											<Icon color="white" as={IoDocumentsSharp} me="6px" />
										}
										onClick={() =>
											dispatch({ type: "SWITCH_ACTIVE", payload: "unstaked" })
										}
										minW="90px"
									>
										<Text fontSize="xs" color="#fff" fontWeight="bold">
											UNSTAKED ({unstakedNfts.length})
										</Text>
									</Button>
									<Button
										borderRadius="12px"
										transition="background .3s ease"
										bg={state.wallet ? "brand.200" : "transparent"}
										_hover={{
											bg: "brand.200",
										}}
										_active={{
											bg: "brand.200",
										}}
										me={state.wallet ? { base: "none", lg: "20px" } : null}
										leftIcon={<Icon color="white" as={FaPenFancy} me="6px" />}
										onClick={() =>
											dispatch({ type: "SWITCH_ACTIVE", payload: "wallet" })
										}
										minW="90px"
									>
										<Text fontSize="xs" color="#fff" fontWeight="bold">
											WALLET ({walletNfts.length})
										</Text>
									</Button>
									{state.wallet && walletNfts?.length !== 0 ? (
										<Button
											borderRadius="12px"
											transition="background .3s ease"
											bg="green.400"
											_hover={{
												bg: "green.600",
											}}
											_active={{
												bg: "green.600",
											}}
											leftIcon={<Icon color="white" as={FaArrowUp} me="6px" />}
											onClick={() => {
												onOpenStakeAllConfirm();
											}}
											minW="90px"
										>
											<Text fontSize="xs" color="#fff" fontWeight="bold">
												Stake All
											</Text>
										</Button>
									) : null}
								</Flex>
							</Flex>
						</CardHeader>
						<CardBody>
							<Flex
								flexDirection="row"
								justifyContent="space-evenly"
								alignItems="center"
								alignContent="flex-start"
								flexWrap="wrap"
								gap="30px"
								flexBasis="auto"
								boxSizing="border-box"
							>
								{state.staked &&
									stakedNfts.map((nft, index) => (
										<Box
											alignItems="center"
											justifyContent="center"
											alignContent="center"
											mb="20px"
											maxWidth="300px"
											key={index}
										>
											<Image
												src={nft.media[0].thumbnailUrl}
												borderRadius="20px"
												style={
													nft.metadata.attributes?.[7]?.value === "Ape Earring"
														? { border: "3px solid gold" }
														: null
												}
												mb="10px"
												boxShadow=" 0 1px 1px hsl(0deg 0% 0% / 0.075),
												0 2px 2px hsl(0deg 0% 0% / 0.075),
												0 4px 4px hsl(0deg 0% 0% / 0.075),
												0 8px 8px hsl(0deg 0% 0% / 0.075),
												0 16px 16px hsl(0deg 0% 0% / 0.075)"
												_hover={{
													transition: "all 0.3s ease",
													opacity: 0.5,
												}}
												onClick={() => {
													setModalNft(nft);
													onOpen();
												}}
											/>
											<Text
												fontSize="md"
												color="#fff"
												fontWeight="bold"
												align="center"
											>
												{nft.name}
											</Text>
										</Box>
									))}
								{state.unstaked &&
									unstakedNfts.map((nft, index) => (
										<Box
											alignItems="center"
											justifyContent="center"
											alignContent="center"
											width="270px"
											mb="20px"
											flexGrow={1}
											key={index}
										>
											<Image
												src={nft.media[0].thumbnailUrl}
												borderRadius="20px"
												mb="10px"
												boxShadow=" 0 1px 1px hsl(0deg 0% 0% / 0.075),
												0 2px 2px hsl(0deg 0% 0% / 0.075),
												0 4px 4px hsl(0deg 0% 0% / 0.075),
												0 8px 8px hsl(0deg 0% 0% / 0.075),
												0 16px 16px hsl(0deg 0% 0% / 0.075)"
												_hover={{
													transition: "all 0.3s ease",
													opacity: 0.5,
												}}
												style={
													nft.metadata.attributes?.[7]?.value === "Ape Earring"
														? { border: "3px solid gold" }
														: null
												}
												onClick={() => {
													setModalNft(nft);
													onOpen();
												}}
											/>
											<Text
												fontSize="md"
												color="#fff"
												fontWeight="bold"
												align="center"
											>
												{nft.name}
											</Text>
										</Box>
									))}
								{state.wallet &&
									walletNfts.map((nft, index) => (
										<Box
											alignItems="center"
											justifyContent="center"
											alignContent="center"
											width="270px"
											mb="20px"
											key={index}
										>
											<Image
												src={nft.media[0].thumbnailUrl}
												borderRadius="20px"
												mb="10px"
												boxShadow=" 0 1px 1px hsl(0deg 0% 0% / 0.075),
												0 2px 2px hsl(0deg 0% 0% / 0.075),
												0 4px 4px hsl(0deg 0% 0% / 0.075),
												0 8px 8px hsl(0deg 0% 0% / 0.075),
												0 16px 16px hsl(0deg 0% 0% / 0.075)"
												_hover={{
													transition: "all 0.3s ease",
													opacity: 0.5,
												}}
												style={
													nft.metadata.attributes?.[7]?.value === "Ape Earring"
														? { border: "3px solid gold" }
														: null
												}
												onClick={() => {
													setModalNft(nft);
													onOpen();
												}}
											/>
											<Text
												fontSize="md"
												color="#fff"
												fontWeight="bold"
												align="center"
											>
												{nft.name}
											</Text>
										</Box>
									))}
							</Flex>
						</CardBody>
					</Card>
				</Box>
			</Flex>
			<Modal isOpen={isOpen} onClose={onClose} size="4xl">
				<ModalOverlay />
				<ModalContent mx={{ sm: 5, lg: 0 }}>
					<ModalBody>
						<Flex
							direction={{ sm: "column", md: "column", lg: "row" }}
							alignItems="center"
						>
							<ProgressiveImage
								preview={modalNft?.media[0]?.thumbnailUrl}
								src={modalNft?.media[0]?.url}
								render={(src, style) => (
									<Image
										src={src}
										className="img-fluid border-radius-lg shadow d-none d-sm-block"
										alt=""
										borderRadius="20px"
										w={{ sm: "65%", md: "50%", lg: "36%" }}
										mr="20px"
									/>
								)}
							/>

							<Flex direction="column">
								<Flex direction="row" flexWrap="wrap">
									<Box
										flex="0 0 auto"
										w="100%"
										textAlign="left"
										mb="20px"
										mt="10px"
									>
										<Text fontSize={{ sm: "xl", md: "2xl", lg: "2xl" }}>
											{modalNft?.name}
										</Text>
									</Box>
									<Box flex="0 0 auto" w="50%">
										<p>Attributes</p>
									</Box>
									<Box flex="0 0 auto" w="50%">
										<Text fontSize="md" align="right">
											Rank:{" "}
											<Text as="b">
												{modalNft?.rarities?.statistical?.rank ||
													modalNft?.rank}
											</Text>
										</Text>
									</Box>
								</Flex>

								<SimpleGrid
									columns={{ sm: 2, md: 3, lg: 3 }}
									spacing={5}
									mt="10px"
								>
									{modalNft?.metadata?.attributes?.map((attribute, index) => (
										<Box
											border="1px"
											borderColor="gray.200"
											borderRadius="10px"
											p="0.5rem"
											key={index}
											style={
												attribute.value === "Ape Earring"
													? { border: "3px solid gold" }
													: null
											}
										>
											<Text fontSize="xs" color="gray.400">
												{attribute?.trait_type}
											</Text>
											<Text fontSize="sm">{attribute?.value}</Text>
										</Box>
									))}
								</SimpleGrid>
							</Flex>
						</Flex>
					</ModalBody>

					<ModalFooter>
						{state.unstaked && (
							<Text mr="4px">
								Unlockable at: {timestampToDateTime(modalNft?.deadline)}
							</Text>
						)}
						<Button
							colorScheme="blue"
							mx="12px"
							onClick={async () => {
								if (state.staked) {
									onOpenUnstakeConfirm();
									return;
								}
								if (state.unstaked) {
									onClose();

									setTxSigned(false);
									setTxCompleted(false);

									onOpenTransactionSign();

									const txHash = await makeTransaction({
										transaction: {
											receiver: contractAddress,
											data:
												(collectionIdentifier === firstCollectionIdentifier
													? "withdrawFirst"
													: "withdrawSecond") +
												"@" +
												modalNft.identifier.split("-")[2],
											gasLimit: 16_000_000,
											value: 0,
										},
									});

									setTxSigned(true);

									const txResult = await whenCompleted(txHash, {
										interval: 2000,
									});

									setTxCompleted(true);

									getUserStakedNfts();
									getUserUnstakedNfts();
									getWalletNfts();

									return;
								}
								if (state.wallet) {
									onOpenStakeConfirm();
									return;
								}
							}}
							disabled={modalNft?.deadline > Date.now() / 1000}
						>
							{state.staked && "Unstake"}
							{state.unstaked && "Withdraw"}
							{state.wallet && "Stake"}
						</Button>
						{state.unstaked && (
							<Button
								colorScheme="blue"
								mr="12px"
								onClick={() => {
									onOpenCancelUnstake();
									return;
								}}
							>
								Cancel Unstake
							</Button>
						)}
						<Button variant="outline" mr={3} onClick={onClose}>
							Close
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<Modal isOpen={isOpenStakeConfirm} onClose={onCloseStakeConfirm}>
				<ModalOverlay />
				<ModalContent mx={{ sm: 5, lg: 0 }}>
					<ModalBody>
						<Text>
							Are you sure you want to stake {modalNft?.name}?<br />I understand
							I will have a 6 days unstake period in which I won&apos;t receive
							any rewards if I decide to unstake my Apes.
						</Text>
					</ModalBody>

					<ModalFooter>
						<Button
							colorScheme="blue"
							mr="10px"
							onClick={async () => {
								onCloseStakeConfirm();
								onClose();

								setTxSigned(false);
								setTxCompleted(false);

								onOpenTransactionSign();

								const txHash = await makeTransaction({
									transaction: {
										receiver: address,
										data:
											"ESDTNFTTransfer" +
											"@" +
											Buffer.from(modalNft.collection).toString("hex") +
											"@" +
											modalNft.identifier.split("-")[2] +
											"@" +
											"01" +
											"@" +
											"000000000000000005007499a1f0fadefe8675b4af7fbd28dd23e0ba4e89a938" + //SC in hex
											"@" +
											Buffer.from(
												collectionIdentifier === firstCollectionIdentifier
													? "stakeFirst"
													: "stakeSecond"
											).toString("hex"),
										gasLimit: 8_000_000,
										value: 0,
									},
								});

								setTxSigned(true);

								const txResult = await whenCompleted(txHash, {
									interval: 2000,
								});

								setTxCompleted(true);

								getUserStakedNfts();
								getUserUnstakedNfts();
								getWalletNfts();
							}}
						>
							Confirm
						</Button>
						<Button variant="outline" mr={3} onClick={onCloseStakeConfirm}>
							Cancel
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<Modal isOpen={isOpenStakeAllConfirm} onClose={onCloseStakeAllConfirm}>
				<ModalOverlay />
				<ModalContent mx={{ sm: 5, lg: 0 }}>
					<ModalBody>
						<Text>
							Are you sure you want to <b>stake {walletNfts?.length} NFTs</b>?
							<br />I understand I will have a 6 days unstake period in which I
							won&apos;t receive any rewards if I decide to unstake my Apes.
						</Text>
					</ModalBody>

					<ModalFooter>
						<Button
							colorScheme="blue"
							mr="10px"
							onClick={async () => {
								onCloseStakeAllConfirm();
								onClose();

								setTxSigned(false);
								setTxCompleted(false);

								onOpenTransactionSign();

								let nNftsHex = walletNfts?.length.toString(16);
								if (nNftsHex.length % 2 !== 0) {
									nNftsHex = "0" + nNftsHex;
								}

								let data =
									"MultiESDTNFTTransfer" +
									"@" +
									"000000000000000005007499a1f0fadefe8675b4af7fbd28dd23e0ba4e89a938" + //SC in hex
									"@" +
									nNftsHex;

								walletNfts?.forEach((nft) => {
									data += "@" + Buffer.from(nft.collection).toString("hex");
									data += "@" + nft.identifier.split("-")[2];
									data += "@" + "01";
								});

								data += "@";
								data += Buffer.from(
									collectionIdentifier === firstCollectionIdentifier
										? "stakeMultipleFirst"
										: "stakeMultipleSecond"
								).toString("hex");

								const txHash = await makeTransaction({
									transaction: {
										receiver: address,
										data: data,
										gasLimit: 1_000_000 + 6_000_000 * walletNfts?.length,
										value: 0,
									},
								});

								setTxSigned(true);

								const txResult = await whenCompleted(txHash, {
									interval: 2000,
								});

								setTxCompleted(true);

								getWalletNfts();
								getUserStakedNfts();
								getUserUnstakedNfts();
							}}
						>
							Confirm
						</Button>
						<Button variant="outline" mr={3} onClick={onCloseStakeAllConfirm}>
							Cancel
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<Modal isOpen={isOpenUnstakeConfirm} onClose={onCloseUnstakeConfirm}>
				<ModalOverlay />
				<ModalContent mx={{ sm: 5, lg: 0 }}>
					<ModalBody>
						<Text>
							Are you sure you want to continue?
							<br />
							By unstaking you won&apos;t be eligible for rewards for{" "}
							<b>{modalNft?.name}</b> and you will have to wait 6 days to
							withdraw your NFT.
						</Text>
					</ModalBody>

					<ModalFooter>
						<Button
							colorScheme="blue"
							mr="10px"
							onClick={async () => {
								onCloseUnstakeConfirm();
								onClose();

								setTxSigned(false);
								setTxCompleted(false);

								onOpenTransactionSign();

								const txHash = await makeTransaction({
									transaction: {
										receiver: contractAddress,
										data:
											(collectionIdentifier === firstCollectionIdentifier
												? "unstakeFirst"
												: "unstakeSecond") +
											"@" +
											modalNft.identifier.split("-")[2],
										gasLimit: 16_000_000,
										value: 0,
									},
								});

								setTxSigned(true);

								const txResult = await whenCompleted(txHash, {
									interval: 2000,
								});

								setTxCompleted(true);

								getUserStakedNfts();
								getUserUnstakedNfts();
								getWalletNfts();
							}}
						>
							Confirm
						</Button>
						<Button variant="outline" mr={3} onClick={onCloseUnstakeConfirm}>
							Cancel
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<Modal isOpen={isOpenCancelUnstake} onClose={onCloseCancelUnstake}>
				<ModalOverlay />
				<ModalContent mx={{ sm: 5, lg: 0 }}>
					<ModalBody>
						<Text>
							Are you sure you want to continue?
							<br />
							<b>{modalNft?.name}</b> will get back to stake and will be
							eligible for rewards.
							<br />
							If you want to unstake your NFT, you will have to wait again for 6
							days.
						</Text>
					</ModalBody>

					<ModalFooter>
						<Button
							colorScheme="blue"
							mr="10px"
							onClick={async () => {
								onCloseCancelUnstake();
								onClose();

								setTxSigned(false);
								setTxCompleted(false);

								onOpenTransactionSign();

								const txHash = await makeTransaction({
									transaction: {
										receiver: contractAddress,
										data:
											(collectionIdentifier === firstCollectionIdentifier
												? "cancelUnstakeFirst"
												: "cancelUnstakeSecond") +
											"@" +
											modalNft.identifier.split("-")[2],
										gasLimit: 16_000_000,
										value: 0,
									},
								});

								setTxSigned(true);

								const txResult = await whenCompleted(txHash, {
									interval: 2000,
								});

								setTxCompleted(true);

								getUserUnstakedNfts();
								getUserStakedNfts();
								getWalletNfts();
							}}
						>
							Confirm
						</Button>
						<Button variant="outline" mr={3} onClick={onCloseUnstakeConfirm}>
							Cancel
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<Modal isOpen={isOpenTransactionSign} onClose={onCloseTransactionSign}>
				<ModalOverlay />
				<ModalContent mx={{ sm: 5, lg: 0 }}>
					<ModalBody>
						<Text>
							{!txSigned
								? "Check your MultiversX Wallet to sign the transaction"
								: null}
							{txSigned && !txCompleted
								? "Transaction is being processed, please wait..."
								: null}
							{txCompleted ? "Transaction completed!" : null}
						</Text>
					</ModalBody>
					<ModalFooter>
						<Button variant="outline" mr={3} onClick={onCloseTransactionSign}>
							{txSigned ? "Close" : "Cancel"}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}

export default Profile;
