"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenOperationsOutcomeParser = void 0;
const address_1 = require("../address");
const errors_1 = require("../errors");
const codec_1 = require("./codec");
class TokenOperationsOutcomeParser {
    parseIssueFungible(transaction) {
        this.ensureNoError(transaction);
        const event = this.findSingleEventByIdentifier(transaction, "issue");
        const tokenIdentifier = this.extractTokenIdentifier(event);
        return { tokenIdentifier: tokenIdentifier };
    }
    parseIssueNonFungible(transaction) {
        this.ensureNoError(transaction);
        const event = this.findSingleEventByIdentifier(transaction, "issueNonFungible");
        const tokenIdentifier = this.extractTokenIdentifier(event);
        return { tokenIdentifier: tokenIdentifier };
    }
    parseIssueSemiFungible(transaction) {
        this.ensureNoError(transaction);
        const event = this.findSingleEventByIdentifier(transaction, "issueSemiFungible");
        const tokenIdentifier = this.extractTokenIdentifier(event);
        return { tokenIdentifier: tokenIdentifier };
    }
    parseRegisterMetaESDT(transaction) {
        this.ensureNoError(transaction);
        const event = this.findSingleEventByIdentifier(transaction, "registerMetaESDT");
        const tokenIdentifier = this.extractTokenIdentifier(event);
        return { tokenIdentifier: tokenIdentifier };
    }
    parseRegisterAndSetAllRoles(transaction) {
        this.ensureNoError(transaction);
        const eventRegister = this.findSingleEventByIdentifier(transaction, "registerAndSetAllRoles");
        const tokenIdentifier = this.extractTokenIdentifier(eventRegister);
        const eventSetRole = this.findSingleEventByIdentifier(transaction, "ESDTSetRole");
        const roles = eventSetRole.topics.slice(3).map(topic => topic.valueOf().toString());
        return { tokenIdentifier, roles };
    }
    parseSetBurnRoleGlobally(transaction) {
        this.ensureNoError(transaction);
        return {};
    }
    parseUnsetBurnRoleGlobally(transaction) {
        this.ensureNoError(transaction);
        return {};
    }
    parseSetSpecialRole(transaction) {
        this.ensureNoError(transaction);
        const event = this.findSingleEventByIdentifier(transaction, "ESDTSetRole");
        const userAddress = event.address.toString();
        const tokenIdentifier = this.extractTokenIdentifier(event);
        const roles = event.topics.slice(3).map(topic => topic.valueOf().toString());
        return { userAddress, tokenIdentifier, roles };
    }
    parseNFTCreate(transaction) {
        this.ensureNoError(transaction);
        const event = this.findSingleEventByIdentifier(transaction, "ESDTNFTCreate");
        const tokenIdentifier = this.extractTokenIdentifier(event);
        const nonce = this.extractNonce(event);
        const initialQuantity = this.extractAmount(event);
        return { tokenIdentifier, nonce, initialQuantity };
    }
    parseLocalMint(transaction) {
        this.ensureNoError(transaction);
        const event = this.findSingleEventByIdentifier(transaction, "ESDTLocalMint");
        const userAddress = event.address.toString();
        const tokenIdentifier = this.extractTokenIdentifier(event);
        const nonce = this.extractNonce(event);
        const mintedSupply = this.extractAmount(event);
        return { userAddress, tokenIdentifier, nonce, mintedSupply };
    }
    parseLocalBurn(transaction) {
        this.ensureNoError(transaction);
        const event = this.findSingleEventByIdentifier(transaction, "ESDTLocalBurn");
        const userAddress = event.address.toString();
        const tokenIdentifier = this.extractTokenIdentifier(event);
        const nonce = this.extractNonce(event);
        const burntSupply = this.extractAmount(event);
        return { userAddress, tokenIdentifier, nonce, burntSupply };
    }
    parsePause(transaction) {
        this.ensureNoError(transaction);
        const _ = this.findSingleEventByIdentifier(transaction, "ESDTPause");
        return {};
    }
    parseUnpause(transaction) {
        this.ensureNoError(transaction);
        const _ = this.findSingleEventByIdentifier(transaction, "ESDTUnPause");
        return {};
    }
    parseFreeze(transaction) {
        this.ensureNoError(transaction);
        const event = this.findSingleEventByIdentifier(transaction, "ESDTFreeze");
        const tokenIdentifier = this.extractTokenIdentifier(event);
        const nonce = this.extractNonce(event);
        const balance = this.extractAmount(event);
        const userAddress = this.extractAddress(event);
        return { userAddress, tokenIdentifier, nonce, balance };
    }
    parseUnfreeze(transaction) {
        this.ensureNoError(transaction);
        const event = this.findSingleEventByIdentifier(transaction, "ESDTUnFreeze");
        const tokenIdentifier = this.extractTokenIdentifier(event);
        const nonce = this.extractNonce(event);
        const balance = this.extractAmount(event);
        const userAddress = this.extractAddress(event);
        return { userAddress, tokenIdentifier, nonce, balance };
    }
    parseWipe(transaction) {
        this.ensureNoError(transaction);
        const event = this.findSingleEventByIdentifier(transaction, "ESDTWipe");
        const tokenIdentifier = this.extractTokenIdentifier(event);
        const nonce = this.extractNonce(event);
        const balance = this.extractAmount(event);
        const userAddress = this.extractAddress(event);
        return { userAddress, tokenIdentifier, nonce, balance };
    }
    parseUpdateAttributes(transaction) {
        var _a;
        this.ensureNoError(transaction);
        const event = this.findSingleEventByIdentifier(transaction, "ESDTNFTUpdateAttributes");
        const tokenIdentifier = this.extractTokenIdentifier(event);
        const nonce = this.extractNonce(event);
        const attributes = (_a = event.topics[3]) === null || _a === void 0 ? void 0 : _a.valueOf();
        return { tokenIdentifier, nonce, attributes };
    }
    parseAddQuantity(transaction) {
        this.ensureNoError(transaction);
        const event = this.findSingleEventByIdentifier(transaction, "ESDTNFTAddQuantity");
        const tokenIdentifier = this.extractTokenIdentifier(event);
        const nonce = this.extractNonce(event);
        const addedQuantity = this.extractAmount(event);
        return { tokenIdentifier, nonce, addedQuantity };
    }
    parseBurnQuantity(transaction) {
        this.ensureNoError(transaction);
        const event = this.findSingleEventByIdentifier(transaction, "ESDTNFTBurn");
        const tokenIdentifier = this.extractTokenIdentifier(event);
        const nonce = this.extractNonce(event);
        const burntQuantity = this.extractAmount(event);
        return { tokenIdentifier, nonce, burntQuantity };
    }
    ensureNoError(transaction) {
        var _a;
        for (const event of transaction.logs.events) {
            if (event.identifier == "signalError") {
                const data = Buffer.from(event.data.substring(1), "hex").toString();
                const message = (_a = event.topics[1]) === null || _a === void 0 ? void 0 : _a.valueOf().toString();
                throw new errors_1.ErrCannotParseTransactionOutcome(transaction.hash, `encountered signalError: ${message} (${data})`);
            }
        }
    }
    findSingleEventByIdentifier(transaction, identifier) {
        const events = this.gatherAllEvents(transaction).filter(event => event.identifier == identifier);
        if (events.length == 0) {
            throw new errors_1.ErrCannotParseTransactionOutcome(transaction.hash, `cannot find event of type ${identifier}`);
        }
        if (events.length > 1) {
            throw new errors_1.ErrCannotParseTransactionOutcome(transaction.hash, `more than one event of type ${identifier}`);
        }
        return events[0];
    }
    gatherAllEvents(transaction) {
        const allEvents = [];
        allEvents.push(...transaction.logs.events);
        for (const item of transaction.contractResults.items) {
            allEvents.push(...item.logs.events);
        }
        return allEvents;
    }
    extractTokenIdentifier(event) {
        var _a;
        return (_a = event.topics[0]) === null || _a === void 0 ? void 0 : _a.valueOf().toString();
    }
    extractNonce(event) {
        var _a;
        return codec_1.bufferToBigInt((_a = event.topics[1]) === null || _a === void 0 ? void 0 : _a.valueOf()).toFixed(0);
    }
    extractAmount(event) {
        var _a;
        return codec_1.bufferToBigInt((_a = event.topics[2]) === null || _a === void 0 ? void 0 : _a.valueOf()).toFixed(0);
    }
    extractAddress(event) {
        var _a;
        return address_1.Address.fromBuffer((_a = event.topics[3]) === null || _a === void 0 ? void 0 : _a.valueOf()).toString();
    }
}
exports.TokenOperationsOutcomeParser = TokenOperationsOutcomeParser;
