"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Compatibility = void 0;
const address_1 = require("./address");
/**
 * For internal use only.
 */
class Compatibility {
    /**
     * For internal use only.
     */
    static guardAddressIsSetAndNonZero(address, context, resolution) {
        if (!this.areWarningsEnabled) {
            return;
        }
        if (!address || address.bech32() == "") {
            console.warn(`${context}: address should be set; ${resolution}. In the future, this will throw an exception instead of emitting a WARN.`);
        }
        else if (address.bech32() == address_1.Address.Zero().bech32()) {
            console.warn(`${context}: address should not be the 'zero' address (also known as the 'contracts deployment address'); ${resolution}. In the future, this will throw an exception instead of emitting a WARN.`);
        }
    }
}
exports.Compatibility = Compatibility;
Compatibility.areWarningsEnabled = true;
