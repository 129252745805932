import React, { useReducer } from "react";

// Tired imports
import { useAuth, useTransaction } from "@elrond-giants/erd-react-hooks";
import { MyApiNetworkProvider } from "./../MyApiNetworkProvider/index.js";
import { ProxyNetworkProvider } from "@elrondnetwork/erdjs-network-providers";
import {
	Address,
	Query,
	AddressValue,
	ContractFunction,
	AbiRegistry,
	SmartContractAbi,
	SmartContract,
	U32Value,
	ResultsParser,
	BytesValue,
} from "@elrondnetwork/erdjs";
import { useState } from "react";
import {
	contractAddress,
	firstCollectionIdentifier,
	secondCollectionIdentifier,
} from "./../config.js";
import { BigNumber } from "bignumber.js";
import { useLocation } from "react-router-dom";

// Chakra imports
import {
	Avatar,
	AvatarBadge,
	AvatarGroup,
	Box,
	Button,
	CircularProgress,
	CircularProgressLabel,
	DarkMode,
	Flex,
	Grid,
	Icon,
	Image,
	Link,
	SimpleGrid,
	Switch,
	Text,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
} from "@chakra-ui/react";

import { useHistory } from "react-router-dom";

//import stream from "stream";
//import crypto from "crypto";
import CryptoJS from "crypto-js";
import randomBytes from "randombytes";

// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import LineChart from "components/Charts/LineChart";
import IconBox from "components/Icons/IconBox";
import { HSeparator, VSeparator } from "components/Separator/Separator";

// Icons
import { FaCube, FaPenFancy, FaArrowUp } from "react-icons/fa";
import { IoDocumentsSharp } from "react-icons/io5";

import ProgressiveImage from "react-progressive-image-loading";
import axios, { AxiosResponse } from "axios";

function BotHandle() {
	const history = useHistory();

	const apiNetworkProvider = new MyApiNetworkProvider(
		"https://api.multiversx.com"
	);
	const proxy = new ProxyNetworkProvider("https://gateway.multiversx.com");

	const location = useLocation();
	const params = new URLSearchParams(location.search);

	const uuid = params.get("uuid");

	const {
		isOpen: isOpenTransactionSign,
		onOpen: onOpenTransactionSign,
		onClose: onCloseTransactionSign,
	} = useDisclosure();
	const [txSigned, setTxSigned] = useState(false);
	const [txCompleted, setTxCompleted] = useState(false);

	const { makeTransaction, whenCompleted } = useTransaction();

	const { address, authenticated, login, logout } = useAuth();

	const [account, setAccount] = useState(null);

	const [message, setMessage] = useState("Registering your account...");

	const algorithm = "aes-256-ctr";

	const encryptJSON = (json: any) => {
		const string = JSON.stringify(json);

		const key = "kKONQyBng0RKjg3lIhHY9qNBVrfocX8L";

		// Convert the plaintext to a WordArray object
		const plaintext = "my secret message";
		const plaintextWordArray = CryptoJS.enc.Utf8.parse(string);

		// Generate a random initialization vector
		const iv = CryptoJS.lib.WordArray.random(16);

		// Encrypt the plaintext using AES-256-CTR
		const ciphertext = CryptoJS.AES.encrypt(
			plaintextWordArray,
			CryptoJS.enc.Utf8.parse(key),
			{
				iv: iv,
				mode: CryptoJS.mode.CTR,
			}
		);

		// Combine the initialization vector and ciphertext into a single string
		const ivHex = iv.toString(CryptoJS.enc.Hex);
		const ciphertextHex = ciphertext.ciphertext.toString(CryptoJS.enc.Hex);
		const combined = `${ivHex}:${ciphertextHex}`;

		// Log the combined string
		console.log("iv:", ivHex);
		console.log("ciphertext:", ciphertext.toString());
		console.log("combined:", combined);

		return {
			content: combined,
		};
		/*
		
		const iv = crypto.randomBytes(16);
		const cipher = crypto.createCipheriv(
			algorithm,
			"kKONQyBng0RKjg3lIhHY9qNBVrfocX8L",
			iv
		);

		const encrypted = Buffer.concat([cipher.update(string), cipher.final()]);

		return {
			content: `${iv.toString("hex")}:${encrypted.toString("hex")}`,
		};*/
	};

	const getUser = async () => {
		const account = await apiNetworkProvider.getAccount(new Address(address));
		setAccount(account);
	};

	React.useEffect(() => {
		if (!authenticated) {
			history.push("/auth");
			return;
		}

		const user = {
			wallet: address,
			uuid: uuid ?? "",
		};
		const encrypted = encryptJSON(user);

		console.log(JSON.stringify(encrypted));
		const xhr = new XMLHttpRequest();
		xhr.open("POST", "https://discordbot.tiredclub.art/api/register");
		xhr.setRequestHeader("Accept", "application/json");
		xhr.setRequestHeader("Content-Type", "application/json");

		xhr.onreadystatechange = function () {
			if (xhr.readyState === 4) {
				const responseJson = JSON.parse(xhr.responseText);
				setMessage(responseJson.message + " You can now close this page.");
			}
		};
		xhr.send(JSON.stringify(encrypted));
	}, [authenticated, address]);

	/*
	React.useEffect(() => {
		const user: User = {
			wallet: address,
			uuid: uuid ?? "",
		};
		const encrypted = encryptJSON(user);
		console.log(user);
		console.log(encrypted);

		const xhr = new XMLHttpRequest();
		xhr.open("POST", "https://discordbot.tiredclub.art/api/register");
		xhr.setRequestHeader("Accept", "application/json");
		xhr.setRequestHeader("Content-Type", "application/json");

		xhr.onreadystatechange = function () {
			if (xhr.readyState === 4) {
				const responseJson = JSON.parse(xhr.responseText);
				setMessage(responseJson.message + " You can now close this page.");
			}
		};
		xhr.send(JSON.stringify(encrypted));
	}, []);
*/
	return (
		<>
			<Flex direction="column" mt={{ sm: "150px", md: "100px" }}>
				<Box
					mb={{ sm: "24px", md: "50px", xl: "20px" }}
					borderRadius="15px"
					px="0px"
					display="flex"
					flexDirection="column"
					justifyContent="center"
				>
					{/* Header */}
					<Card
						direction={{ sm: "column", md: "row" }}
						mx="auto"
						maxH="330px"
						w={{ sm: "90%", xl: "100%" }}
						justifyContent={{ sm: "center", md: "space-between" }}
						align="center"
						p="24px"
						borderRadius="20px"
					>
						<Flex
							direction="column"
							maxWidth="100%"
							my={{ sm: "14px" }}
							align="center"
						>
							<Text
								fontSize={{ sm: "lg", lg: "xl" }}
								color="#fff"
								fontWeight="bold"
								ms={{ sm: "8px", md: "0px" }}
							>
								{message}
							</Text>
						</Flex>
					</Card>
				</Box>
			</Flex>
			<Modal isOpen={isOpenTransactionSign} onClose={onCloseTransactionSign}>
				<ModalOverlay />
				<ModalContent mx={{ sm: 5, lg: 0 }}>
					<ModalBody>
						<Text>
							{!txSigned
								? "Check your MultiversX Wallet to sign the transaction"
								: null}
							{txSigned && !txCompleted
								? "Transaction is being processed, please wait..."
								: null}
							{txCompleted ? "Transaction completed!" : null}
						</Text>
					</ModalBody>
					<ModalFooter>
						<Button variant="outline" mr={3} onClick={onCloseTransactionSign}>
							{txSigned ? "Close" : "Cancel"}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}

export default BotHandle;
