"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.onPrimitiveTypeSelect = exports.onPrimitiveValueSelect = exports.onTypedValueSelect = exports.onTypeSelect = void 0;
const errors = __importStar(require("../../errors"));
const address_1 = require("./address");
const boolean_1 = require("./boolean");
const bytes_1 = require("./bytes");
const enum_1 = require("./enum");
const generic_1 = require("./generic");
const h256_1 = require("./h256");
const numerical_1 = require("./numerical");
const nothing_1 = require("./nothing");
const struct_1 = require("./struct");
const tokenIdentifier_1 = require("./tokenIdentifier");
const tuple_1 = require("./tuple");
const types_1 = require("./types");
const genericArray_1 = require("./genericArray");
const string_1 = require("./string");
// TODO: Extend functionality or rename wrt. restricted / reduced functionality (not all types are handled: composite, variadic).
function onTypeSelect(type, selectors) {
    if (type.hasExactClass(generic_1.OptionType.ClassName)) {
        return selectors.onOption();
    }
    if (type.hasExactClass(generic_1.ListType.ClassName)) {
        return selectors.onList();
    }
    if (type.hasExactClass(genericArray_1.ArrayVecType.ClassName)) {
        return selectors.onArray();
    }
    if (type.hasClassOrSuperclass(types_1.PrimitiveType.ClassName)) {
        return selectors.onPrimitive();
    }
    if (type.hasExactClass(struct_1.StructType.ClassName)) {
        return selectors.onStruct();
    }
    if (type.hasExactClass(tuple_1.TupleType.ClassName)) {
        return selectors.onTuple();
    }
    if (type.hasExactClass(enum_1.EnumType.ClassName)) {
        return selectors.onEnum();
    }
    if (selectors.onOther) {
        return selectors.onOther();
    }
    throw new errors.ErrTypingSystem(`type isn't known: ${type}`);
}
exports.onTypeSelect = onTypeSelect;
function onTypedValueSelect(value, selectors) {
    if (value.hasClassOrSuperclass(types_1.PrimitiveValue.ClassName)) {
        return selectors.onPrimitive();
    }
    if (value.hasExactClass(generic_1.OptionValue.ClassName)) {
        return selectors.onOption();
    }
    if (value.hasExactClass(generic_1.List.ClassName)) {
        return selectors.onList();
    }
    if (value.hasExactClass(genericArray_1.ArrayVec.ClassName)) {
        return selectors.onArray();
    }
    if (value.hasExactClass(struct_1.Struct.ClassName)) {
        return selectors.onStruct();
    }
    if (value.hasExactClass(tuple_1.Tuple.ClassName)) {
        return selectors.onTuple();
    }
    if (value.hasExactClass(enum_1.EnumValue.ClassName)) {
        return selectors.onEnum();
    }
    if (selectors.onOther) {
        return selectors.onOther();
    }
    throw new errors.ErrTypingSystem(`value isn't typed: ${value}`);
}
exports.onTypedValueSelect = onTypedValueSelect;
function onPrimitiveValueSelect(value, selectors) {
    if (value.hasExactClass(boolean_1.BooleanValue.ClassName)) {
        return selectors.onBoolean();
    }
    if (value.hasClassOrSuperclass(numerical_1.NumericalValue.ClassName)) {
        return selectors.onNumerical();
    }
    if (value.hasExactClass(address_1.AddressValue.ClassName)) {
        return selectors.onAddress();
    }
    if (value.hasExactClass(bytes_1.BytesValue.ClassName)) {
        return selectors.onBytes();
    }
    if (value.hasExactClass(string_1.StringValue.ClassName)) {
        return selectors.onString();
    }
    if (value.hasExactClass(h256_1.H256Value.ClassName)) {
        return selectors.onH256();
    }
    if (value.hasExactClass(tokenIdentifier_1.TokenIdentifierValue.ClassName)) {
        return selectors.onTypeIdentifier();
    }
    if (value.hasExactClass(nothing_1.NothingValue.ClassName)) {
        return selectors.onNothing();
    }
    if (selectors.onOther) {
        return selectors.onOther();
    }
    throw new errors.ErrTypingSystem(`value isn't a primitive: ${value.getType()}`);
}
exports.onPrimitiveValueSelect = onPrimitiveValueSelect;
function onPrimitiveTypeSelect(type, selectors) {
    if (type.hasExactClass(boolean_1.BooleanType.ClassName)) {
        return selectors.onBoolean();
    }
    if (type.hasClassOrSuperclass(numerical_1.NumericalType.ClassName)) {
        return selectors.onNumerical();
    }
    if (type.hasExactClass(address_1.AddressType.ClassName)) {
        return selectors.onAddress();
    }
    if (type.hasExactClass(bytes_1.BytesType.ClassName)) {
        return selectors.onBytes();
    }
    if (type.hasExactClass(string_1.StringType.ClassName)) {
        return selectors.onString();
    }
    if (type.hasExactClass(h256_1.H256Type.ClassName)) {
        return selectors.onH256();
    }
    if (type.hasExactClass(tokenIdentifier_1.TokenIdentifierType.ClassName)) {
        return selectors.onTokenIndetifier();
    }
    if (type.hasExactClass(nothing_1.NothingType.ClassName)) {
        return selectors.onNothing();
    }
    if (selectors.onOther) {
        return selectors.onOther();
    }
    throw new errors.ErrTypingSystem(`type isn't a known primitive: ${type}`);
}
exports.onPrimitiveTypeSelect = onPrimitiveTypeSelect;
