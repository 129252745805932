import React, { useReducer } from "react";

// Tired imports
import { useAuth, useTransaction } from "@elrond-giants/erd-react-hooks";
import { ApiNetworkProvider } from "@elrondnetwork/erdjs-network-providers";
import { ProxyNetworkProvider } from "@elrondnetwork/erdjs-network-providers";
import {
	Address,
	Query,
	AddressValue,
	ContractFunction,
} from "@elrondnetwork/erdjs";
import { useState } from "react";
import {
	contractAddress,
	sticketMintAddress,
	mintAddress,
	secondCollectionIdentifier,
} from "./../config";
import { BigNumber } from "bignumber.js";
import { useHistory } from "react-router-dom";

// Chakra imports
import {
	Avatar,
	AvatarBadge,
	AvatarGroup,
	Box,
	Button,
	CircularProgress,
	CircularProgressLabel,
	DarkMode,
	Flex,
	Grid,
	Icon,
	Image,
	Link,
	Switch,
	Text,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Spinner,
	AlertTitle,
	AlertDescription,
	CloseButton,
	Alert,
	Input,
	InputGroup,
	InputRightAddon,
} from "@chakra-ui/react";

// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import LineChart from "components/Charts/LineChart";
import IconBox from "components/Icons/IconBox";
import { HSeparator, VSeparator } from "components/Separator/Separator";

// Icons
import {
	CarIcon,
	LightningIcon,
	LightningWhiteIcon,
	ElrondLogo,
} from "components/Icons/Icons";
import { BsArrowRight } from "react-icons/bs";
import {
	FaCube,
	FaFacebook,
	FaInstagram,
	FaPencilAlt,
	FaPenFancy,
	FaTwitter,
} from "react-icons/fa";

function Sticket() {
	const {
		isOpen: isOpenTransactionSign,
		onOpen: onOpenTransactionSign,
		onClose: onCloseTransactionSign,
	} = useDisclosure();
	const [txSigned, setTxSigned] = useState(false);
	const [txCompleted, setTxCompleted] = useState(false);

	const isMintOpen = Date.now() < 1689778800000;

	const proxy = new ProxyNetworkProvider("https://gateway.multiversx.com");

	const { makeTransaction, whenCompleted } = useTransaction();
	const history = useHistory();

	const { address, authenticated, login, logout } = useAuth();

	const [nftsLeft, setNftsLeft] = useState(0);
	const [supply, setSupply] = useState(100);
	const [price, setPrice] = useState(new BigNumber(0));
	const [stakedNonces, setStakedNonces] = useState([]);

	const minimumStake = 0;

	const [inputMint, setInputMint] = useState("");

	const getUser = async () => {
		const query = new Query({
			address: new Address(contractAddress),
			func: new ContractFunction("getUserStakedSecondCollection"),
			args: [new AddressValue(new Address(address))],
		});
		proxy
			.queryContract(query)
			.then(({ returnData }) => {
				const decodedData = returnData.map((x: any) =>
					Buffer.from(x, "base64").toString("hex")
				);
				setStakedNonces(decodedData);
				console.log(decodedData);
			})
			.catch((err) => {
				console.error("Unable to call VM query", err);
			});
	};

	async function getMintData() {
		proxy
			.queryContract(
				new Query({
					address: new Address(sticketMintAddress),
					func: new ContractFunction("getTotalTokensLeft"),
					args: [],
				})
			)
			.then(({ returnData }) => {
				setNftsLeft(
					parseInt(
						Buffer.from(returnData[0], "base64").toString("hex"),
						16
					)
				);
			})
			.catch((err) => {
				console.error("Unable to call VM query", err);
			});

		proxy
			.queryContract(
				new Query({
					address: new Address(sticketMintAddress),
					func: new ContractFunction("getNftPrice"),
					args: [],
				})
			)
			.then(({ returnData }) => {
				setPrice(
					new BigNumber(
						Buffer.from(returnData[0], "base64").toString("hex"),
						16
					)
				);
			})
			.catch((err) => {
				console.error("Unable to call VM query", err);
			});
	}

	const mint = async () => {
		setTxSigned(false);
		setTxCompleted(false);

		onOpenTransactionSign();
		const txHash = await makeTransaction({
			transaction: {
				receiver: sticketMintAddress,
				data:
					"mint" +
					"@" +
					parseInt(inputMint).toString(16).padStart(2, "0"),
				gasLimit: 5_000_000 + 10_000_000 * parseInt(inputMint),
				value: price.multipliedBy(inputMint).div(10 ** 18),
			},
		});

		setTxSigned(true);

		const txResult = await whenCompleted(txHash, { interval: 2000 });

		setTxCompleted(true);

		getMintData();
	};

	React.useEffect(() => {
		if (!authenticated) {
			history.push("/auth");
			return;
		}

		getMintData();
		getUser();
	}, [authenticated, address]);

	return (
		<>
			<Flex direction="column" mt={{ sm: "150px", md: "100px" }}>
				<Box
					mb={{ sm: "24px", md: "50px", xl: "20px" }}
					borderRadius="15px"
					px="0px"
					display="flex"
					flexDirection="column"
					justifyContent="center"
				>
					{/* Header */}
					<Card
						direction={{ sm: "column", md: "row" }}
						mx="auto"
						w={{ sm: "90%", xl: "100%" }}
						justifyContent={{ sm: "center", md: "space-between" }}
						align="center"
						p="24px"
						borderRadius="20px"
					>
						<Flex
							align="center"
							direction={{ sm: "column", md: "row" }}
						>
							<Flex
								align="center"
								mb={{ sm: "10px", md: "0px" }}
								direction={{ sm: "column", md: "row" }}
								w={{ sm: "100%" }}
								textAlign={{ sm: "center", md: "start" }}
							>
								<Flex
									direction="column"
									maxWidth="100%"
									my={{ sm: "14px" }}
								>
									<Text
										fontSize={{ sm: "lg", lg: "xl" }}
										color="#fff"
										fontWeight="bold"
										ms={{ sm: "8px", md: "0px" }}
									>
										<Text>sTicket</Text>
									</Text>
									<Text
										fontSize={{ sm: "sm", md: "md" }}
										color="gray.400"
									>
										sTickets are an innovative concept
										brought to you by Tired Club, enabling
										the possibility of participating to a
										no-loss lottery and win HTM and TASCs.
										Say goodbye to limitations and embrace
										seamless participation. It will be
										possible to sell sTickets on the
										secondary market
									</Text>

									<Text
										fontSize={{ sm: "lg", lg: "xl" }}
										color="#fff"
										fontWeight="bold"
										mt="14px"
										ms={{ sm: "8px", md: "0px" }}
									>
										<Text>Price:</Text>
									</Text>
									<Text
										fontSize={{ sm: "sm", md: "md" }}
										color="gray.400"
									>
										{price.div(10 ** 18).toPrecision(3)}{" "}
										EGLD
									</Text>
									<Text
										fontSize={{ sm: "lg", lg: "xl" }}
										color="#fff"
										fontWeight="bold"
										ms={{ sm: "8px", md: "0px" }}
									>
										<Text>Supply:</Text>
									</Text>
									<Text
										fontSize={{ sm: "sm", md: "md" }}
										color="gray.400"
									>
										100 sTickets
									</Text>
									<Text
										fontSize={{ sm: "lg", lg: "xl" }}
										color="#fff"
										fontWeight="bold"
										ms={{ sm: "8px", md: "0px" }}
									>
										<Text>Rules:</Text>
									</Text>
									<Text
										fontSize={{ sm: "sm", md: "md" }}
										color="gray.400"
									>
										On July 19th, we&apos;ll announce the
										number of winning tickets. Following the
										listing of HTM on the first exchange,
										there will be a drawing among the
										sTicket holders.
										<br />
										If your sTicket is a winner, you&apos;ll
										receive 80 HTM tokens. If your sTicket
										doesn&apos;t win, you&apos;ll get a
										refund of 1 EGLD + 1 TASC. A win-win
										situation!
										<br />
										No KYC is required
									</Text>
								</Flex>
							</Flex>
						</Flex>
					</Card>
				</Box>
				<Flex
					direction={{ sm: "column", md: "column", lg: "row" }}
					w="100%"
					justifyContent={{
						sm: "space-between",
						md: "space-between",
						lg: "center",
					}}
				>
					{/* Staking Rewards */}
					<Box flex="1">
						<Card p="16px" h="300px">
							<CardHeader p="12px 5px" mb="12px">
								<Flex direction="column">
									<Text
										fontSize="xl"
										color="#fff"
										fontWeight="bold"
										mb="6px"
									>
										Mint
									</Text>
									<Text fontSize="sm" color="gray.400">
										{nftsLeft} sTickets remaining to be sold
										out of {supply}
									</Text>
									<Text fontSize="sm" color="gray.400">
										{price.div(10 ** 18).toPrecision(3)}{" "}
										EGLD per NFT
									</Text>
								</Flex>
							</CardHeader>
							<CardBody w="100%">
								<Flex
									w="100%"
									direction={{ sm: "column", md: "row" }}
								>
									<Grid
										templateColumns={{
											sm: "2fr",
											md: "repeat(1, 2fr)",
										}}
										gap="24px"
										w="100%"
										alignSelf="flex-start"
									>
										{isMintOpen ? (
											<>
												<InputGroup size="lg">
													<Input
														placeholder="Amount"
														type="number"
														color="white"
														value={inputMint}
														onChange={(e) =>
															setInputMint(
																e.target.value
															)
														}
													/>
													<InputRightAddon backgroundColor="gray.300">
														NFTs
													</InputRightAddon>
												</InputGroup>
												<Button
													variant="brand"
													alignSelf="flex-end"
													mt="24px"
													h="35px"
													onClick={mint}
													disabled={
														inputMint === "" ||
														parseInt(inputMint) >
															"50" ||
														stakedNonces.length <
															minimumStake
													}
												>
													<Text
														fontSize="xs"
														color="#fff"
														fontWeight="bold"
													>
														{inputMint === ""
															? "Select amount"
															: parseInt(
																	inputMint
															  ) > "50"
															? "Maximum 50 NFTs per transaction"
															: stakedNonces.length <
															  minimumStake
															? `You need to stake at least ${minimumStake} TASC to mint`
															: `Mint ${inputMint} NFTs for ${price
																	.multipliedBy(
																		inputMint
																	)
																	.div(
																		10 ** 18
																	)} EGLD`}
													</Text>
												</Button>
											</>
										) : (
											<Text
												fontSize="lg"
												color="gray.400"
											>
												Mint is closed
											</Text>
										)}
									</Grid>
								</Flex>
							</CardBody>
						</Card>
					</Box>
				</Flex>
			</Flex>
			<Modal
				isOpen={isOpenTransactionSign}
				onClose={onCloseTransactionSign}
			>
				<ModalOverlay />
				<ModalContent mx={{ sm: 5, lg: 0 }}>
					<ModalBody>
						<Text>
							{!txSigned
								? "Check your MultiversX Wallet to sign the transaction"
								: null}
							{txSigned && !txCompleted
								? "Transaction is being processed, please wait..."
								: null}
							{txCompleted ? "Transaction completed!" : null}
						</Text>
					</ModalBody>
					<ModalFooter>
						<Button
							variant="outline"
							mr={3}
							onClick={onCloseTransactionSign}
						>
							{txCompleted ? "Close" : "Cancel"}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}

export default Sticket;
