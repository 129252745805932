"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MultiESDTNFTTransferPayloadBuilder = exports.ESDTNFTTransferPayloadBuilder = exports.ESDTTransferPayloadBuilder = void 0;
const address_1 = require("./address");
const argSerializer_1 = require("./smartcontracts/argSerializer");
const typesystem_1 = require("./smartcontracts/typesystem");
const tokenPayment_1 = require("./tokenPayment");
const transactionPayload_1 = require("./transactionPayload");
class ESDTTransferPayloadBuilder {
    constructor() {
        this.payment = tokenPayment_1.TokenPayment.fungibleFromAmount("", "0", 0);
    }
    setPayment(payment) {
        this.payment = payment;
        return this;
    }
    build() {
        let args = [
            // The token identifier
            typesystem_1.BytesValue.fromUTF8(this.payment.tokenIdentifier),
            // The transfered amount
            new typesystem_1.BigUIntValue(this.payment.valueOf()),
        ];
        let { argumentsString } = new argSerializer_1.ArgSerializer().valuesToString(args);
        let data = `ESDTTransfer@${argumentsString}`;
        return new transactionPayload_1.TransactionPayload(data);
    }
}
exports.ESDTTransferPayloadBuilder = ESDTTransferPayloadBuilder;
class ESDTNFTTransferPayloadBuilder {
    constructor() {
        this.payment = tokenPayment_1.TokenPayment.nonFungible("", 0);
        this.destination = new address_1.Address("");
    }
    setPayment(payment) {
        this.payment = payment;
        return this;
    }
    setDestination(destination) {
        this.destination = destination;
        return this;
    }
    build() {
        let args = [
            // The token identifier
            typesystem_1.BytesValue.fromUTF8(this.payment.tokenIdentifier),
            // The nonce of the token
            new typesystem_1.U64Value(this.payment.nonce),
            // The transferred quantity
            new typesystem_1.BigUIntValue(this.payment.valueOf()),
            // The destination address
            new typesystem_1.AddressValue(this.destination)
        ];
        let { argumentsString } = new argSerializer_1.ArgSerializer().valuesToString(args);
        let data = `ESDTNFTTransfer@${argumentsString}`;
        return new transactionPayload_1.TransactionPayload(data);
    }
}
exports.ESDTNFTTransferPayloadBuilder = ESDTNFTTransferPayloadBuilder;
class MultiESDTNFTTransferPayloadBuilder {
    constructor() {
        this.payments = [];
        this.destination = new address_1.Address("");
    }
    setPayments(payments) {
        this.payments = payments;
        return this;
    }
    setDestination(destination) {
        this.destination = destination;
        return this;
    }
    build() {
        let args = [
            // The destination address
            new typesystem_1.AddressValue(this.destination),
            // Number of tokens
            new typesystem_1.U16Value(this.payments.length)
        ];
        for (const payment of this.payments) {
            args.push(...[
                // The token identifier
                typesystem_1.BytesValue.fromUTF8(payment.tokenIdentifier),
                // The nonce of the token
                new typesystem_1.U64Value(payment.nonce),
                // The transfered quantity
                new typesystem_1.BigUIntValue(payment.valueOf())
            ]);
        }
        let { argumentsString } = new argSerializer_1.ArgSerializer().valuesToString(args);
        let data = `MultiESDTNFTTransfer@${argumentsString}`;
        return new transactionPayload_1.TransactionPayload(data);
    }
}
exports.MultiESDTNFTTransferPayloadBuilder = MultiESDTNFTTransferPayloadBuilder;
