import React, { useReducer } from "react";

// Tired imports
import { useAuth, useTransaction } from "@elrond-giants/erd-react-hooks";
import { ApiNetworkProvider } from "@elrondnetwork/erdjs-network-providers";
import { ProxyNetworkProvider } from "@elrondnetwork/erdjs-network-providers";
import {
	Address,
	Query,
	AddressValue,
	ContractFunction,
} from "@elrondnetwork/erdjs";
import { useState } from "react";
import { contractAddress } from "./../config";
import { BigNumber } from "bignumber.js";
import { useHistory } from "react-router-dom";

// Chakra imports
import {
	Avatar,
	AvatarBadge,
	AvatarGroup,
	Box,
	Button,
	CircularProgress,
	CircularProgressLabel,
	DarkMode,
	Flex,
	Grid,
	Icon,
	Image,
	Link,
	Switch,
	Text,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Spinner,
	AlertTitle,
	AlertDescription,
	CloseButton,
	Alert,
} from "@chakra-ui/react";
import avatar11 from "assets/img/avatars/avatar11.png";
// Assets
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar3 from "assets/img/avatars/avatar3.png";
import avatar4 from "assets/img/avatars/avatar4.png";
import avatar6 from "assets/img/avatars/avatar6.png";
import bgProfile from "assets/img/bgProfile.png";
import ProjectImage1 from "assets/img/ProjectImage1.png";
import ProjectImage2 from "assets/img/ProjectImage2.png";
import ProjectImage3 from "assets/img/ProjectImage3.png";
import xLogo from "../assets/img/x-black.png";
// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import LineChart from "components/Charts/LineChart";
import IconBox from "components/Icons/IconBox";
import { HSeparator, VSeparator } from "components/Separator/Separator";

// Icons
import {
	CarIcon,
	LightningIcon,
	LightningWhiteIcon,
	ElrondLogo,
	MultiversXLogo,
} from "components/Icons/Icons";
import { BsArrowRight } from "react-icons/bs";
import {
	FaCube,
	FaFacebook,
	FaInstagram,
	FaPencilAlt,
	FaPenFancy,
	FaTwitter,
} from "react-icons/fa";
import { IoDocumentsSharp } from "react-icons/io5";

// This reducer changes the active button based on the current state

const reducer = (state, action) => {
	if (action.type === "SWITCH_ACTIVE") {
		if (action.payload === "overview") {
			const newState = {
				overview: true,
				teams: false,
				projects: false,
			};
			return newState;
		} else if (action.payload === "teams") {
			const newState = {
				overview: false,
				teams: true,
				projects: false,
			};
			return newState;
		} else if (action.payload === "projects") {
			const newState = {
				overview: false,
				teams: false,
				projects: true,
			};
			return newState;
		}
	}
	return state;
};

function Profile() {
	// Chakra color mode
	const [state, dispatch] = useReducer(reducer, {
		overview: true,
		teams: false,
		projects: false,
	});

	const {
		isOpen: isOpenTransactionSign,
		onOpen: onOpenTransactionSign,
		onClose: onCloseTransactionSign,
	} = useDisclosure();
	const [txSigned, setTxSigned] = useState(false);
	const [txCompleted, setTxCompleted] = useState(false);

	const proxy = new ProxyNetworkProvider("https://gateway.multiversx.com");

	const { makeTransaction, whenCompleted } = useTransaction();
	const history = useHistory();

	const { address, authenticated, login, logout } = useAuth();

	const [account, setAccount] = useState(null);
	const [stakedTacc, setStakedTacc] = useState(0);
	const [stakedTasc, setStakedTasc] = useState(0);
	const [rewards, setRewards] = useState(new BigNumber(0));
	const [votingPower, setVotingPower] = useState(0);

	const getUser = async () => {
		const provider = new ApiNetworkProvider("https://api.multiversx.com");
		const account = await provider.getAccount(new Address(address));
		setAccount(account);
	};

	const claimRewards = async () => {
		setTxSigned(false);
		setTxCompleted(false);

		onOpenTransactionSign();
		const txHash = await makeTransaction({
			transaction: {
				receiver: contractAddress,
				data: "claimRewards",
				gasLimit: 5_000_000,
				value: 0,
			},
		});

		setTxSigned(true);

		const txResult = await whenCompleted(txHash, { interval: 2000 });

		setTxCompleted(true);

		if (txResult.status.isExecuted()) {
			//console.log("Hooray, the transaction was successful!");
		}

		getRewards();
	};

	const compoundRewards = async () => {
		setTxSigned(false);
		setTxCompleted(false);

		onOpenTransactionSign();
		const txHash = await makeTransaction({
			transaction: {
				receiver: contractAddress,
				data: "compoundRewards",
				gasLimit: 8_000_000,
				value: 0,
			},
		});

		setTxSigned(true);

		const txResult = await whenCompleted(txHash, { interval: 2000 });

		setTxCompleted(true);

		if (txResult.status.isExecuted()) {
			//console.log("Hooray, the transaction was successful!");
		}

		getRewards();
	};

	function base64ToHexFunc(str: string): string {
		const encodedData = atob(str);
		let result = "";
		for (let i = 0; i < encodedData.length; i++) {
			const hex = encodedData.charCodeAt(i).toString(16);
			result += hex.length === 2 ? hex : "0" + hex;
		}
		return result.toUpperCase();
	}

	async function getRewards() {
		//Get rewards
		const query = new Query({
			address: new Address(contractAddress),
			func: new ContractFunction("userRewards"),
			args: [new AddressValue(new Address(address))],
		});
		proxy
			.queryContract(query)
			.then(({ returnData }) => {
				const decodedData = returnData.map((x: any) => x);
				const n = parseInt(base64ToHexFunc(decodedData[0]), 16);
				setRewards(isNaN(n) ? BigNumber(0) : BigNumber(n));
			})
			.catch((err) => {
				console.error("Unable to call VM query", err);
			});
	}

	async function getVotingPower() {
		//Get voting power
		const query = new Query({
			address: new Address(contractAddress),
			func: new ContractFunction("getUserActualVotingPower"),
			args: [new AddressValue(new Address(address))],
		});
		proxy
			.queryContract(query)
			.then(({ returnData }) => {
				const decodedData = returnData.map((x: any) => x);
				const n = parseInt(base64ToHexFunc(decodedData[0]), 16);
				setVotingPower(isNaN(n) ? 0 : n);
			})
			.catch((err) => {
				console.error("Unable to call VM query", err);
			});
	}

	React.useEffect(() => {
		if (!authenticated) {
			history.push("/auth");
			return;
		}

		getRewards();
		getVotingPower();

		//Get staked TACCs
		let query = new Query({
			address: new Address(contractAddress),
			func: new ContractFunction("getUserStakedFirstCollection"),
			args: [new AddressValue(new Address(address))],
		});
		proxy
			.queryContract(query)
			.then(({ returnData }) => {
				const decodedData = returnData.map((x: any) =>
					Buffer.from(x, "base64").toString("hex")
				);
				setStakedTacc(decodedData);
			})
			.catch((err) => {
				console.error("Unable to call VM query", err);
			});

		//Get staked TASCs
		query = new Query({
			address: new Address(contractAddress),
			func: new ContractFunction("getUserStakedSecondCollection"),
			args: [new AddressValue(new Address(address))],
		});
		proxy
			.queryContract(query)
			.then(({ returnData }) => {
				const decodedData = returnData.map((x: any) =>
					Buffer.from(x, "base64").toString("hex")
				);
				setStakedTasc(decodedData);
			})
			.catch((err) => {
				console.error("Unable to call VM query", err);
			});

		getUser();
	}, [authenticated, address]);

	return (
		<>
			<Flex direction="column" mt={{ sm: "150px", md: "100px" }}>
				<Box
					mb={{ sm: "24px", md: "50px", xl: "20px" }}
					borderRadius="15px"
					px="0px"
					display="flex"
					flexDirection="column"
					justifyContent="center"
				>
					{/* Header */}
					<Card
						direction={{ sm: "column", md: "row" }}
						mx="auto"
						maxH="330px"
						w={{ sm: "90%", xl: "100%" }}
						justifyContent={{ sm: "center", md: "space-between" }}
						align="center"
						p="24px"
						borderRadius="20px"
					>
						<Flex
							align="center"
							direction={{ sm: "column", md: "row" }}
						>
							<Flex
								align="center"
								mb={{ sm: "10px", md: "0px" }}
								direction={{ sm: "column", md: "row" }}
								w={{ sm: "100%" }}
								textAlign={{ sm: "center", md: "start" }}
							>
								<Flex
									direction="column"
									maxWidth="100%"
									my={{ sm: "14px" }}
								>
									<Text
										fontSize={{ sm: "lg", lg: "xl" }}
										color="#fff"
										fontWeight="bold"
										ms={{ sm: "8px", md: "0px" }}
									>
										{account?.userName ? (
											<Text>
												Hey there,{" "}
												{account.userName.replace(
													".elrond",
													""
												)}
												!
											</Text>
										) : (
											<Text>Welcome back!</Text>
										)}
									</Text>
									<Text
										fontSize={{ sm: "sm", md: "md" }}
										color="gray.400"
									>
										{address}
									</Text>
								</Flex>
							</Flex>
						</Flex>
					</Card>
				</Box>
				<Flex
					direction={{ sm: "column", md: "column", lg: "row" }}
					w="100%"
					justifyContent={{
						sm: "space-between",
						md: "space-between",
						lg: "center",
					}}
				>
					{/* Staking Rewards */}
					<Box flex="1">
						<Card p="16px" h="340px">
							<CardHeader p="12px 5px" mb="12px">
								<Flex direction="column">
									<Text
										fontSize="xl"
										color="#fff"
										fontWeight="bold"
										mb="6px"
									>
										Staking Rewards
									</Text>
									<Text fontSize="sm" color="gray.400">
										Rewards for staking your Tired Apes.
									</Text>
								</Flex>
							</CardHeader>
							<CardBody w="100%">
								<Flex
									w="100%"
									direction={{ sm: "column", md: "row" }}
								>
									<Grid
										templateColumns={{
											sm: "2fr",
											md: "repeat(1, 2fr)",
										}}
										gap="24px"
										w="100%"
										alignSelf="flex-start"
									>
										<Flex
											align="center"
											p="18px"
											justify="space-between"
											bg="linear-gradient(126.97deg, rgba(6, 11, 38, 0.74) 28.26%, rgba(26, 31, 55, 0.5) 91.2%)"
											borderRadius="20px"
										>
											<Flex direction="column" me="auto">
												<Text
													fontSize="xs"
													color="gray.400"
													mb="3px"
												>
													Cumulated Rewards
												</Text>
												<Text
													color="#fff"
													fontSize="22px"
													fontWeight="bold"
												>
													{rewards
														.div(10 ** 18)
														.toPrecision(3)}{" "}
													EGLD
												</Text>
											</Flex>
											<IconBox
												bg="brand.200"
												w="56px"
												h="56px"
												direction="column"
												p="10px"
											>
												<Image src={xLogo} />
											</IconBox>
										</Flex>

										<Flex w="100%" direction="row">
											<Button
												variant="brand"
												alignSelf="flex-end"
												mt="8px"
												w="100px"
												h="35px"
												disabled={rewards.isZero()}
												onClick={claimRewards}
											>
												<Text
													fontSize="xs"
													color="#fff"
													fontWeight="bold"
												>
													Claim Rewards
												</Text>
											</Button>
											{/*
											<Button
												variant="brand"
												alignSelf="flex-end"
												mt="8px"
												ml="10px"
												w="130px"
												h="35px"
												disabled={rewards.isZero()}
												onClick={compoundRewards}
											>
												<Text fontSize="xs" color="#fff" fontWeight="bold">
													Compound Rewards
												</Text>
											</Button>
                                            */}
										</Flex>
									</Grid>
								</Flex>
							</CardBody>
						</Card>
					</Box>
					{/* Staking Rewards */}
					<Box
						flex="1"
						ml={{ md: "0px", lg: "20px" }}
						mt={{ sm: "20px", md: "20px", lg: "0px" }}
					>
						<Card p="16px" h="340px">
							<CardHeader p="12px 5px" mb="12px">
								<Flex direction="column">
									<Text
										fontSize="xl"
										color="#fff"
										fontWeight="bold"
										mb="6px"
									>
										Voting Power
									</Text>
									<Text fontSize="sm" color="gray.400">
										Your live DAO voting power.
									</Text>
								</Flex>
							</CardHeader>
							<CardBody w="100%">
								<Flex
									w="100%"
									direction={{ sm: "column", md: "row" }}
								>
									<Grid
										templateColumns={{
											sm: "2fr",
											md: "repeat(1, 2fr)",
										}}
										gap="24px"
										w="100%"
										alignSelf="flex-start"
									>
										<Flex
											align="center"
											p="18px"
											justify="space-between"
											bg="linear-gradient(126.97deg, rgba(6, 11, 38, 0.74) 28.26%, rgba(26, 31, 55, 0.5) 91.2%)"
											borderRadius="20px"
										>
											<Flex direction="column" me="auto">
												<Text
													fontSize="xs"
													color="gray.400"
													mb="3px"
												>
													Voting Power
												</Text>
												<Text
													color="#fff"
													fontSize="22px"
													fontWeight="bold"
												>
													{votingPower} VOTES
												</Text>
											</Flex>
										</Flex>
									</Grid>
								</Flex>
							</CardBody>
						</Card>
					</Box>
					<Box
						flex="2"
						ml={{ md: "0px", lg: "20px" }}
						mt={{ sm: "20px", md: "20px", lg: "0px" }}
					>
						<Card p="16px" h="340px">
							<CardHeader p="12px 5px" mb="12px">
								<Text
									fontSize="lg"
									color="#fff"
									fontWeight="bold"
								>
									My Staked Apes
								</Text>
							</CardHeader>
							<CardBody px="5px">
								<Flex
									direction="row"
									w="100%"
									align="center"
									justify="space-evenly"
									alignContent="center"
								>
									<Flex direction="column" align="center">
										<Text
											fontSize="xl"
											color={"white"}
											fontWeight="400"
											mb="15px"
										>
											{stakedTacc.length}
										</Text>
										<Text
											fontSize="sm"
											color={"gray.400"}
											fontWeight="400"
											mb="15px"
											align="center"
										>
											Tired Ape Country Club
										</Text>
									</Flex>
									<VSeparator h="56px" my="30px" />
									<Flex direction="column" align="center">
										<Text
											fontSize="xl"
											color={"white"}
											fontWeight="400"
											mb="15px"
										>
											{stakedTasc.length}
										</Text>
										<Text
											fontSize="sm"
											color={"gray.400"}
											fontWeight="400"
											mb="15px"
											align="center"
										>
											Tired Ape Sporting Club
										</Text>
									</Flex>
								</Flex>
							</CardBody>
						</Card>
					</Box>
				</Flex>
			</Flex>
			<Modal
				isOpen={isOpenTransactionSign}
				onClose={onCloseTransactionSign}
			>
				<ModalOverlay />
				<ModalContent mx={{ sm: 5, lg: 0 }}>
					<ModalBody>
						<Text>
							{!txSigned
								? "Check your MultiversX Wallet to sign the transaction"
								: null}
							{txSigned && !txCompleted
								? "Transaction is being processed, please wait..."
								: null}
							{txCompleted ? "Transaction completed!" : null}
						</Text>
					</ModalBody>
					<ModalFooter>
						<Button
							variant="outline"
							mr={3}
							onClick={onCloseTransactionSign}
						>
							{txCompleted ? "Close" : "Cancel"}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
			{/*isOpenTransactionSign ?
			
			<Alert 
				status={!txSigned ? 'info' : txCompleted ? 'success' : 'warning'}
				variant='top-accent'>
				 <Spinner
					thickness='4px'
					speed='0.65s'
					emptyColor='gray.200'
					color='blue.500'
					size='sm'
					/>
				<Box>
					<AlertTitle>Processing transaction</AlertTitle>
					<AlertDescription>
					Your application has been received. We will review your application
					and respond within the next 48 hours.
					</AlertDescription>
				</Box>
				<CloseButton
					alignSelf='flex-start'
					position='relative'
					right={-1}
					top={-1}
					onClick={onCloseTransactionSign}
				/>
    		</Alert>
							:null*/}
		</>
	);
}

export default Profile;
