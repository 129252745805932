"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GasEstimator = exports.DefaultGasConfiguration = void 0;
/**
 * This is mirroring (on a best efforts basis) the network's gas configuration & gas schedule:
 *  - https://gateway.elrond.com/network/config
 *  - https://github.com/ElrondNetwork/elrond-config-mainnet/tree/master/gasSchedules
 *  - https://github.com/ElrondNetwork/elrond-config-mainnet/blob/master/enableEpochs.toml#L200
 */
exports.DefaultGasConfiguration = {
    minGasLimit: 50000,
    gasPerDataByte: 1500,
    gasCostESDTTransfer: 200000,
    gasCostESDTNFTTransfer: 200000,
    gasCostESDTNFTMultiTransfer: 200000
};
// Additional gas to account for eventual increases in gas requirements (thus avoid fast-breaking changes in clients of erdjs).
const ADDITIONAL_GAS_FOR_ESDT_TRANSFER = 100000;
// Additional gas to account for extra blockchain operations (e.g. data movement (between accounts) for NFTs), 
// and for eventual increases in gas requirements (thus avoid fast-breaking changes in clients of erdjs).
const ADDITIONAL_GAS_FOR_ESDT_NFT_TRANSFER = 800000;
class GasEstimator {
    constructor(gasConfiguration) {
        this.gasConfiguration = gasConfiguration || exports.DefaultGasConfiguration;
    }
    forEGLDTransfer(dataLength) {
        const gasLimit = this.gasConfiguration.minGasLimit +
            this.gasConfiguration.gasPerDataByte * dataLength;
        return gasLimit;
    }
    forESDTTransfer(dataLength) {
        const gasLimit = this.gasConfiguration.minGasLimit +
            this.gasConfiguration.gasCostESDTTransfer +
            this.gasConfiguration.gasPerDataByte * dataLength +
            ADDITIONAL_GAS_FOR_ESDT_TRANSFER;
        return gasLimit;
    }
    forESDTNFTTransfer(dataLength) {
        const gasLimit = this.gasConfiguration.minGasLimit +
            this.gasConfiguration.gasCostESDTNFTTransfer +
            this.gasConfiguration.gasPerDataByte * dataLength +
            ADDITIONAL_GAS_FOR_ESDT_NFT_TRANSFER;
        return gasLimit;
    }
    forMultiESDTNFTTransfer(dataLength, numTransfers) {
        const gasLimit = this.gasConfiguration.minGasLimit +
            (this.gasConfiguration.gasCostESDTNFTMultiTransfer + ADDITIONAL_GAS_FOR_ESDT_NFT_TRANSFER) * numTransfers +
            this.gasConfiguration.gasPerDataByte * dataLength;
        return gasLimit;
    }
}
exports.GasEstimator = GasEstimator;
