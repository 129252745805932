"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContractQueryRequest = void 0;
class ContractQueryRequest {
    constructor(query) {
        this.query = query;
    }
    toHttpRequest() {
        var _a;
        let request = {};
        let query = this.query;
        request.scAddress = query.address.bech32();
        request.caller = ((_a = query.caller) === null || _a === void 0 ? void 0 : _a.bech32()) ? query.caller.bech32() : undefined;
        request.funcName = query.func.toString();
        request.value = query.value ? query.value.toString() : undefined;
        request.args = query.getEncodedArguments();
        return request;
    }
}
exports.ContractQueryRequest = ContractQueryRequest;
