import React, { useReducer } from "react";

// Tired imports
import { useAuth, useTransaction } from "@elrond-giants/erd-react-hooks";
import { ApiNetworkProvider } from "@elrondnetwork/erdjs-network-providers";
import { ProxyNetworkProvider } from "@elrondnetwork/erdjs-network-providers";
import {
	Address,
	Query,
	AddressValue,
	ContractFunction,
} from "@elrondnetwork/erdjs";
import { useState } from "react";
import {
	contractAddress,
	firstCollectionIdentifier,
	secondCollectionIdentifier,
} from "./../config";
import { BigNumber } from "bignumber.js";
import { useHistory } from "react-router-dom";

// Chakra imports
import {
	Avatar,
	AvatarBadge,
	AvatarGroup,
	Box,
	Button,
	CircularProgress,
	CircularProgressLabel,
	DarkMode,
	Flex,
	Grid,
	Icon,
	Image,
	Link,
	Switch,
	Text,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Spinner,
	AlertTitle,
	AlertDescription,
	CloseButton,
	Alert,
} from "@chakra-ui/react";
import avatar11 from "assets/img/avatars/avatar11.png";
// Assets
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar3 from "assets/img/avatars/avatar3.png";
import avatar4 from "assets/img/avatars/avatar4.png";
import avatar6 from "assets/img/avatars/avatar6.png";
import bgProfile from "assets/img/bgProfile.png";
import ProjectImage1 from "assets/img/ProjectImage1.png";
import ProjectImage2 from "assets/img/ProjectImage2.png";
import ProjectImage3 from "assets/img/ProjectImage3.png";
// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import LineChart from "components/Charts/LineChart";
import IconBox from "components/Icons/IconBox";
import { HSeparator, VSeparator } from "components/Separator/Separator";

import { MyApiNetworkProvider } from "./../MyApiNetworkProvider/index.js";

// Icons
import {
	CarIcon,
	LightningIcon,
	LightningWhiteIcon,
	ElrondLogo,
} from "components/Icons/Icons";
import { BsArrowRight } from "react-icons/bs";
import {
	FaCube,
	FaFacebook,
	FaInstagram,
	FaPencilAlt,
	FaPenFancy,
	FaTwitter,
} from "react-icons/fa";
import { IoDocumentsSharp } from "react-icons/io5";

function Statistics() {
	const provider = new MyApiNetworkProvider("https://api.multiversx.com");
	const proxy = new ProxyNetworkProvider("https://gateway.multiversx.com");

	const { address, authenticated, login, logout } = useAuth();

	const [account, setAccount] = useState(null);

	const [stakedTacc, setStakedTacc] = useState(0);
	const [stakedTasc, setStakedTasc] = useState(0);

	const [unstakedTacc, setUnstakedTacc] = useState(0);
	const [unstakedTasc, setUnstakedTasc] = useState(0);

	const [supplyTacc, setSupplyTacc] = useState(777);
	const [supplyTasc, setSupplyTasc] = useState(0);

	const [nUsersStakedTacc, setNUsersStakedTacc] = useState(0);
	const [nUsersStakedTasc, setNUsersStakedTasc] = useState(0);

	const [detailsTasc, setDetailsTasc] = useState({});
	const [detailsTascTraits, setDetailsTascTraits] = useState({});

	const getUser = async () => {
		const account = await provider.getAccount(new Address(address));
		setAccount(account);
	};

	function base64ToHexFunc(str: string): string {
		const encodedData = atob(str);
		let result = "";
		for (let i = 0; i < encodedData.length; i++) {
			const hex = encodedData.charCodeAt(i).toString(16);
			result += hex.length === 2 ? hex : "0" + hex;
		}
		return result.toUpperCase();
	}

	async function getTascSupply() {
		const supply = await provider.getNftSupplyFromCollection(
			secondCollectionIdentifier
		);
		setSupplyTasc(supply);
	}

	async function getTascDetails() {
		const collection = await provider.getNftCollection(
			secondCollectionIdentifier
		);
		setDetailsTasc(collection);
		setDetailsTascTraits(JSON.parse(collection.traits));
	}

	React.useEffect(() => {
		//Get staked TACCs
		let query = new Query({
			address: new Address(contractAddress),
			func: new ContractFunction("getNumberStakedFirstCollection"),
			args: [],
		});
		proxy
			.queryContract(query)
			.then(({ returnData }) => {
				const decodedData = returnData.map((x: any) => x);
				const n = parseInt(base64ToHexFunc(decodedData[0]), 16);
				setStakedTacc(isNaN(n) ? 0 : n);
			})
			.catch((err) => {
				console.error("Unable to call VM query", err);
			});

		//Get staked TASCs
		query = new Query({
			address: new Address(contractAddress),
			func: new ContractFunction("getNumberStakedSecondCollection"),
			args: [],
		});
		proxy
			.queryContract(query)
			.then(({ returnData }) => {
				const decodedData = returnData.map((x: any) => x);
				const n = parseInt(base64ToHexFunc(decodedData[0]), 16);
				setStakedTasc(isNaN(n) ? 0 : n);
			})
			.catch((err) => {
				console.error("Unable to call VM query", err);
			});

		//Get unstaked TACCs
		query = new Query({
			address: new Address(contractAddress),
			func: new ContractFunction("getNumberUnstakedFirstCollection"),
			args: [],
		});
		proxy
			.queryContract(query)
			.then(({ returnData }) => {
				const decodedData = returnData.map((x: any) => x);
				const n = parseInt(base64ToHexFunc(decodedData[0]), 16);
				setUnstakedTacc(isNaN(n) ? 0 : n);
			})
			.catch((err) => {
				console.error("Unable to call VM query", err);
			});

		//Get unstaked TASCs
		query = new Query({
			address: new Address(contractAddress),
			func: new ContractFunction("getNumberUnstakedSecondCollection"),
			args: [],
		});
		proxy
			.queryContract(query)
			.then(({ returnData }) => {
				const decodedData = returnData.map((x: any) => x);
				const n = parseInt(base64ToHexFunc(decodedData[0]), 16);
				setUnstakedTasc(isNaN(n) ? 0 : n);
			})
			.catch((err) => {
				console.error("Unable to call VM query", err);
			});

		//Get number of users staked TACCs
		query = new Query({
			address: new Address(contractAddress),
			func: new ContractFunction("getUsersStakedFirstCollection"),
			args: [],
		});
		proxy
			.queryContract(query)
			.then(({ returnData }) => {
				const decodedData = returnData.map((x: any) => x);
				setNUsersStakedTacc(decodedData.length);
			})
			.catch((err) => {
				console.error("Unable to call VM query", err);
			});

		//Get number of users staked TASCs
		query = new Query({
			address: new Address(contractAddress),
			func: new ContractFunction("getUsersStakedSecondCollection"),
			args: [],
		});
		proxy
			.queryContract(query)
			.then(({ returnData }) => {
				const decodedData = returnData.map((x: any) => x);
				setNUsersStakedTasc(decodedData.length);
			})
			.catch((err) => {
				console.error("Unable to call VM query", err);
			});

		//Get NFTs supply
		getTascSupply();
		//Get TASCs details
		getTascDetails();
	}, [authenticated, address]);

	return (
		<>
			<Flex direction="column" mt={{ sm: "150px", md: "100px" }}>
				<Box
					mb={{ sm: "24px", md: "50px", xl: "20px" }}
					borderRadius="15px"
					px="0px"
					display="flex"
					flexDirection="column"
					justifyContent="center"
				>
					{/* Header */}
					<Card
						direction={{ sm: "column", md: "row" }}
						mx="auto"
						maxH="330px"
						w={{ sm: "90%", xl: "100%" }}
						justifyContent={{ sm: "center", md: "space-between" }}
						align="center"
						p="24px"
						borderRadius="20px"
					>
						<Flex align="center" direction={{ sm: "column", md: "row" }}>
							<Flex
								align="center"
								mb={{ sm: "10px", md: "0px" }}
								direction={{ sm: "column", md: "row" }}
								w={{ sm: "100%" }}
								textAlign={{ sm: "center", md: "start" }}
							>
								<Flex direction="column" maxWidth="100%" my={{ sm: "14px" }}>
									<Text
										fontSize={{ sm: "lg", lg: "xl" }}
										color="#fff"
										fontWeight="bold"
										ms={{ sm: "8px", md: "0px" }}
									>
										Tired Club Statistics
									</Text>
								</Flex>
							</Flex>
						</Flex>
					</Card>
				</Box>
				{/* ******************************************************************************** */}
				<Flex
					direction={{ sm: "column", md: "column", lg: "row" }}
					mb={{ sm: "24px", md: "50px", xl: "20px" }}
					w="100%"
					justifyContent={{
						sm: "space-between",
						md: "space-between",
						lg: "center",
					}}
				>
					{/* Staked Tired Ape Country Club */}
					<Box flex="1">
						<Card p="16px">
							<CardHeader p="12px 5px" mb="12px">
								<Text fontSize="xl" color="#fff" fontWeight="bold" mb="6px">
									Tired Ape Country Club
								</Text>
							</CardHeader>
							<CardBody w="100%">
								<Flex w="100%" direction={{ sm: "column", md: "row" }}>
									<Grid
										templateColumns={{ sm: "2fr", md: "repeat(1, 2fr)" }}
										gap="24px"
										w="100%"
										alignSelf="flex-start"
									>
										<Flex
											align="center"
											p="18px"
											justify="space-between"
											borderRadius="20px"
										>
											<Flex direction="column" me="auto">
												<Text color="#fff" fontSize="24px" fontWeight="bold">
													{stakedTacc} NFTs staked
												</Text>
												<Text fontSize="s" color="gray.400" mb="3px">
													Out of {supplyTacc} minted
												</Text>
												<Text fontSize="xs" color="gray.400" mb="3px">
													{unstakedTacc} NFTs Unstaked
												</Text>
												<Text color="#fff" fontSize="24px" fontWeight="bold">
													{nUsersStakedTacc} Holders
												</Text>
											</Flex>
											<IconBox
												bg="brand.200"
												w="85px"
												h="85px"
												direction="column"
												p="10px"
											>
												<CircularProgress
													value={(stakedTacc / supplyTacc) * 100}
													color="green.400"
													trackColor="brand.600"
													size="75px"
												>
													<CircularProgressLabel color="white">
														{Math.round((stakedTacc / supplyTacc) * 100)}%
													</CircularProgressLabel>
												</CircularProgress>
											</IconBox>
										</Flex>
									</Grid>
								</Flex>
							</CardBody>
						</Card>
					</Box>
					{/* Staked Tired Ape Sporting Club */}
					<Box
						flex="1"
						ml={{ md: "0px", lg: "20px" }}
						mt={{ sm: "20px", md: "20px", lg: "0px" }}
					>
						<Card p="16px">
							<CardHeader p="12px 5px" mb="12px">
								<Text fontSize="xl" color="#fff" fontWeight="bold" mb="6px">
									Tired Ape Sporting Club
								</Text>
							</CardHeader>
							<CardBody w="100%">
								<Flex w="100%" direction={{ sm: "column", md: "row" }}>
									<Grid
										templateColumns={{ sm: "2fr", md: "repeat(1, 2fr)" }}
										gap="24px"
										w="100%"
										alignSelf="flex-start"
									>
										<Flex
											align="center"
											p="18px"
											justify="space-between"
											borderRadius="20px"
										>
											<Flex direction="column" me="auto">
												<Text color="#fff" fontSize="24px" fontWeight="bold">
													{stakedTasc} NFTs staked
												</Text>
												<Text fontSize="s" color="gray.400" mb="3px">
													Out of {supplyTasc} minted
												</Text>
												<Text fontSize="xs" color="gray.400" mb="3px">
													{unstakedTasc} NFTs Unstaked
												</Text>
												<Text color="#fff" fontSize="24px" fontWeight="bold">
													{nUsersStakedTasc} Holders
												</Text>
											</Flex>
											<IconBox
												bg="brand.200"
												w="85px"
												h="85px"
												direction="column"
												p="10px"
											>
												<CircularProgress
													value={(stakedTasc / supplyTasc) * 100}
													color="green.400"
													trackColor="brand.600"
													size="75px"
												>
													<CircularProgressLabel color="white">
														{Math.round((stakedTasc / supplyTasc) * 100)}%
													</CircularProgressLabel>
												</CircularProgress>
											</IconBox>
										</Flex>
									</Grid>
								</Flex>
							</CardBody>
						</Card>
					</Box>
				</Flex>
				{/* ******************************************************************************** */}
				<Flex
					direction={{ sm: "column", md: "column", lg: "row" }}
					w="100%"
					justifyContent={{
						sm: "space-between",
						md: "space-between",
						lg: "center",
					}}
				>
					{/* Mint */}
					<Box flex="1">
						<Card p="16px">
							<CardHeader p="12px 5px" mb="12px">
								<Text fontSize="xl" color="#fff" fontWeight="bold" mb="6px">
									TASC Mint
								</Text>
							</CardHeader>
							<CardBody w="100%">
								<Flex w="100%" direction={{ sm: "column", md: "row" }}>
									<Grid
										templateColumns={{ sm: "2fr", md: "repeat(1, 2fr)" }}
										gap="24px"
										w="100%"
										alignSelf="flex-start"
									>
										<Flex
											align="center"
											p="18px"
											justify="space-between"
											borderRadius="20px"
										>
											<Flex direction="column" me="auto">
												<Text color="#fff" fontSize="24px" fontWeight="bold">
													{supplyTasc} NFTs minted
												</Text>
												<Text fontSize="xl" color="gray.400" mb="3px">
													Out of 5000 total
												</Text>
											</Flex>
											<IconBox
												bg="brand.200"
												w="85px"
												h="85px"
												direction="column"
												p="10px"
											>
												<CircularProgress
													value={(supplyTasc / 5000) * 100}
													color="green.400"
													trackColor="brand.600"
													size="75px"
												>
													<CircularProgressLabel color="white">
														{Math.round((supplyTasc / 5000) * 100)}%
													</CircularProgressLabel>
												</CircularProgress>
											</IconBox>
										</Flex>
									</Grid>
								</Flex>
							</CardBody>
						</Card>
					</Box>
					{/* Olympian */}
					<Box
						flex="1"
						ml={{ md: "0px", lg: "20px" }}
						mt={{ sm: "20px", md: "20px", lg: "0px" }}
					>
						<Card p="16px">
							<CardHeader p="12px 5px" mb="12px">
								<Text fontSize="xl" color="#fff" fontWeight="bold" mb="6px">
									Olympian
								</Text>
							</CardHeader>
							<CardBody w="100%">
								<Flex w="100%" direction={{ sm: "column", md: "row" }}>
									<Grid
										templateColumns={{ sm: "2fr", md: "repeat(1, 2fr)" }}
										gap="24px"
										w="100%"
										alignSelf="flex-start"
									>
										<Flex
											align="center"
											p="18px"
											justify="space-between"
											borderRadius="20px"
										>
											<Flex direction="column" me="auto">
												<Text color="#fff" fontSize="24px" fontWeight="bold">
													{detailsTascTraits["Earring"]?.["Ape Earring"] ?? 0}{" "}
													NFTs minted
												</Text>
												<Text fontSize="xl" color="gray.400" mb="3px">
													Out of 96 total
												</Text>
											</Flex>
											<IconBox
												bg="brand.200"
												w="85px"
												h="85px"
												direction="column"
												p="10px"
											>
												<CircularProgress
													value={
														((detailsTascTraits["Earring"]?.["Ape Earring"] ??
															0) /
															96) *
														100
													}
													color="green.400"
													trackColor="brand.600"
													size="75px"
												>
													<CircularProgressLabel color="white">
														{Math.round(
															((detailsTascTraits["Earring"]?.["Ape Earring"] ??
																0) /
																96) *
																100
														)}
														%
													</CircularProgressLabel>
												</CircularProgress>
											</IconBox>
										</Flex>
									</Grid>
								</Flex>
							</CardBody>
						</Card>
					</Box>
					{/* Leggendary */}
					<Box
						flex="1"
						ml={{ md: "0px", lg: "20px" }}
						mt={{ sm: "20px", md: "20px", lg: "0px" }}
					>
						<Card p="16px">
							<CardHeader p="12px 5px" mb="12px">
								<Text fontSize="xl" color="#fff" fontWeight="bold" mb="6px">
									Legendary
								</Text>
							</CardHeader>
							<CardBody w="100%">
								<Flex w="100%" direction={{ sm: "column", md: "row" }}>
									<Grid
										templateColumns={{ sm: "2fr", md: "repeat(1, 2fr)" }}
										gap="24px"
										w="100%"
										alignSelf="flex-start"
									>
										<Flex
											align="center"
											p="18px"
											justify="space-between"
											borderRadius="20px"
										>
											<Flex direction="column" me="auto">
												<Text color="#fff" fontSize="24px" fontWeight="bold">
													7 NFTs minted
												</Text>
												<Text fontSize="xl" color="gray.400" mb="3px">
													Out of 15 total
												</Text>
											</Flex>
											<IconBox
												bg="brand.200"
												w="85px"
												h="85px"
												direction="column"
												p="10px"
											>
												<CircularProgress
													value={(7 / 15) * 100}
													color="green.400"
													trackColor="brand.600"
													size="75px"
												>
													<CircularProgressLabel color="white">
														{Math.round((7 / 15) * 100)}%
													</CircularProgressLabel>
												</CircularProgress>
											</IconBox>
										</Flex>
									</Grid>
								</Flex>
							</CardBody>
						</Card>
					</Box>
				</Flex>
			</Flex>
		</>
	);
}

export default Statistics;
