import React from "react";

// Chakra imports
import {
	Box,
	Flex,
	Grid,
	Progress,
	SimpleGrid,
	Spacer,
	Stack,
	Stat,
	StatHelpText,
	StatLabel,
	StatNumber,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	useColorModeValue,
} from "@chakra-ui/react";


import Carousel from "../components/Carousel/Carousel";
import { column } from "stylis";

export default function Default() {
	// Chakra Color Mode
	const iconBoxInside = useColorModeValue("white", "white");
	return (
		<Flex flexDirection={{sm:"column",md:"column",lg:"row"}}
		w="100%"
		minH="85vh"
		alignItems="center"
		justifyContent="center">
			<Box w={{sm:"100%",md:"100%",lg:"50%"}}
			flexDirection="column"
			justifyContent="center"
			alignItems="center">
				<Text fontSize="4xl" align="center" color="#fff" fontWeight="bold">Welcome to the Tired Club dApp</Text>
				<Text fontSize={{sm:"lg",md:"xl",lg:"xl"}} mt={{sm:"10px"}}align="center" color="#fff" fontWeight="bold">Stake your Apes and be part of the Club</Text>
				<Text fontSize={{sm:"lg",md:"xl",lg:"xl"}} align="center" color="#fff" fontWeight="bold">Let&apos;s build the first community Staking Agency!</Text>
			</Box>
			<Box w={{sm:"100%",md:"75%",lg:"50%"}} h="100%"
			flexDirection="column"
			justifyContent="center"
			alignItems="center">
				<Carousel />
			</Box>
		</Flex>
	);
}
