"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NonFungibleTokenOfAccountOnNetwork = exports.FungibleTokenOfAccountOnNetwork = void 0;
const bignumber_js_1 = require("bignumber.js");
const primitives_1 = require("./primitives");
class FungibleTokenOfAccountOnNetwork {
    constructor() {
        this.identifier = "";
        this.balance = new bignumber_js_1.BigNumber(0);
        this.rawResponse = {};
    }
    static fromHttpResponse(payload) {
        let result = new FungibleTokenOfAccountOnNetwork();
        result.identifier = payload.tokenIdentifier || payload.identifier || "";
        result.balance = new bignumber_js_1.BigNumber(payload.balance || 0);
        result.rawResponse = payload;
        return result;
    }
}
exports.FungibleTokenOfAccountOnNetwork = FungibleTokenOfAccountOnNetwork;
class NonFungibleTokenOfAccountOnNetwork {
    constructor(init) {
        this.identifier = "";
        this.collection = "";
        this.timestamp = 0;
        this.attributes = Buffer.from([]);
        this.nonce = 0;
        this.type = "";
        this.name = "";
        this.creator = new primitives_1.Address("");
        this.supply = new bignumber_js_1.BigNumber(0);
        this.decimals = 0;
        this.royalties = new bignumber_js_1.BigNumber(0);
        this.assets = [];
        this.balance = new bignumber_js_1.BigNumber(0);
        Object.assign(this, init);
    }
    static fromProxyHttpResponse(payload) {
        let result = NonFungibleTokenOfAccountOnNetwork.fromHttpResponse(payload);
        result.identifier = payload.tokenIdentifier || "";
        result.collection = NonFungibleTokenOfAccountOnNetwork.parseCollectionFromIdentifier(result.identifier);
        result.royalties = new bignumber_js_1.BigNumber(payload.royalties || 0).div(100);
        return result;
    }
    static fromProxyHttpResponseByNonce(payload) {
        let result = NonFungibleTokenOfAccountOnNetwork.fromHttpResponse(payload);
        let nonceAsHex = new primitives_1.Nonce(result.nonce).hex();
        result.identifier = `${payload.tokenIdentifier}-${nonceAsHex}`;
        result.collection = payload.tokenIdentifier || "";
        result.royalties = new bignumber_js_1.BigNumber(payload.royalties || 0).div(100);
        return result;
    }
    static fromApiHttpResponse(payload) {
        let result = NonFungibleTokenOfAccountOnNetwork.fromHttpResponse(payload);
        result.identifier = payload.identifier || "";
        result.collection = payload.collection || "";
        return result;
    }
    // TODO: Compare results from Proxy and API and try to reconciliate them.
    static fromHttpResponse(payload) {
        let result = new NonFungibleTokenOfAccountOnNetwork();
        result.timestamp = Number(payload.timestamp || 0);
        result.attributes = Buffer.from(payload.attributes || "", "base64");
        result.nonce = payload.nonce || 0;
        result.type = payload.type || "";
        result.name = payload.name || "";
        result.creator = new primitives_1.Address(payload.creator || "");
        result.decimals = Number(payload.decimals || 0);
        result.supply = new bignumber_js_1.BigNumber(payload.balance || 1);
        result.royalties = new bignumber_js_1.BigNumber(payload.royalties || 0);
        result.assets = payload.assets || [];
        result.balance = new bignumber_js_1.BigNumber(payload.balance || 0);
        return result;
    }
    static parseCollectionFromIdentifier(identifier) {
        let parts = identifier.split("-");
        let collection = parts.slice(0, 2).join("-");
        return collection;
    }
}
exports.NonFungibleTokenOfAccountOnNetwork = NonFungibleTokenOfAccountOnNetwork;
