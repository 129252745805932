"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExtensionProvider = void 0;
const transaction_1 = require("@multiversx/sdk-core/out/transaction");
const errors_1 = require("./errors");
const operation_1 = require("./operation");
class ExtensionProvider {
    constructor() {
        this.account = { address: "" };
        this.initialized = false;
        if (ExtensionProvider._instance) {
            throw new Error("Error: Instantiation failed: Use ExtensionProvider.getInstance() instead of new.");
        }
        ExtensionProvider._instance = this;
    }
    static getInstance() {
        return ExtensionProvider._instance;
    }
    setAddress(address) {
        this.account.address = address;
        return ExtensionProvider._instance;
    }
    init() {
        return __awaiter(this, void 0, void 0, function* () {
            if (window && window.elrondWallet) {
                this.initialized = true;
            }
            return this.initialized;
        });
    }
    login(options = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.initialized) {
                throw new Error("Extension provider is not initialised, call init() first");
            }
            const { token } = options;
            const data = token ? token : "";
            yield this.startBgrMsgChannel(operation_1.Operation.Connect, data);
            return this.account.address;
        });
    }
    logout() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.initialized) {
                throw new Error("Extension provider is not initialised, call init() first");
            }
            try {
                yield this.startBgrMsgChannel(operation_1.Operation.Logout, this.account.address);
                this.disconnect();
            }
            catch (error) {
                console.warn("Extension origin url is already cleared!", error);
            }
            return true;
        });
    }
    disconnect() {
        this.account = { address: "" };
    }
    getAddress() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.initialized) {
                throw new Error("Extension provider is not initialised, call init() first");
            }
            return this.account ? this.account.address : "";
        });
    }
    isInitialized() {
        return this.initialized;
    }
    // TODO: In V3, this will not be an async function anymore.
    isConnected() {
        return __awaiter(this, void 0, void 0, function* () {
            return Boolean(this.account.address);
        });
    }
    signTransaction(transaction) {
        return __awaiter(this, void 0, void 0, function* () {
            this.ensureConnected();
            const signedTransactions = yield this.signTransactions([transaction]);
            if (signedTransactions.length != 1) {
                throw new errors_1.ErrCannotSignSingleTransaction();
            }
            return signedTransactions[0];
        });
    }
    ensureConnected() {
        if (!this.account.address) {
            throw new errors_1.ErrAccountNotConnected();
        }
    }
    signTransactions(transactions) {
        return __awaiter(this, void 0, void 0, function* () {
            this.ensureConnected();
            const extensionResponse = yield this.startBgrMsgChannel(operation_1.Operation.SignTransactions, {
                from: this.account.address,
                transactions: transactions.map((transaction) => transaction.toPlainObject()),
            });
            try {
                const transactionsResponse = extensionResponse.map((transaction) => transaction_1.Transaction.fromPlainObject(transaction));
                return transactionsResponse;
            }
            catch (error) {
                throw new Error(`Transaction canceled: ${error.message}.`);
            }
        });
    }
    signMessage(message) {
        return __awaiter(this, void 0, void 0, function* () {
            this.ensureConnected();
            const data = {
                account: this.account.address,
                message: message.message.toString(),
            };
            const extensionResponse = yield this.startBgrMsgChannel(operation_1.Operation.SignMessage, data);
            const signatureHex = extensionResponse.signature;
            const signature = Buffer.from(signatureHex, "hex");
            message.applySignature(signature);
            return message;
        });
    }
    cancelAction() {
        return this.startBgrMsgChannel(operation_1.Operation.CancelAction, {});
    }
    startBgrMsgChannel(operation, connectData) {
        return new Promise((resolve) => {
            window.postMessage({
                target: "erdw-inpage",
                type: operation,
                data: connectData,
            }, window.origin);
            const eventHandler = (event) => {
                if (event.isTrusted && event.data.target === "erdw-contentScript") {
                    if (event.data.type === "connectResponse") {
                        if (event.data.data && Boolean(event.data.data.address)) {
                            this.account = event.data.data;
                        }
                        window.removeEventListener("message", eventHandler);
                        resolve(event.data.data);
                    }
                    else {
                        window.removeEventListener("message", eventHandler);
                        resolve(event.data.data);
                    }
                }
            };
            window.addEventListener("message", eventHandler, false);
        });
    }
}
exports.ExtensionProvider = ExtensionProvider;
ExtensionProvider._instance = new ExtensionProvider();
