"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionLogs = void 0;
const errors_1 = require("./errors");
const primitives_1 = require("./primitives");
const transactionEvents_1 = require("./transactionEvents");
class TransactionLogs {
    constructor(init) {
        this.address = new primitives_1.Address("");
        this.events = [];
        Object.assign(this, init);
    }
    static fromHttpResponse(logs) {
        let result = new TransactionLogs();
        result.address = new primitives_1.Address(logs.address);
        result.events = (logs.events || []).map((event) => transactionEvents_1.TransactionEvent.fromHttpResponse(event));
        return result;
    }
    findSingleOrNoneEvent(identifier, predicate) {
        let events = this.findEvents(identifier, predicate);
        if (events.length > 1) {
            throw new errors_1.ErrUnexpectedCondition(`more than one event of type ${identifier}`);
        }
        return events[0];
    }
    findFirstOrNoneEvent(identifier, predicate) {
        return this.findEvents(identifier, predicate)[0];
    }
    findEvents(identifier, predicate) {
        let events = this.events.filter(event => event.identifier == identifier);
        if (predicate) {
            events = events.filter(event => predicate(event));
        }
        return events;
    }
}
exports.TransactionLogs = TransactionLogs;
