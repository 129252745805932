"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.zeroPadStringIfOddLength = exports.isPaddedHex = exports.numberToPaddedHex = exports.Nonce = exports.Address = void 0;
const bech32 = __importStar(require("bech32"));
/**
 * The human-readable-part of the bech32 addresses.
 */
const HRP = "erd";
class Address {
    constructor(value) {
        this.value = value;
    }
    static fromPubkey(pubkey) {
        let words = bech32.toWords(pubkey);
        let address = bech32.encode(HRP, words);
        return new Address(address);
    }
    bech32() {
        return this.value;
    }
    toString() {
        return this.bech32();
    }
}
exports.Address = Address;
class Nonce {
    constructor(value) {
        this.value = value;
    }
    valueOf() {
        return this.value;
    }
    hex() {
        return numberToPaddedHex(this.value);
    }
}
exports.Nonce = Nonce;
function numberToPaddedHex(value) {
    let hex = value.toString(16);
    return zeroPadStringIfOddLength(hex);
}
exports.numberToPaddedHex = numberToPaddedHex;
function isPaddedHex(input) {
    input = input || "";
    let decodedThenEncoded = Buffer.from(input, "hex").toString("hex");
    return input.toUpperCase() == decodedThenEncoded.toUpperCase();
}
exports.isPaddedHex = isPaddedHex;
function zeroPadStringIfOddLength(input) {
    input = input || "";
    if (input.length % 2 == 1) {
        return "0" + input;
    }
    return input;
}
exports.zeroPadStringIfOddLength = zeroPadStringIfOddLength;
