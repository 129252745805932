"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenOperationsFactoryConfig = void 0;
const address_1 = require("../address");
class TokenOperationsFactoryConfig {
    constructor(chainID) {
        this.minGasPrice = 1000000000;
        this.minGasLimit = 50000;
        this.gasLimitPerByte = 1500;
        this.gasLimitIssue = 60000000;
        this.gasLimitToggleBurnRoleGlobally = 60000000;
        this.gasLimitESDTLocalMint = 300000;
        this.gasLimitESDTLocalBurn = 300000;
        this.gasLimitSetSpecialRole = 60000000;
        this.gasLimitPausing = 60000000;
        this.gasLimitFreezing = 60000000;
        this.gasLimitWiping = 60000000;
        this.gasLimitESDTNFTCreate = 3000000;
        this.gasLimitESDTNFTUpdateAttributes = 1000000;
        this.gasLimitESDTNFTAddQuantity = 1000000;
        this.gasLimitESDTNFTBurn = 1000000;
        this.gasLimitStorePerByte = 50000;
        this.issueCost = "50000000000000000";
        this.esdtContractAddress = address_1.Address.fromBech32("erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqzllls8a5w6u");
        this.chainID = chainID;
    }
}
exports.TokenOperationsFactoryConfig = TokenOperationsFactoryConfig;
