"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SmartContractAbi = void 0;
const utils_1 = require("../utils");
const function_1 = require("./function");
class SmartContractAbi {
    // TODO (breaking, next major version): remove second parameter (not used anymore).
    constructor(registry, _implementsInterfaces) {
        this.interface = registry.interfaces[0];
    }
    getAllEndpoints() {
        return this.interface.endpoints;
    }
    getEndpoint(name) {
        if (name instanceof function_1.ContractFunction) {
            name = name.name;
        }
        let result = this.getAllEndpoints().find(item => item.name === name);
        utils_1.guardValueIsSetWithMessage(`endpoint [${name}] not found`, result);
        return result;
    }
    getConstructorDefinition() {
        return this.interface.getConstructorDefinition();
    }
}
exports.SmartContractAbi = SmartContractAbi;
