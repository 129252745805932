import React, { useReducer } from "react";

// Tired imports
import { useAuth, useTransaction } from "@elrond-giants/erd-react-hooks";
import { ApiNetworkProvider } from "@elrondnetwork/erdjs-network-providers";
import { ProxyNetworkProvider } from "@elrondnetwork/erdjs-network-providers";
import {
	Address,
	Query,
	AddressValue,
	ContractFunction,
} from "@elrondnetwork/erdjs";
import { useState } from "react";
import {
	contractAddress,
	mintAddress,
	secondCollectionIdentifier,
} from "./../config";
import { BigNumber } from "bignumber.js";
import { useHistory } from "react-router-dom";

// Chakra imports
import {
	Avatar,
	AvatarBadge,
	AvatarGroup,
	Box,
	Button,
	CircularProgress,
	CircularProgressLabel,
	DarkMode,
	Flex,
	Grid,
	Icon,
	Image,
	Link,
	Switch,
	Text,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Spinner,
	AlertTitle,
	AlertDescription,
	CloseButton,
	Alert,
	Input,
	InputGroup,
	InputRightAddon,
} from "@chakra-ui/react";
import avatar11 from "assets/img/avatars/avatar11.png";
// Assets
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar3 from "assets/img/avatars/avatar3.png";
import avatar4 from "assets/img/avatars/avatar4.png";
import avatar6 from "assets/img/avatars/avatar6.png";
import bgProfile from "assets/img/bgProfile.png";
import ProjectImage1 from "assets/img/ProjectImage1.png";
import ProjectImage2 from "assets/img/ProjectImage2.png";
import ProjectImage3 from "assets/img/ProjectImage3.png";
// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import LineChart from "components/Charts/LineChart";
import IconBox from "components/Icons/IconBox";
import { HSeparator, VSeparator } from "components/Separator/Separator";

// Icons
import {
	CarIcon,
	LightningIcon,
	LightningWhiteIcon,
	ElrondLogo,
} from "components/Icons/Icons";
import { BsArrowRight } from "react-icons/bs";
import {
	FaCube,
	FaFacebook,
	FaInstagram,
	FaPencilAlt,
	FaPenFancy,
	FaTwitter,
} from "react-icons/fa";
import { IoDocumentsSharp } from "react-icons/io5";

function Profile() {
	const {
		isOpen: isOpenTransactionSign,
		onOpen: onOpenTransactionSign,
		onClose: onCloseTransactionSign,
	} = useDisclosure();
	const [txSigned, setTxSigned] = useState(false);
	const [txCompleted, setTxCompleted] = useState(false);

	const isMintOpen = Date.now() > 1671793200000;

	const proxy = new ProxyNetworkProvider("https://gateway.multiversx.com");

	const { makeTransaction, whenCompleted } = useTransaction();
	const history = useHistory();

	const { address, authenticated, login, logout } = useAuth();

	const [mintData, setMintData] = useState(null);
	const [inputMint, setInputMint] = useState("");

	const getUser = async () => {
		const provider = new ApiNetworkProvider("https://api.multiversx.com");
		const account = await provider.getAccount(new Address(address));
		setAccount(account);
	};

	const mint = async () => {
		setTxSigned(false);
		setTxCompleted(false);

		onOpenTransactionSign();
		const txHash = await makeTransaction({
			transaction: {
				receiver: mintAddress,
				data:
					"mint" +
					"@" +
					Buffer.from(secondCollectionIdentifier).toString("hex") +
					"@" +
					parseInt(inputMint).toString(16).padStart(2, "0"),
				gasLimit: 5_000_000 + 10_000_000 * parseInt(inputMint),
				value: new BigNumber(mintData.stage.price)
					.multipliedBy(inputMint)
					.div(10 ** 18),
			},
		});

		setTxSigned(true);

		const txResult = await whenCompleted(txHash, { interval: 2000 });

		setTxCompleted(true);

		getMintData();
	};

	function base64ToHexFunc(str: string): string {
		const encodedData = atob(str);
		let result = "";
		for (let i = 0; i < encodedData.length; i++) {
			const hex = encodedData.charCodeAt(i).toString(16);
			result += hex.length === 2 ? hex : "0" + hex;
		}
		return result.toUpperCase();
	}

	async function getMintData() {
		fetch(
			`https://api.elrondnftswap.com/mint/getMintCollection?ticker=${secondCollectionIdentifier}`
		)
			.then((response) => response.json())
			.then((data) => {
				setMintData(data);
			});
	}

	React.useEffect(() => {
		if (!authenticated) {
			history.push("/auth");
			return;
		}

		getMintData();

		getUser();
	}, [authenticated, address]);

	return (
		<>
			<Flex direction="column" mt={{ sm: "150px", md: "100px" }}>
				<Box
					mb={{ sm: "24px", md: "50px", xl: "20px" }}
					borderRadius="15px"
					px="0px"
					display="flex"
					flexDirection="column"
					justifyContent="center"
				>
					{/* Header */}
					<Card
						direction={{ sm: "column", md: "row" }}
						mx="auto"
						maxH="330px"
						w={{ sm: "90%", xl: "100%" }}
						justifyContent={{ sm: "center", md: "space-between" }}
						align="center"
						p="24px"
						borderRadius="20px"
					>
						<Flex align="center" direction={{ sm: "column", md: "row" }}>
							<Flex
								align="center"
								mb={{ sm: "10px", md: "0px" }}
								direction={{ sm: "column", md: "row" }}
								w={{ sm: "100%" }}
								textAlign={{ sm: "center", md: "start" }}
							>
								<Flex direction="column" maxWidth="100%" my={{ sm: "14px" }}>
									<Text
										fontSize={{ sm: "lg", lg: "xl" }}
										color="#fff"
										fontWeight="bold"
										ms={{ sm: "8px", md: "0px" }}
									>
										<Text>Tired Ape Sporting Club</Text>
									</Text>
									<Text fontSize={{ sm: "sm", md: "md" }} color="gray.400">
										Tired Ape Sporting Club is the Second collection of The
										Club,the most Athletic Nfts over the whole MultiversX
										network! Those Apes are ready to setup the Tired Club
										Stacking Agency in order to deliver true healthy and
										sustainable EGLDs rewards to their Holders
									</Text>
								</Flex>
							</Flex>
						</Flex>
					</Card>
				</Box>
				<Flex
					direction={{ sm: "column", md: "column", lg: "row" }}
					w="100%"
					justifyContent={{
						sm: "space-between",
						md: "space-between",
						lg: "center",
					}}
				>
					{/* Staking Rewards */}
					<Box flex="1">
						<Card p="16px" h="300px">
							<CardHeader p="12px 5px" mb="12px">
								<Flex direction="column">
									<Text fontSize="xl" color="#fff" fontWeight="bold" mb="6px">
										Mint
									</Text>
									<Text fontSize="sm" color="gray.400">
										{mintData?.minted || 0} sold out of{" "}
										{mintData?.total || 5000}
									</Text>
									<Text fontSize="sm" color="gray.400">
										{new BigNumber(
											mintData?.stage?.price || "800000000000000000"
										)
											.div(10 ** 18)
											.toPrecision(2)}{" "}
										EGLD per NFT
									</Text>
								</Flex>
							</CardHeader>
							<CardBody w="100%">
								<Flex w="100%" direction={{ sm: "column", md: "row" }}>
									<Grid
										templateColumns={{ sm: "2fr", md: "repeat(1, 2fr)" }}
										gap="24px"
										w="100%"
										alignSelf="flex-start"
									>
										{isMintOpen ? (
											<>
												<InputGroup size="lg">
													<Input
														placeholder="Amount"
														type="number"
														color="white"
														value={inputMint}
														onChange={(e) => setInputMint(e.target.value)}
													/>
													<InputRightAddon backgroundColor="gray.300">
														NFTs
													</InputRightAddon>
												</InputGroup>
												<Button
													variant="brand"
													alignSelf="flex-end"
													mt="24px"
													h="35px"
													onClick={mint}
													disabled={
														inputMint === "" || parseInt(inputMint) > "50"
													}
												>
													<Text fontSize="xs" color="#fff" fontWeight="bold">
														{inputMint === ""
															? "Select amount"
															: parseInt(inputMint) > "50"
															? "Maximum 50 NFTs per transaction"
															: `Mint ${inputMint} NFTs for ${new BigNumber(
																	mintData?.stage?.price || "800000000000000000"
															  )
																	.multipliedBy(inputMint)
																	.div(10 ** 18)} EGLD`}
													</Text>
												</Button>
											</>
										) : (
											<Text fontSize="lg" color="gray.400">
												Mint is closed
											</Text>
										)}
									</Grid>
								</Flex>
							</CardBody>
						</Card>
					</Box>
				</Flex>
			</Flex>
			<Modal isOpen={isOpenTransactionSign} onClose={onCloseTransactionSign}>
				<ModalOverlay />
				<ModalContent mx={{ sm: 5, lg: 0 }}>
					<ModalBody>
						<Text>
							{!txSigned
								? "Check your MultiversX Wallet to sign the transaction"
								: null}
							{txSigned && !txCompleted
								? "Transaction is being processed, please wait..."
								: null}
							{txCompleted ? "Transaction completed!" : null}
						</Text>
					</ModalBody>
					<ModalFooter>
						<Button variant="outline" mr={3} onClick={onCloseTransactionSign}>
							{txCompleted ? "Close" : "Cancel"}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}

export default Profile;
