"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlainSignedTransaction = void 0;
class PlainSignedTransaction {
    constructor(init) {
        this.nonce = 0;
        this.value = "";
        this.receiver = "";
        this.sender = "";
        this.gasPrice = 0;
        this.gasLimit = 0;
        this.data = "";
        this.chainID = "";
        this.version = 0;
        this.options = undefined;
        this.signature = "";
        Object.assign(this, init);
    }
}
exports.PlainSignedTransaction = PlainSignedTransaction;
