"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResultsParser = void 0;
const transaction_decoder_1 = require("@elrondnetwork/transaction-decoder");
const address_1 = require("../address");
const errors_1 = require("../errors");
const logger_1 = require("../logger");
const argSerializer_1 = require("./argSerializer");
const returnCode_1 = require("./returnCode");
var WellKnownEvents;
(function (WellKnownEvents) {
    WellKnownEvents["OnTransactionCompleted"] = "completedTxEvent";
    WellKnownEvents["OnSignalError"] = "signalError";
    WellKnownEvents["OnWriteLog"] = "writeLog";
})(WellKnownEvents || (WellKnownEvents = {}));
var WellKnownTopics;
(function (WellKnownTopics) {
    WellKnownTopics["TooMuchGas"] = "@too much gas provided for processing";
})(WellKnownTopics || (WellKnownTopics = {}));
// TODO: perhaps move default construction options to a factory (ResultsParserFactory), instead of referencing them in the constructor
// (postpone as much as possible, breaking change)
const defaultResultsParserOptions = {
    argsSerializer: new argSerializer_1.ArgSerializer()
};
/**
 * Parses contract query responses and smart contract results.
 * The parsing involves some heuristics, in order to handle slight inconsistencies (e.g. some SCRs are present on API, but missing on Gateway).
 */
class ResultsParser {
    constructor(options) {
        options = Object.assign(Object.assign({}, defaultResultsParserOptions), options);
        this.argsSerializer = options.argsSerializer;
    }
    parseQueryResponse(queryResponse, endpoint) {
        let parts = queryResponse.getReturnDataParts();
        let values = this.argsSerializer.buffersToValues(parts, endpoint.output);
        let returnCode = new returnCode_1.ReturnCode(queryResponse.returnCode.toString());
        return {
            returnCode: returnCode,
            returnMessage: queryResponse.returnMessage,
            values: values,
            firstValue: values[0],
            secondValue: values[1],
            thirdValue: values[2],
            lastValue: values[values.length - 1]
        };
    }
    parseUntypedQueryResponse(queryResponse) {
        let returnCode = new returnCode_1.ReturnCode(queryResponse.returnCode.toString());
        return {
            returnCode: returnCode,
            returnMessage: queryResponse.returnMessage,
            values: queryResponse.getReturnDataParts()
        };
    }
    parseOutcome(transaction, endpoint) {
        let untypedBundle = this.parseUntypedOutcome(transaction);
        let values = this.argsSerializer.buffersToValues(untypedBundle.values, endpoint.output);
        return {
            returnCode: untypedBundle.returnCode,
            returnMessage: untypedBundle.returnMessage,
            values: values,
            firstValue: values[0],
            secondValue: values[1],
            thirdValue: values[2],
            lastValue: values[values.length - 1]
        };
    }
    parseUntypedOutcome(transaction) {
        let bundle;
        let transactionMetadata = this.parseTransactionMetadata(transaction);
        bundle = this.createBundleOnSimpleMoveBalance(transaction);
        if (bundle) {
            logger_1.Logger.trace("parseUntypedOutcome(): on simple move balance");
            return bundle;
        }
        bundle = this.createBundleOnInvalidTransaction(transaction);
        if (bundle) {
            logger_1.Logger.trace("parseUntypedOutcome(): on invalid transaction");
            return bundle;
        }
        bundle = this.createBundleOnEasilyFoundResultWithReturnData(transaction.contractResults);
        if (bundle) {
            logger_1.Logger.trace("parseUntypedOutcome(): on easily found result with return data");
            return bundle;
        }
        bundle = this.createBundleOnSignalError(transaction.logs);
        if (bundle) {
            logger_1.Logger.trace("parseUntypedOutcome(): on signal error");
            return bundle;
        }
        bundle = this.createBundleOnTooMuchGasWarning(transaction.logs);
        if (bundle) {
            logger_1.Logger.trace("parseUntypedOutcome(): on 'too much gas' warning");
            return bundle;
        }
        bundle = this.createBundleOnWriteLogWhereFirstTopicEqualsAddress(transaction.logs, transaction.sender);
        if (bundle) {
            logger_1.Logger.trace("parseUntypedOutcome(): on writelog with topics[0] == tx.sender");
            return bundle;
        }
        bundle = this.createBundleWithCustomHeuristics(transaction, transactionMetadata);
        if (bundle) {
            logger_1.Logger.trace("parseUntypedOutcome(): with custom heuristics");
            return bundle;
        }
        bundle = this.createBundleWithFallbackHeuristics(transaction, transactionMetadata);
        if (bundle) {
            logger_1.Logger.trace("parseUntypedOutcome(): with fallback heuristics");
            return bundle;
        }
        throw new errors_1.ErrCannotParseContractResults(`transaction ${transaction.hash.toString()}`);
    }
    parseTransactionMetadata(transaction) {
        return new transaction_decoder_1.TransactionDecoder().getTransactionMetadata({
            sender: transaction.sender.bech32(),
            receiver: transaction.receiver.bech32(),
            data: transaction.data.toString("base64"),
            value: transaction.value.toString(),
            type: transaction.type
        });
    }
    createBundleOnSimpleMoveBalance(transaction) {
        let noResults = transaction.contractResults.items.length == 0;
        let noLogs = transaction.logs.events.length == 0;
        if (noResults && noLogs) {
            return {
                returnCode: returnCode_1.ReturnCode.None,
                returnMessage: returnCode_1.ReturnCode.None.toString(),
                values: []
            };
        }
        return null;
    }
    createBundleOnInvalidTransaction(transaction) {
        if (transaction.status.isInvalid()) {
            if (transaction.receipt.data) {
                return {
                    returnCode: returnCode_1.ReturnCode.OutOfFunds,
                    returnMessage: transaction.receipt.data,
                    values: []
                };
            }
            // If there's no receipt message, let other heuristics to handle the outcome (most probably, a log with "signalError" is emitted).
        }
        return null;
    }
    createBundleOnEasilyFoundResultWithReturnData(results) {
        let resultItemWithReturnData = results.items.find(item => item.nonce.valueOf() != 0 && item.data.startsWith("@"));
        if (!resultItemWithReturnData) {
            return null;
        }
        let { returnCode, returnDataParts } = this.sliceDataFieldInParts(resultItemWithReturnData.data);
        let returnMessage = resultItemWithReturnData.returnMessage || returnCode.toString();
        return {
            returnCode: returnCode,
            returnMessage: returnMessage,
            values: returnDataParts
        };
    }
    createBundleOnSignalError(logs) {
        let eventSignalError = logs.findSingleOrNoneEvent(WellKnownEvents.OnSignalError);
        if (!eventSignalError) {
            return null;
        }
        let { returnCode, returnDataParts } = this.sliceDataFieldInParts(eventSignalError.data);
        let lastTopic = eventSignalError.getLastTopic();
        let returnMessage = (lastTopic === null || lastTopic === void 0 ? void 0 : lastTopic.toString()) || returnCode.toString();
        return {
            returnCode: returnCode,
            returnMessage: returnMessage,
            values: returnDataParts
        };
    }
    createBundleOnTooMuchGasWarning(logs) {
        let eventTooMuchGas = logs.findSingleOrNoneEvent(WellKnownEvents.OnWriteLog, event => event.findFirstOrNoneTopic(topic => topic.toString().startsWith(WellKnownTopics.TooMuchGas)) != undefined);
        if (!eventTooMuchGas) {
            return null;
        }
        let { returnCode, returnDataParts } = this.sliceDataFieldInParts(eventTooMuchGas.data);
        let lastTopic = eventTooMuchGas.getLastTopic();
        let returnMessage = (lastTopic === null || lastTopic === void 0 ? void 0 : lastTopic.toString()) || returnCode.toString();
        return {
            returnCode: returnCode,
            returnMessage: returnMessage,
            values: returnDataParts
        };
    }
    createBundleOnWriteLogWhereFirstTopicEqualsAddress(logs, address) {
        let hexAddress = new address_1.Address(address.bech32()).hex();
        let eventWriteLogWhereTopicIsSender = logs.findSingleOrNoneEvent(WellKnownEvents.OnWriteLog, event => event.findFirstOrNoneTopic(topic => topic.hex() == hexAddress) != undefined);
        if (!eventWriteLogWhereTopicIsSender) {
            return null;
        }
        let { returnCode, returnDataParts } = this.sliceDataFieldInParts(eventWriteLogWhereTopicIsSender.data);
        let returnMessage = returnCode.toString();
        return {
            returnCode: returnCode,
            returnMessage: returnMessage,
            values: returnDataParts
        };
    }
    /**
     * Override this method (in a subclass of {@link ResultsParser}) if the basic heuristics of the parser are not sufficient.
     */
    createBundleWithCustomHeuristics(_transaction, _transactionMetadata) {
        return null;
    }
    createBundleWithFallbackHeuristics(transaction, transactionMetadata) {
        let contractAddress = new address_1.Address(transactionMetadata.receiver);
        // Search the nested logs for matching events (writeLog):
        for (const resultItem of transaction.contractResults.items) {
            let writeLogWithReturnData = resultItem.logs.findSingleOrNoneEvent(WellKnownEvents.OnWriteLog, event => {
                var _a;
                let addressIsSender = event.address.bech32() == transaction.sender.bech32();
                let firstTopicIsContract = ((_a = event.topics[0]) === null || _a === void 0 ? void 0 : _a.hex()) == contractAddress.hex();
                return addressIsSender && firstTopicIsContract;
            });
            if (writeLogWithReturnData) {
                let { returnCode, returnDataParts } = this.sliceDataFieldInParts(writeLogWithReturnData.data);
                let returnMessage = returnCode.toString();
                return {
                    returnCode: returnCode,
                    returnMessage: returnMessage,
                    values: returnDataParts
                };
            }
        }
        return null;
    }
    sliceDataFieldInParts(data) {
        // By default, skip the first part, which is usually empty (e.g. "[empty]@6f6b")
        let startingIndex = 1;
        // Before trying to parse the hex strings, cut the unwanted parts of the data field, in case of token transfers:
        if (data.startsWith("ESDTTransfer")) {
            // Skip "ESDTTransfer" (1), token identifier (2), amount (3)
            startingIndex = 3;
        }
        else {
            // TODO: Upon gathering more transaction samples, fix for other kinds of transfers, as well (future PR, as needed).
        }
        let parts = this.argsSerializer.stringToBuffers(data);
        let returnCodePart = parts[startingIndex] || Buffer.from([]);
        let returnDataParts = parts.slice(startingIndex + 1);
        if (returnCodePart.length == 0) {
            throw new errors_1.ErrCannotParseContractResults("no return code");
        }
        let returnCode = returnCode_1.ReturnCode.fromBuffer(returnCodePart);
        return { returnCode, returnDataParts };
    }
}
exports.ResultsParser = ResultsParser;
