"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Account = void 0;
const address_1 = require("./address");
/**
 * An abstraction representing an account (user or Smart Contract) on the Network.
 */
class Account {
    /**
     * Creates an account object from an address
     */
    constructor(address) {
        /**
         * The address of the account.
         */
        this.address = new address_1.Address();
        /**
         * The nonce of the account (the account sequence number).
         */
        this.nonce = 0;
        /**
         * The balance of the account.
         */
        this.balance = "0";
        this.address = address;
    }
    /**
     * Updates account properties (such as nonce, balance).
     */
    update(obj) {
        this.nonce = obj.nonce;
        this.balance = obj.balance;
    }
    /**
     * Increments (locally) the nonce (the account sequence number).
     */
    incrementNonce() {
        this.nonce = this.nonce.valueOf() + 1;
    }
    /**
     * Gets then increments (locally) the nonce (the account sequence number).
     */
    getNonceThenIncrement() {
        let nonce = this.nonce;
        this.nonce = this.nonce.valueOf() + 1;
        return nonce;
    }
    /**
     * Converts the account to a pretty, plain JavaScript object.
     */
    toJSON() {
        return {
            address: this.address.bech32(),
            nonce: this.nonce.valueOf(),
            balance: this.balance.toString(),
        };
    }
}
exports.Account = Account;
