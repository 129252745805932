"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrContractQuery = exports.ErrNetworkProvider = exports.ErrUnexpectedCondition = exports.Err = void 0;
/**
 * The base class for exceptions (errors).
 */
class Err extends Error {
    constructor(message, inner) {
        super(message);
        this.inner = undefined;
        this.inner = inner;
    }
}
exports.Err = Err;
/**
 * Signals an unexpected condition.
 */
class ErrUnexpectedCondition extends Err {
    constructor(message) {
        super(`Unexpected condition: [${message}]`);
    }
}
exports.ErrUnexpectedCondition = ErrUnexpectedCondition;
/**
 * Signals an error that happened during a request against the Network.
 */
class ErrNetworkProvider extends Err {
    constructor(url, error, inner) {
        let message = `Request error on url [${url}]: [${error}]`;
        super(message, inner);
    }
}
exports.ErrNetworkProvider = ErrNetworkProvider;
/**
 * Signals a generic error in the context of querying Smart Contracts.
 */
class ErrContractQuery extends Err {
    constructor(originalError) {
        super(originalError.message.replace("executeQuery:", ""));
    }
}
exports.ErrContractQuery = ErrContractQuery;
