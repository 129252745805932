"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionVersion = void 0;
const constants_1 = require("./constants");
class TransactionVersion {
    constructor(value) {
        this.value = value;
    }
    static withTxHashSignVersion() {
        return new TransactionVersion(constants_1.TRANSACTION_VERSION_TX_HASH_SIGN);
    }
    valueOf() {
        return this.value;
    }
}
exports.TransactionVersion = TransactionVersion;
