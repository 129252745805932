/*eslint-disable*/
import React from "react";
import { Flex, Link, List, ListItem, Text } from "@chakra-ui/react";

export default function Footer(props) {
	return (
		<Flex
			flexDirection={{
				base: "column",
				xl: "row",
			}}
			alignItems={{
				base: "center",
				xl: "start",
			}}
			justifyContent="space-between"
			px={{ base: "15px", md: "30px" }}
			pb="20px"
		>
			<Text
				fontSize="sm"
				color="white"
				textAlign={{
					base: "center",
					xl: "start",
				}}
				mb={{ base: "20px", xl: "0px" }}
			>
				<Link href="https://www.tiredclub.art" target="_blank">
					Made with ❤️ by Tired Club
				</Link>
			</Text>
			<List display="flex">
				<ListItem
					me={{
						base: "20px",
						md: "44px",
					}}
				>
					<Link
						color="white"
						fontSize="sm"
						href="https://tiredclub.art/"
						target="_blank"
					>
						Website
					</Link>
				</ListItem>
				<ListItem
					me={{
						base: "20px",
						md: "44px",
					}}
				>
					<Link
						color="white"
						fontSize="sm"
						href="https://whitepaper.tiredclub.art/"
						target="_blank"
					>
						White Paper
					</Link>
				</ListItem>
				<ListItem
					me={{
						base: "20px",
						md: "44px",
					}}
				>
					<Link
						color="white"
						fontSize="sm"
						href="https://twitter.com/tired__club/"
						target="_blank"
					>
						Twitter
					</Link>
				</ListItem>
				<ListItem>
					<Link
						color="white"
						fontSize="sm"
						href="https://discord.gg/tiredclub"
						target="_blank"
					>
						Discord
					</Link>
				</ListItem>
			</List>
		</Flex>
	);
}
