"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransferTransactionsFactory = void 0;
const argSerializer_1 = require("./smartcontracts/argSerializer");
const typesystem_1 = require("./smartcontracts/typesystem");
const transaction_1 = require("./transaction");
const transactionPayload_1 = require("./transactionPayload");
class TransferTransactionsFactory {
    constructor(gasEstimator) {
        this.gasEstimator = gasEstimator;
    }
    createEGLDTransfer(args) {
        var _a;
        const dataLength = ((_a = args.data) === null || _a === void 0 ? void 0 : _a.length()) || 0;
        const estimatedGasLimit = this.gasEstimator.forEGLDTransfer(dataLength);
        return new transaction_1.Transaction({
            nonce: args.nonce,
            value: args.value,
            receiver: args.receiver,
            sender: args.sender,
            gasPrice: args.gasPrice,
            gasLimit: args.gasLimit || estimatedGasLimit,
            data: args.data,
            chainID: args.chainID
        });
    }
    createESDTTransfer(args) {
        const { argumentsString } = new argSerializer_1.ArgSerializer().valuesToString([
            // The token identifier
            typesystem_1.BytesValue.fromUTF8(args.tokenTransfer.tokenIdentifier),
            // The transfered amount
            new typesystem_1.BigUIntValue(args.tokenTransfer.valueOf()),
        ]);
        const data = `ESDTTransfer@${argumentsString}`;
        const transactionPayload = new transactionPayload_1.TransactionPayload(data);
        const dataLength = transactionPayload.length() || 0;
        const estimatedGasLimit = this.gasEstimator.forESDTTransfer(dataLength);
        return new transaction_1.Transaction({
            nonce: args.nonce,
            receiver: args.receiver,
            sender: args.sender,
            gasPrice: args.gasPrice,
            gasLimit: args.gasLimit || estimatedGasLimit,
            data: transactionPayload,
            chainID: args.chainID
        });
    }
    createESDTNFTTransfer(args) {
        const { argumentsString } = new argSerializer_1.ArgSerializer().valuesToString([
            // The token identifier
            typesystem_1.BytesValue.fromUTF8(args.tokenTransfer.tokenIdentifier),
            // The nonce of the token
            new typesystem_1.U64Value(args.tokenTransfer.nonce),
            // The transferred quantity
            new typesystem_1.BigUIntValue(args.tokenTransfer.valueOf()),
            // The destination address
            new typesystem_1.AddressValue(args.destination)
        ]);
        const data = `ESDTNFTTransfer@${argumentsString}`;
        const transactionPayload = new transactionPayload_1.TransactionPayload(data);
        const dataLength = transactionPayload.length() || 0;
        const estimatedGasLimit = this.gasEstimator.forESDTNFTTransfer(dataLength);
        return new transaction_1.Transaction({
            nonce: args.nonce,
            receiver: args.sender,
            sender: args.sender,
            gasPrice: args.gasPrice,
            gasLimit: args.gasLimit || estimatedGasLimit,
            data: transactionPayload,
            chainID: args.chainID
        });
    }
    createMultiESDTNFTTransfer(args) {
        const parts = [
            // The destination address
            new typesystem_1.AddressValue(args.destination),
            // Number of tokens
            new typesystem_1.U16Value(args.tokenTransfers.length)
        ];
        for (const payment of args.tokenTransfers) {
            parts.push(...[
                // The token identifier
                typesystem_1.BytesValue.fromUTF8(payment.tokenIdentifier),
                // The nonce of the token
                new typesystem_1.U64Value(payment.nonce),
                // The transfered quantity
                new typesystem_1.BigUIntValue(payment.valueOf())
            ]);
        }
        const { argumentsString } = new argSerializer_1.ArgSerializer().valuesToString(parts);
        const data = `MultiESDTNFTTransfer@${argumentsString}`;
        const transactionPayload = new transactionPayload_1.TransactionPayload(data);
        const dataLength = transactionPayload.length() || 0;
        const estimatedGasLimit = this.gasEstimator.forMultiESDTNFTTransfer(dataLength, args.tokenTransfers.length);
        return new transaction_1.Transaction({
            nonce: args.nonce,
            receiver: args.sender,
            sender: args.sender,
            gasPrice: args.gasPrice,
            gasLimit: args.gasLimit || estimatedGasLimit,
            data: transactionPayload,
            chainID: args.chainID
        });
    }
}
exports.TransferTransactionsFactory = TransferTransactionsFactory;
