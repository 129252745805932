"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContractResultItem = exports.ContractResults = void 0;
const transactionLogs_1 = require("./transactionLogs");
const primitives_1 = require("./primitives");
class ContractResults {
    constructor(items) {
        this.items = items;
        this.items.sort(function (a, b) {
            return a.nonce.valueOf() - b.nonce.valueOf();
        });
    }
    static fromProxyHttpResponse(results) {
        let items = results.map(item => ContractResultItem.fromProxyHttpResponse(item));
        return new ContractResults(items);
    }
    static fromApiHttpResponse(results) {
        let items = results.map(item => ContractResultItem.fromApiHttpResponse(item));
        return new ContractResults(items);
    }
}
exports.ContractResults = ContractResults;
class ContractResultItem {
    constructor(init) {
        this.hash = "";
        this.nonce = 0;
        this.value = "";
        this.receiver = new primitives_1.Address("");
        this.sender = new primitives_1.Address("");
        this.data = "";
        this.previousHash = "";
        this.originalHash = "";
        this.gasLimit = 0;
        this.gasPrice = 0;
        this.callType = 0;
        this.returnMessage = "";
        this.logs = new transactionLogs_1.TransactionLogs();
        Object.assign(this, init);
    }
    static fromProxyHttpResponse(response) {
        let item = ContractResultItem.fromHttpResponse(response);
        return item;
    }
    static fromApiHttpResponse(response) {
        let item = ContractResultItem.fromHttpResponse(response);
        item.data = Buffer.from(item.data, "base64").toString();
        item.callType = Number(item.callType);
        return item;
    }
    static fromHttpResponse(response) {
        let item = new ContractResultItem();
        item.hash = response.hash;
        item.nonce = Number(response.nonce || 0);
        item.value = (response.value || 0).toString();
        item.receiver = new primitives_1.Address(response.receiver);
        item.sender = new primitives_1.Address(response.sender);
        item.previousHash = response.prevTxHash;
        item.originalHash = response.originalTxHash;
        item.gasLimit = Number(response.gasLimit || 0);
        item.gasPrice = Number(response.gasPrice || 0);
        item.data = response.data || "";
        item.callType = response.callType;
        item.returnMessage = response.returnMessage;
        item.logs = transactionLogs_1.TransactionLogs.fromHttpResponse(response.logs || {});
        return item;
    }
}
exports.ContractResultItem = ContractResultItem;
